import SchoolIcon from '@mui/icons-material/SchoolOutlined';
import PeopleIcon from '@mui/icons-material/PeopleOutlined';
import FAQsIcon from '@mui/icons-material/QuestionAnswerOutlined';
import { IconReportMoney } from '@tabler/icons';

import client from 'apolloClient';
import { MyModuleMenusDocument, MyModuleMenusQuery, MyModuleMenusQueryVariables, UserRole } from 'generated/graphql';
import { IMenuItem } from 'types/menu';

const BudgetIcon = () => <IconReportMoney width={24} height={24} strokeWidth={2} />;

const ml: IMenuItem = {
    id: 'pgta-navigation',
    type: 'group',
    children: [
        {
            id: 'module-management',
            title: 'My Modules',
            type: 'collapse',
            url: '/module',
            defaultOpen: true,
            breadcrumbs: true,
            icon: SchoolIcon,
            lazy: true,
            onLoad: async () => {
                const queryResult = await client.query<MyModuleMenusQuery, MyModuleMenusQueryVariables>({
                    query: MyModuleMenusDocument
                });
                return queryResult.data.myModules.map((module) => {
                    const children: IMenuItem[] = [
                        {
                            id: `my-module-${module.code}-approved-application`,
                            title: 'Approved Applications',
                            type: 'item',
                            url: `/module/${module.code}/approved`
                        },
                        {
                            id: `my-module-${module.code}-pending-application`,
                            title: 'Pending Applications',
                            type: 'item',
                            url: `/module/${module.code}/pending`
                        },
                        {
                            id: `my-module-${module.code}-profile`,
                            title: 'Module Profile',
                            type: 'item',
                            url: `/module/${module.code}/profile`
                        },
                        {
                            id: `my-module-${module.code}-initial-budget`,
                            title: 'Initial Budget',
                            type: 'item',
                            url: `/module/${module.code}/initial`
                        },
                        {
                            id: `my-module-${module.code}-extra-budget`,
                            title: 'Extra Budget',
                            type: 'item',
                            url: `/module/${module.code}/extra`
                        },
                        {
                            id: `my-module-${module.code}-vacancies`,
                            title: 'Vacancy',
                            type: 'item',
                            url: `/module/${module.code}/vacancies`
                        }
                    ];
                    if (module.myRole === UserRole.ModuleLeader) {
                        children.push({
                            id: `my-module-${module.code}-team`,
                            title: 'Team',
                            type: 'item',
                            url: `/module/${module.code}/team`
                        });
                    }
                    return {
                        id: `my-module-${module.code}`,
                        title: module.code,
                        pageTitle: `${module.code} ${module.title}`,
                        type: 'tabGroup',
                        url: `/module/${module.code}`,
                        defaultTabUrl: `/module/${module.code}/approved`,
                        breadcrumbs: true,
                        children
                    };
                });
            }
        },
        {
            id: 'ta-management',
            title: 'TA Management',
            type: 'tabGroup',
            url: '/ta',
            defaultTabUrl: '/ta/pending',
            breadcrumbs: true,
            icon: PeopleIcon,
            children: [
                {
                    id: 'ta-management-current',
                    title: 'Pending TAs',
                    type: 'item',
                    url: '/ta/pending'
                },
                {
                    id: 'ta-management-approved',
                    title: 'Approved TAs',
                    type: 'item',
                    url: '/ta/approved'
                },
                {
                    id: 'ta-management-applicants',
                    title: 'Applicants',
                    type: 'item',
                    url: '/ta/applicants'
                }
            ]
        },
        {
            id: 'ml-budget',
            title: 'Module Budget',
            type: 'tabGroup',
            url: '/budget',
            defaultTabUrl: '/budget/initial',
            breadcrumbs: true,
            icon: BudgetIcon,
            children: [
                {
                    id: 'ml-budget-initial',
                    title: 'Initial Budget',
                    type: 'item',
                    url: '/budget/initial'
                },
                {
                    id: 'ml-budget-extra',
                    title: 'Extra Budget',
                    type: 'item',
                    url: '/budget/extra'
                }
            ]
        },
        {
            id: 'faqs',
            title: 'FAQs',
            type: 'item',
            url: '/faqs',
            icon: FAQsIcon,
            breadcrumbs: true
        }
    ]
};

export default ml;
