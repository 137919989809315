import { lazy } from 'react';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { RouteObject } from 'react-router-dom';

const PendingRTW = Loadable(lazy(() => import('views/hr/rtw/Pending')));
const CheckedRTW = Loadable(lazy(() => import('views/hr/rtw/Checked')));
const PendingContract = Loadable(lazy(() => import('views/hr/contract/Pending')));
const IssuedContract = Loadable(lazy(() => import('views/hr/contract/Issued')));
const ModuleInfo = Loadable(lazy(() => import('views/hr/ModuleInfo')));
const TAInfo = Loadable(lazy(() => import('views/management/ta')));

const MainRoutes: RouteObject = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: 'rtw-review',
            children: [
                {
                    path: 'pending',
                    element: <PendingRTW />
                },
                {
                    path: 'checked',
                    element: <CheckedRTW />
                }
            ]
        },
        {
            path: 'contract-information',
            children: [
                {
                    path: 'pending',
                    element: <PendingContract />
                },
                {
                    path: 'issued',
                    element: <IssuedContract />
                }
            ]
        },
        {
            path: 'ta-information',
            element: <TAInfo />
        },
        {
            path: 'module-information',
            element: <ModuleInfo />
        }
    ]
};

export default MainRoutes;
