import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Cursor: number;
  Timestamp: Date;
};

export type AddRecruiterInput = {
  moduleCode: Scalars['String'];
  taRecruiter: UserInput;
  vacancyId: Scalars['Int'];
};

export type AdminTeamRoleUpdateInput = {
  role: UserRole;
  user: UserInput;
};

export type ApplicationForm = {
  appliedHours: Scalars['Int'];
  moduleId: Scalars['Int'];
  motivationLetter?: InputMaybe<Scalars['String']>;
  vacancyId: Scalars['Int'];
};

export enum ApplicationStatus {
  Approved = 'Approved',
  Pending = 'Pending',
  Rejected = 'Rejected',
  Saved = 'Saved'
}

export type BasicProfileUpdateInput = {
  cvUrl?: InputMaybe<Scalars['String']>;
  department?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  homeAddress?: InputMaybe<Scalars['String']>;
  jobRole?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  needTraining: Scalars['Boolean'];
  phone?: InputMaybe<Scalars['String']>;
  researchGroup?: InputMaybe<Scalars['String']>;
  studentType: StudentType;
  title: Scalars['String'];
  university?: InputMaybe<Scalars['String']>;
  workBefore: Scalars['Boolean'];
  year?: InputMaybe<Scalars['String']>;
};

export type BudgetApplication = {
  applicationDate: Scalars['Timestamp'];
  approvedDate?: Maybe<Scalars['Timestamp']>;
  approvedHours?: Maybe<Scalars['Int']>;
  budgetOption: Scalars['String'];
  id: Scalars['Int'];
  jobDescription?: Maybe<Scalars['String']>;
  marking?: Maybe<Scalars['String']>;
  module: Course;
  status: InitialBudgetStatus;
  support?: Maybe<Scalars['String']>;
  teaching?: Maybe<Scalars['String']>;
  term: Array<Scalars['String']>;
  totalHours?: Maybe<Scalars['Int']>;
};

export type Course = {
  applicationAmount: Scalars['Int'];
  applications: Array<TAApplication>;
  code: Scalars['String'];
  confirmedTAs: Scalars['Int'];
  degree?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  initialBudget?: Maybe<BudgetApplication>;
  jobs: Array<TAJob>;
  moduleLeader: ModuleLeader;
  myRole: UserRole;
  permission: CoursePermission;
  remainingHours?: Maybe<Scalars['Int']>;
  term: Array<Scalars['String']>;
  title: Scalars['String'];
  vacancies: Array<Vacancy>;
};

export type CourseCreateInput = {
  code: Scalars['String'];
  degree?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  moduleLeader?: InputMaybe<UserInput>;
  term: Array<Scalars['String']>;
  title: Scalars['String'];
};

export type CourseEdge = {
  cursor: Scalars['Cursor'];
  node: Course;
};

export enum CoursePermission {
  Read = 'Read',
  Write = 'Write'
}

export type CourseUpdateInput = {
  code: Scalars['String'];
  degree?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  moduleLeader?: InputMaybe<UserInput>;
  term: Array<Scalars['String']>;
  title: Scalars['String'];
};

export type CreateVacancyFormInput = {
  academicYear: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  details: Scalars['String'];
  endDate: Scalars['Timestamp'];
  moduleCode: Scalars['String'];
  offeredHours: Scalars['String'];
  skills: Array<Scalars['String']>;
  startDate: Scalars['Timestamp'];
  taRecruiter: UserInput;
  term: Array<Scalars['String']>;
  title: Scalars['String'];
  totalPositions: Scalars['Int'];
  weeks: Scalars['Int'];
};

export enum Degree {
  Postgraduate = 'Postgraduate',
  Undergraduate = 'Undergraduate'
}

export type DocumentProfileUpdateInput = {
  bankUrl: Scalars['String'];
  hrFormUrl: Scalars['String'];
  passportUrl: Scalars['String'];
  visaUrl: Scalars['String'];
};

export type EmailTemplate = {
  template: Scalars['String'];
  type: EmailType;
};

export enum EmailType {
  MODULE_BUDGET_APPROVED = 'MODULE_BUDGET_APPROVED',
  MODULE_BUDGET_REJECTED = 'MODULE_BUDGET_REJECTED',
  NEW_PGTA_APPLICATION = 'NEW_PGTA_APPLICATION',
  PASSWORD_RESET = 'PASSWORD_RESET',
  PGTA_APPLICATION_APPROVED = 'PGTA_APPLICATION_APPROVED',
  PGTA_APPLICATION_REJECTED = 'PGTA_APPLICATION_REJECTED',
  PGTA_CONTRACT_ISSUED = 'PGTA_CONTRACT_ISSUED',
  RTW_CHECKED = 'RTW_CHECKED'
}

export type ExternalApplicantRegistry = {
  id: Scalars['Int'];
  registrationDate: Scalars['Timestamp'];
  user: PGTA;
};

export type ExternalTARegistrationForm = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  moduleCode?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  university: Scalars['String'];
};

export type ExtraBudgetApplication = {
  applicationDate: Scalars['Timestamp'];
  approvedHours?: Maybe<Scalars['Int']>;
  decisionDate?: Maybe<Scalars['Timestamp']>;
  id: Scalars['Int'];
  jobDescription: Scalars['String'];
  marking: Scalars['String'];
  module: Course;
  status: ExtraBudgetStatus;
  support: Scalars['String'];
  teaching: Scalars['String'];
  term: Array<Scalars['String']>;
  totalHours: Scalars['Int'];
};

export type ExtraBudgetApplicationForm = {
  jobDescription: Scalars['String'];
  marking: Scalars['String'];
  moduleCode: Scalars['String'];
  support: Scalars['String'];
  teaching: Scalars['String'];
  term: Array<Scalars['String']>;
  totalHours: Scalars['Int'];
};

export enum ExtraBudgetStatus {
  Approved = 'Approved',
  Rejected = 'Rejected',
  Submitted = 'Submitted'
}

export type Faq = {
  answer: Scalars['String'];
  createDate: Scalars['Timestamp'];
  id: Scalars['Int'];
  question: Scalars['String'];
  updateDate: Scalars['Timestamp'];
};

export type FaqCreateInput = {
  answer: Scalars['String'];
  question: Scalars['String'];
};

export type FaqUpdateInput = {
  answer: Scalars['String'];
  id: Scalars['Int'];
  question: Scalars['String'];
};

export type InitialBudgetApplicationForm = {
  budgetOption: Scalars['String'];
  jobDescription?: InputMaybe<Scalars['String']>;
  marking?: InputMaybe<Scalars['String']>;
  moduleCode: Scalars['String'];
  support?: InputMaybe<Scalars['String']>;
  teaching?: InputMaybe<Scalars['String']>;
  term: Array<Scalars['String']>;
  totalHours?: InputMaybe<Scalars['Int']>;
};

export enum InitialBudgetStatus {
  Approved = 'Approved',
  Submitted = 'Submitted'
}

export enum JobStatus {
  Approved = 'Approved',
  ContractIssued = 'ContractIssued'
}

export type LoginPayload = {
  accessToken: Scalars['String'];
  user: User;
};

export type ModuleLeader = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['Int'];
  lastName: Scalars['String'];
  modules: Array<Course>;
  name: Scalars['String'];
  permissions: Array<Permission>;
  title?: Maybe<Scalars['String']>;
};

export type Mutation = {
  addFavorite: Scalars['Boolean'];
  addRecruiterToModule?: Maybe<Scalars['Boolean']>;
  apply?: Maybe<Scalars['Boolean']>;
  approveApplication?: Maybe<Scalars['Boolean']>;
  approveExtraBudget?: Maybe<Scalars['Boolean']>;
  approveInitialBudget?: Maybe<Scalars['Boolean']>;
  checkRTW?: Maybe<Scalars['Boolean']>;
  createAdminTeamMember?: Maybe<Scalars['Boolean']>;
  createFaq?: Maybe<Scalars['Boolean']>;
  createModule?: Maybe<Scalars['Boolean']>;
  createVacancy?: Maybe<Scalars['Boolean']>;
  deleteAdminTeamMember?: Maybe<Scalars['Boolean']>;
  deleteExtraBudget?: Maybe<Scalars['Boolean']>;
  deleteFaq?: Maybe<Scalars['Boolean']>;
  deleteVacancy?: Maybe<Scalars['Boolean']>;
  editTemplate?: Maybe<Scalars['Boolean']>;
  issueContract?: Maybe<Scalars['Boolean']>;
  loginPGTA: LoginPayload;
  logout?: Maybe<Scalars['Boolean']>;
  markVacancyFull?: Maybe<Scalars['Boolean']>;
  readAllNotifications?: Maybe<Scalars['Boolean']>;
  registerExternalTA?: Maybe<Scalars['Boolean']>;
  rejectApplication?: Maybe<Scalars['Boolean']>;
  rejectExtraBudget?: Maybe<Scalars['Boolean']>;
  removeFavorite: Scalars['Boolean'];
  removeRecruiterFromModule?: Maybe<Scalars['Boolean']>;
  reopenVacancy?: Maybe<Scalars['Boolean']>;
  resetPassword?: Maybe<Scalars['Boolean']>;
  saveApplication?: Maybe<Scalars['Boolean']>;
  saveExtraBudget?: Maybe<Scalars['Boolean']>;
  sendVerificationEmail?: Maybe<Scalars['Boolean']>;
  submitInitialBudget?: Maybe<Scalars['Boolean']>;
  updateAdminTeamMemberRole?: Maybe<Scalars['Boolean']>;
  updateDocument?: Maybe<Scalars['Boolean']>;
  updateExtraBudget?: Maybe<Scalars['Boolean']>;
  updateFaq?: Maybe<Scalars['Boolean']>;
  updateModule?: Maybe<Scalars['Boolean']>;
  updateProfile?: Maybe<Scalars['Boolean']>;
  updateSystemSettings?: Maybe<Scalars['Boolean']>;
  updateVacancy?: Maybe<Scalars['Boolean']>;
};


export type MutationaddFavoriteArgs = {
  vacancyId: Scalars['Int'];
};


export type MutationaddRecruiterToModuleArgs = {
  data: AddRecruiterInput;
};


export type MutationapplyArgs = {
  applicationForm: ApplicationForm;
};


export type MutationapproveApplicationArgs = {
  applicationId: Scalars['Int'];
};


export type MutationapproveExtraBudgetArgs = {
  applicationId: Scalars['Int'];
  hours: Scalars['Int'];
};


export type MutationapproveInitialBudgetArgs = {
  applicationId: Scalars['Int'];
  hours: Scalars['Int'];
};


export type MutationcheckRTWArgs = {
  email: Scalars['String'];
};


export type MutationcreateAdminTeamMemberArgs = {
  data: AdminTeamRoleUpdateInput;
};


export type MutationcreateFaqArgs = {
  data: FaqCreateInput;
};


export type MutationcreateModuleArgs = {
  data: CourseCreateInput;
};


export type MutationcreateVacancyArgs = {
  form: CreateVacancyFormInput;
};


export type MutationdeleteAdminTeamMemberArgs = {
  email: Scalars['String'];
};


export type MutationdeleteExtraBudgetArgs = {
  applicationId: Scalars['Int'];
};


export type MutationdeleteFaqArgs = {
  id: Scalars['Int'];
};


export type MutationdeleteVacancyArgs = {
  vacancyId: Scalars['Int'];
};


export type MutationeditTemplateArgs = {
  template: Scalars['String'];
  type: EmailType;
};


export type MutationissueContractArgs = {
  userId: Scalars['Int'];
};


export type MutationloginPGTAArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationmarkVacancyFullArgs = {
  vacancyId: Scalars['Int'];
};


export type MutationregisterExternalTAArgs = {
  form: ExternalTARegistrationForm;
};


export type MutationrejectApplicationArgs = {
  applicationId: Scalars['Int'];
  reason: Scalars['String'];
};


export type MutationrejectExtraBudgetArgs = {
  applicationId: Scalars['Int'];
};


export type MutationremoveFavoriteArgs = {
  vacancyId: Scalars['Int'];
};


export type MutationremoveRecruiterFromModuleArgs = {
  email: Scalars['String'];
  moduleCode: Scalars['String'];
};


export type MutationreopenVacancyArgs = {
  vacancyId: Scalars['Int'];
};


export type MutationresetPasswordArgs = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};


export type MutationsaveApplicationArgs = {
  applicationForm: ApplicationForm;
};


export type MutationsaveExtraBudgetArgs = {
  applicationForm: ExtraBudgetApplicationForm;
};


export type MutationsendVerificationEmailArgs = {
  email: Scalars['String'];
};


export type MutationsubmitInitialBudgetArgs = {
  applicationForm: InitialBudgetApplicationForm;
};


export type MutationupdateAdminTeamMemberRoleArgs = {
  data: AdminTeamRoleUpdateInput;
};


export type MutationupdateDocumentArgs = {
  data: DocumentProfileUpdateInput;
};


export type MutationupdateExtraBudgetArgs = {
  applicationForm: UpdateExtraBudgetApplicationForm;
};


export type MutationupdateFaqArgs = {
  data: FaqUpdateInput;
};


export type MutationupdateModuleArgs = {
  data: CourseUpdateInput;
};


export type MutationupdateProfileArgs = {
  data: BasicProfileUpdateInput;
};


export type MutationupdateSystemSettingsArgs = {
  data: SystemSettingsUpdateInput;
};


export type MutationupdateVacancyArgs = {
  form: UpdateVacancyFormInput;
};

export type Notification = {
  body: Scalars['String'];
  createTime: Scalars['Timestamp'];
  id: Scalars['Int'];
  read: Scalars['Boolean'];
  subject: Scalars['String'];
};

export type OffsetBudgetApplication = {
  count: Scalars['Int'];
  data: Array<BudgetApplication>;
};

export type OffsetContracts = {
  count: Scalars['Int'];
  data: Array<PGTA>;
};

export type OffsetCourse = {
  count: Scalars['Int'];
  data: Array<Course>;
};

export type OffsetExtraBudgetApplication = {
  count: Scalars['Int'];
  data: Array<ExtraBudgetApplication>;
};

export type OffsetPGTA = {
  count: Scalars['Int'];
  data: Array<PGTA>;
};

export type PGTA = {
  applications: Array<TAApplication>;
  email: Scalars['String'];
  favorites: Array<TAFavorites>;
  firstName: Scalars['String'];
  id: Scalars['Int'];
  jobs: Array<TAJob>;
  lastName: Scalars['String'];
  name: Scalars['String'];
  permissions: Array<Permission>;
  profile: TAProfile;
  title?: Maybe<Scalars['String']>;
};

export type PageInfo = {
  endCursor?: Maybe<Scalars['Cursor']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['Cursor']>;
};

export type PaginatedTAApplication = {
  count: Scalars['Int'];
  data: Array<TAApplication>;
  remainingBudgetHours?: Maybe<Scalars['Int']>;
  spentBudgetHours?: Maybe<Scalars['Int']>;
  totalBudgetHours?: Maybe<Scalars['Int']>;
};

export type PaginatedVacancy = {
  edges?: Maybe<Array<VacancyEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type Permission = {
  id: Scalars['Int'];
  moduleId: Scalars['Int'];
  role: UserRole;
};

export type Query = {
  authProfile: User;
  findAdminTeamMember?: Maybe<User>;
  findAllAdminTeamMembers: Array<User>;
  findAllApplications: PaginatedTAApplication;
  findAllContracts: OffsetContracts;
  findAllExternalApplicantRegistry: Array<ExternalApplicantRegistry>;
  findAllExtraBudgetApplications: OffsetExtraBudgetApplication;
  findAllExtraBudgetApplicationsForLecturer: Array<ExtraBudgetApplication>;
  findAllFaqs: Array<Faq>;
  findAllFavorites: Array<TAFavorites>;
  findAllInitialBudgetApplications: OffsetBudgetApplication;
  findAllInitialBudgetApplicationsForLecturer: Array<BudgetApplication>;
  findAllJobs: Array<TAJob>;
  findAllModuleRecruiters: Array<TARecruiter>;
  findAllModuleVacanciesByCode: Array<Vacancy>;
  findAllModules: Array<Course>;
  /** @deprecated All module start with 'COMP', fuzzy search when typing will lead to huge network traffic without significant performance improvement */
  findAllModulesWithCode: Array<Course>;
  findAllModulesWithPagination: OffsetCourse;
  findAllNotifications: Array<Notification>;
  findAllTAs: Array<PGTA>;
  findAllTAsWithRTWStatus: OffsetPGTA;
  findAllTAsWithTotalHours: OffsetPGTA;
  findAllTemplates: Array<EmailTemplate>;
  findAllVacancies: PaginatedVacancy;
  findApplication?: Maybe<TAApplication>;
  findContract?: Maybe<PGTA>;
  findExtraBudgetApplication?: Maybe<ExtraBudgetApplication>;
  findFaq?: Maybe<Faq>;
  findInitialBudgetByCode?: Maybe<BudgetApplication>;
  findInitialBudgetById?: Maybe<BudgetApplication>;
  findJob?: Maybe<TAJob>;
  findModule?: Maybe<Course>;
  findModuleByCode?: Maybe<Course>;
  findTAById?: Maybe<PGTA>;
  findUserWithEmail: Array<User>;
  findUserWithName: Array<User>;
  findVacancy?: Maybe<Vacancy>;
  myModules: Array<Course>;
  systemSettings: SystemSettings;
  taDashboard: TADashboard;
  taProfile: TAProfile;
};


export type QueryfindAdminTeamMemberArgs = {
  email: Scalars['String'];
};


export type QueryfindAllApplicationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  email?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  moduleCode?: InputMaybe<Scalars['String']>;
  status: Array<ApplicationStatus>;
};


export type QueryfindAllContractsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  email?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  status: JobStatus;
};


export type QueryfindAllExtraBudgetApplicationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  moduleCode?: InputMaybe<Scalars['String']>;
  status: ExtraBudgetStatus;
};


export type QueryfindAllExtraBudgetApplicationsForLecturerArgs = {
  moduleCode?: InputMaybe<Scalars['String']>;
};


export type QueryfindAllInitialBudgetApplicationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  moduleCode?: InputMaybe<Scalars['String']>;
  status: InitialBudgetStatus;
};


export type QueryfindAllModuleRecruitersArgs = {
  moduleCode: Scalars['String'];
};


export type QueryfindAllModuleVacanciesByCodeArgs = {
  moduleCode: Scalars['String'];
};


export type QueryfindAllModulesWithCodeArgs = {
  moduleCode: Scalars['String'];
};


export type QueryfindAllModulesWithPaginationArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  moduleCode?: InputMaybe<Scalars['String']>;
};


export type QueryfindAllTAsArgs = {
  moduleCode: Scalars['String'];
};


export type QueryfindAllTAsWithRTWStatusArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  email?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  rtwStatus: RTWStatus;
};


export type QueryfindAllTAsWithTotalHoursArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  email?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  totalHoursOption?: InputMaybe<TotalHoursOption>;
};


export type QueryfindAllVacanciesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  degree?: InputMaybe<Array<Degree>>;
  first?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  searchBy?: InputMaybe<SearchByOption>;
  term?: InputMaybe<Array<Term>>;
  vacancyState?: InputMaybe<VacancyState>;
};


export type QueryfindApplicationArgs = {
  applicationId?: InputMaybe<Scalars['Int']>;
  vacancyId?: InputMaybe<Scalars['Int']>;
};


export type QueryfindContractArgs = {
  userId: Scalars['Int'];
};


export type QueryfindExtraBudgetApplicationArgs = {
  applicationId: Scalars['Int'];
};


export type QueryfindFaqArgs = {
  id: Scalars['Int'];
};


export type QueryfindInitialBudgetByCodeArgs = {
  moduleCode: Scalars['String'];
};


export type QueryfindInitialBudgetByIdArgs = {
  applicationId: Scalars['Int'];
};


export type QueryfindJobArgs = {
  jobId: Scalars['Int'];
};


export type QueryfindModuleArgs = {
  moduleId: Scalars['Int'];
};


export type QueryfindModuleByCodeArgs = {
  moduleCode: Scalars['String'];
};


export type QueryfindTAByIdArgs = {
  id: Scalars['Int'];
};


export type QueryfindUserWithEmailArgs = {
  email: Scalars['String'];
};


export type QueryfindUserWithNameArgs = {
  name: Scalars['String'];
};


export type QueryfindVacancyArgs = {
  vacancyId: Scalars['Int'];
};

export enum RTWStatus {
  Approved = 'Approved',
  Pending = 'Pending',
  Submitted = 'Submitted'
}

export enum SearchByOption {
  All = 'All',
  ModuleCode = 'ModuleCode',
  ModuleLeader = 'ModuleLeader',
  ModuleTitle = 'ModuleTitle'
}

/** Home or International Students */
export enum StudentType {
  Home = 'Home',
  International = 'International'
}

export type SystemSettings = {
  applicationDeadline: Scalars['Timestamp'];
  id: Scalars['Int'];
  minimumWorkingHours: Scalars['Int'];
  taAllowedWorkingHours: Scalars['Int'];
};

export type SystemSettingsUpdateInput = {
  applicationDeadline: Scalars['Timestamp'];
  id: Scalars['Int'];
  minimumWorkingHours: Scalars['Int'];
  taAllowedWorkingHours: Scalars['Int'];
};

export type TAApplication = {
  applicant: PGTA;
  applicationDate: Scalars['Timestamp'];
  appliedHours: Scalars['Int'];
  decisionDate: Scalars['Timestamp'];
  id: Scalars['Int'];
  module: Course;
  motivationLetter?: Maybe<Scalars['String']>;
  status: ApplicationStatus;
  vacancy: Vacancy;
};

export type TADashboard = {
  applications: Array<TAApplication>;
  favorites: Array<TAFavorites>;
  jobs: Array<TAJob>;
  remainingWorkingHours: Scalars['Int'];
  totalVacancies: Scalars['Int'];
  totalWorkingHours: Scalars['Int'];
  vacancies: Array<Vacancy>;
};


export type TADashboardvacanciesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  degree?: InputMaybe<Array<Degree>>;
  first?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  searchBy?: InputMaybe<SearchByOption>;
  term?: InputMaybe<Array<Term>>;
  vacancyState?: InputMaybe<VacancyState>;
};

export type TAFavorites = {
  applied: Scalars['Boolean'];
  createdDate: Scalars['Timestamp'];
  id: Scalars['Int'];
  user: PGTA;
  vacancy: Vacancy;
};

export type TAJob = {
  approvedDate: Scalars['Timestamp'];
  contractIssuedDate?: Maybe<Scalars['Timestamp']>;
  hours: Scalars['Int'];
  id: Scalars['Int'];
  module: Course;
  status: JobStatus;
  user: PGTA;
  vacancy: Vacancy;
};

export type TAProfile = {
  allowWorkingHours: Scalars['Int'];
  bankUrl?: Maybe<Scalars['String']>;
  cvUrl?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  homeAddress?: Maybe<Scalars['String']>;
  hrFormUrl?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  jobRole?: Maybe<Scalars['String']>;
  needTraining: Scalars['Boolean'];
  passportUrl?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  researchGroup?: Maybe<Scalars['String']>;
  rtwCheckedDate?: Maybe<Scalars['Timestamp']>;
  rtwStatus: RTWStatus;
  studentType: StudentType;
  totalHours: Scalars['Int'];
  university?: Maybe<Scalars['String']>;
  visaUrl?: Maybe<Scalars['String']>;
  workBefore: Scalars['Boolean'];
  year?: Maybe<Scalars['String']>;
};

export type TARecruiter = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['Int'];
  lastName: Scalars['String'];
  name: Scalars['String'];
  permissions: Array<Permission>;
  recruitModules: Array<Course>;
  recruitVacancies: Array<Vacancy>;
  title?: Maybe<Scalars['String']>;
};

export enum Term {
  Term1 = 'Term1',
  Term2 = 'Term2',
  Term3 = 'Term3'
}

export enum TotalHoursOption {
  All = 'All',
  LessThan40 = 'LessThan40',
  MoreThan40 = 'MoreThan40'
}

export type UpdateExtraBudgetApplicationForm = {
  applicationId: Scalars['Int'];
  jobDescription: Scalars['String'];
  marking: Scalars['String'];
  moduleCode: Scalars['String'];
  support: Scalars['String'];
  teaching: Scalars['String'];
  term: Array<Scalars['String']>;
  totalHours: Scalars['Int'];
};

export type UpdateVacancyFormInput = {
  academicYear: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  details: Scalars['String'];
  endDate: Scalars['Timestamp'];
  moduleCode: Scalars['String'];
  offeredHours: Scalars['String'];
  skills: Array<Scalars['String']>;
  startDate: Scalars['Timestamp'];
  taRecruiter: UserInput;
  term: Array<Scalars['String']>;
  title: Scalars['String'];
  totalPositions: Scalars['Int'];
  vacancyId: Scalars['Int'];
  weeks: Scalars['Int'];
};

export type User = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['Int'];
  lastName: Scalars['String'];
  name: Scalars['String'];
  permissions: Array<Permission>;
  title?: Maybe<Scalars['String']>;
};

export type UserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  title: Scalars['String'];
};

/** The user role defined in microsoft active directory */
export enum UserRole {
  AcademicLeader = 'AcademicLeader',
  HR = 'HR',
  ModuleLeader = 'ModuleLeader',
  PGTA = 'PGTA',
  TARecruiter = 'TARecruiter'
}

export type Vacancy = {
  academicYear: Scalars['Int'];
  createdDate: Scalars['Timestamp'];
  description?: Maybe<Scalars['String']>;
  details: Scalars['String'];
  endDate: Scalars['Timestamp'];
  id: Scalars['Int'];
  isFull: Scalars['Boolean'];
  isTAFavorite?: Maybe<Scalars['Boolean']>;
  isTeaching?: Maybe<Scalars['Boolean']>;
  module: Course;
  offeredHours: Scalars['String'];
  skills: Array<Scalars['String']>;
  startDate: Scalars['Timestamp'];
  taRecruiter: TARecruiter;
  term: Array<Scalars['String']>;
  title: Scalars['String'];
  totalPositions: Scalars['Int'];
  weeks: Scalars['Int'];
};

export type VacancyEdge = {
  cursor: Scalars['Cursor'];
  node: Vacancy;
};

export enum VacancyState {
  Full = 'Full',
  Recruiting = 'Recruiting'
}

export type LoginPGTAMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginPGTAMutation = { loginPGTA: { accessToken: string, user: { id: number, title?: string | null, name: string, firstName: string, lastName: string, email: string, permissions: Array<{ role: UserRole }> } } };

export type LogoutPGTAMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutPGTAMutation = { logout?: boolean | null };

export type SendVerificationEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SendVerificationEmailMutation = { sendVerificationEmail?: boolean | null };

export type ResetPasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  token: Scalars['String'];
}>;


export type ResetPasswordMutation = { resetPassword?: boolean | null };

export type FindAllInitialBudgetApplicationsForLecturerQueryVariables = Exact<{ [key: string]: never; }>;


export type FindAllInitialBudgetApplicationsForLecturerQuery = { findAllInitialBudgetApplicationsForLecturer: Array<{ totalHours?: number | null, applicationDate: Date, approvedHours?: number | null, approvedDate?: Date | null, status: InitialBudgetStatus, module: { code: string } }> };

export type FindAllContractsQueryVariables = Exact<{
  status: JobStatus;
  first: Scalars['Int'];
  after: Scalars['Cursor'];
  email?: InputMaybe<Scalars['String']>;
}>;


export type FindAllContractsQuery = { findAllContracts: { count: number, data: Array<{ id: number, name: string, email: string, profile: { jobRole?: string | null, studentType: StudentType, totalHours: number }, jobs: Array<{ module: { code: string } }> }> } };

export type FindContractQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type FindContractQuery = { findContract?: { id: number, name: string, email: string, profile: { department?: string | null, university?: string | null, studentType: StudentType, passportUrl?: string | null, visaUrl?: string | null, bankUrl?: string | null, hrFormUrl?: string | null }, jobs: Array<{ hours: number, status: JobStatus, approvedDate: Date, contractIssuedDate?: Date | null, module: { code: string, title: string, moduleLeader: { name: string } }, vacancy: { title: string, taRecruiter: { name: string } } }> } | null };

export type IssueContractMutationVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type IssueContractMutation = { issueContract?: boolean | null };

export type MyModuleMenusQueryVariables = Exact<{ [key: string]: never; }>;


export type MyModuleMenusQuery = { myModules: Array<{ code: string, title: string, myRole: UserRole }> };

export type MyModulesQueryVariables = Exact<{ [key: string]: never; }>;


export type MyModulesQuery = { myModules: Array<{ title: string, code: string, remainingHours?: number | null, confirmedTAs: number, applicationAmount: number, myRole: UserRole, initialBudget?: { approvedHours?: number | null, approvedDate?: Date | null } | null }> };

export type MyModuleProfileQueryVariables = Exact<{
  moduleCode: Scalars['String'];
}>;


export type MyModuleProfileQuery = { findModuleByCode?: { id: number, code: string, title: string, degree?: string | null, term: Array<string>, description?: string | null, permission: CoursePermission, moduleLeader: { name: string, email: string } } | null };

export type ApprovedApplicationsQueryVariables = Exact<{
  first: Scalars['Int'];
  after: Scalars['Cursor'];
  moduleCode?: InputMaybe<Scalars['String']>;
}>;


export type ApprovedApplicationsQuery = { findAllApplications: { count: number, data: Array<{ id: number, appliedHours: number, decisionDate: Date, status: ApplicationStatus, applicant: { name: string, profile: { jobRole?: string | null, researchGroup?: string | null, studentType: StudentType } }, vacancy: { title: string }, module: { code: string } }> } };

export type PendingApplicationsQueryVariables = Exact<{
  first: Scalars['Int'];
  after: Scalars['Cursor'];
  moduleCode?: InputMaybe<Scalars['String']>;
}>;


export type PendingApplicationsQuery = { findAllApplications: { count: number, remainingBudgetHours?: number | null, totalBudgetHours?: number | null, spentBudgetHours?: number | null, data: Array<{ id: number, appliedHours: number, motivationLetter?: string | null, applicationDate: Date, applicant: { name: string, profile: { jobRole?: string | null, researchGroup?: string | null, studentType: StudentType } }, module: { code: string }, vacancy: { title: string } }> } };

export type FindApplicationQueryVariables = Exact<{
  applicationId: Scalars['Int'];
}>;


export type FindApplicationQuery = { findApplication?: { id: number, appliedHours: number, motivationLetter?: string | null, applicant: { name: string, email: string, profile: { researchGroup?: string | null, department?: string | null, university?: string | null, jobRole?: string | null, year?: string | null, studentType: StudentType, allowWorkingHours: number, needTraining: boolean, workBefore: boolean, cvUrl?: string | null } }, vacancy: { title: string } } | null };

export type FindInitialBudgetApplicationForLecturerQueryVariables = Exact<{
  moduleCode: Scalars['String'];
}>;


export type FindInitialBudgetApplicationForLecturerQuery = { findInitialBudgetByCode?: { status: InitialBudgetStatus, approvedHours?: number | null, term: Array<string>, budgetOption: string, totalHours?: number | null, jobDescription?: string | null, teaching?: string | null, support?: string | null, marking?: string | null, applicationDate: Date, approvedDate?: Date | null } | null };

export type FindAllModuleVacanciesQueryVariables = Exact<{
  moduleCode: Scalars['String'];
}>;


export type FindAllModuleVacanciesQuery = { findAllModuleVacanciesByCode: Array<{ id: number, title: string, offeredHours: string, weeks: number, term: Array<string>, skills: Array<string>, startDate: Date, endDate: Date, createdDate: Date, isFull: boolean, taRecruiter: { email: string } }> };

export type FindModuleVacancyQueryVariables = Exact<{
  vacancyId: Scalars['Int'];
}>;


export type FindModuleVacancyQuery = { findVacancy?: { title: string, description?: string | null, skills: Array<string>, term: Array<string>, weeks: number, totalPositions: number, startDate: Date, endDate: Date, offeredHours: string, academicYear: number, details: string, taRecruiter: { title?: string | null, email: string, firstName: string, lastName: string } } | null };

export type FindAllModuleRecruitersQueryVariables = Exact<{
  moduleCode: Scalars['String'];
}>;


export type FindAllModuleRecruitersQuery = { findAllModuleRecruiters: Array<{ name: string, email: string, recruitVacancies: Array<{ title: string, module: { code: string } }> }> };

export type FuzzySearchUserByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type FuzzySearchUserByEmailQuery = { findUserWithEmail: Array<{ name: string, title?: string | null, firstName: string, lastName: string, email: string }> };

export type FindModuleVacancyOptionsQueryVariables = Exact<{
  moduleCode: Scalars['String'];
}>;


export type FindModuleVacancyOptionsQuery = { findAllModuleVacanciesByCode: Array<{ id: number, title: string }> };

export type FindAllExtraBudgetApplicationsForLecturerQueryVariables = Exact<{
  moduleCode?: InputMaybe<Scalars['String']>;
}>;


export type FindAllExtraBudgetApplicationsForLecturerQuery = { findAllExtraBudgetApplicationsForLecturer: Array<{ id: number, totalHours: number, applicationDate: Date, approvedHours?: number | null, decisionDate?: Date | null, status: ExtraBudgetStatus, module: { code: string } }> };

export type FindExtraBudgetApplicationQueryVariables = Exact<{
  applicationId: Scalars['Int'];
}>;


export type FindExtraBudgetApplicationQuery = { findExtraBudgetApplication?: { term: Array<string>, teaching: string, support: string, marking: string, totalHours: number, jobDescription: string } | null };

export type AddRecruiterToModuleMutationVariables = Exact<{
  data: AddRecruiterInput;
}>;


export type AddRecruiterToModuleMutation = { addRecruiterToModule?: boolean | null };

export type RemoveRecruiterFromModuleMutationVariables = Exact<{
  moduleCode: Scalars['String'];
  email: Scalars['String'];
}>;


export type RemoveRecruiterFromModuleMutation = { removeRecruiterFromModule?: boolean | null };

export type UpdateModuleMutationVariables = Exact<{
  data: CourseUpdateInput;
}>;


export type UpdateModuleMutation = { updateModule?: boolean | null };

export type ApproveApplicationMutationVariables = Exact<{
  applicationId: Scalars['Int'];
}>;


export type ApproveApplicationMutation = { approveApplication?: boolean | null };

export type RejectApplicationMutationVariables = Exact<{
  applicationId: Scalars['Int'];
  reason: Scalars['String'];
}>;


export type RejectApplicationMutation = { rejectApplication?: boolean | null };

export type SubmitInitialBudgetApplicationMutationVariables = Exact<{
  form: InitialBudgetApplicationForm;
}>;


export type SubmitInitialBudgetApplicationMutation = { submitInitialBudget?: boolean | null };

export type DeleteVacancyMutationVariables = Exact<{
  vacancyId: Scalars['Int'];
}>;


export type DeleteVacancyMutation = { deleteVacancy?: boolean | null };

export type MarkVacancyFullMutationVariables = Exact<{
  vacancyId: Scalars['Int'];
}>;


export type MarkVacancyFullMutation = { markVacancyFull?: boolean | null };

export type ReopenVacancyMutationVariables = Exact<{
  vacancyId: Scalars['Int'];
}>;


export type ReopenVacancyMutation = { reopenVacancy?: boolean | null };

export type CreateVacancyMutationVariables = Exact<{
  form: CreateVacancyFormInput;
}>;


export type CreateVacancyMutation = { createVacancy?: boolean | null };

export type UpdateVacancyMutationVariables = Exact<{
  form: UpdateVacancyFormInput;
}>;


export type UpdateVacancyMutation = { updateVacancy?: boolean | null };

export type SaveExtraBudgetApplicationMutationVariables = Exact<{
  form: ExtraBudgetApplicationForm;
}>;


export type SaveExtraBudgetApplicationMutation = { saveExtraBudget?: boolean | null };

export type UpdateExtraBudgetApplicationMutationVariables = Exact<{
  form: UpdateExtraBudgetApplicationForm;
}>;


export type UpdateExtraBudgetApplicationMutation = { updateExtraBudget?: boolean | null };

export type DeleteExtraBudgetApplicationMutationVariables = Exact<{
  applicationId: Scalars['Int'];
}>;


export type DeleteExtraBudgetApplicationMutation = { deleteExtraBudget?: boolean | null };

export type FindAllModuleOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type FindAllModuleOptionsQuery = { findAllModules: Array<{ id: number, code: string, title: string, vacancies: Array<{ id: number, title: string, isFull: boolean }> }> };

export type FindAllVacanciesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['Cursor']>;
  degree?: InputMaybe<Array<Degree> | Degree>;
  term?: InputMaybe<Array<Term> | Term>;
  vacancyState?: InputMaybe<VacancyState>;
  search?: InputMaybe<Scalars['String']>;
  searchBy?: InputMaybe<SearchByOption>;
}>;


export type FindAllVacanciesQuery = { findAllVacancies: { edges?: Array<{ cursor: number, node: { id: number, title: string, weeks: number, term: Array<string>, skills: Array<string>, offeredHours: string, totalPositions: number, isFull: boolean, isTAFavorite?: boolean | null, taRecruiter: { email: string }, module: { title: string, code: string } } }> | null, pageInfo: { hasNextPage: boolean, endCursor?: number | null } } };

export type FindAllFavoritesQueryVariables = Exact<{ [key: string]: never; }>;


export type FindAllFavoritesQuery = { findAllFavorites: Array<{ applied: boolean, createdDate: Date, vacancy: { id: number, title: string, skills: Array<string>, offeredHours: string, weeks: number, term: Array<string>, taRecruiter: { email: string }, module: { title: string, code: string, moduleLeader: { name: string } } } }> };

export type AddFavoriteMutationVariables = Exact<{
  vacancyId: Scalars['Int'];
}>;


export type AddFavoriteMutation = { addFavorite: boolean };

export type RemoveShortlistedVacancyMutationVariables = Exact<{
  vacancyId: Scalars['Int'];
}>;


export type RemoveShortlistedVacancyMutation = { removeFavorite: boolean };

export type TADashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type TADashboardQuery = { taDashboard: { totalVacancies: number, totalWorkingHours: number, remainingWorkingHours: number, jobs: Array<{ hours: number, module: { title: string } }>, applications: Array<{ applicationDate: Date, status: ApplicationStatus, appliedHours: number, module: { title: string, moduleLeader: { name: string } } }>, vacancies: Array<{ term: Array<string>, offeredHours: string, module: { title: string, code: string, moduleLeader: { name: string } } }>, favorites: Array<{ vacancy: { offeredHours: string, module: { title: string } } }> } };

export type FindAllTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type FindAllTemplatesQuery = { findAllTemplates: Array<{ type: EmailType, template: string }> };

export type EditTemplateMutationVariables = Exact<{
  type: EmailType;
  template: Scalars['String'];
}>;


export type EditTemplateMutation = { editTemplate?: boolean | null };

export type FindAllExternalApplicantsQueryVariables = Exact<{ [key: string]: never; }>;


export type FindAllExternalApplicantsQuery = { findAllExternalApplicantRegistry: Array<{ registrationDate: Date, user: { name: string, email: string, profile: { university?: string | null } } }> };

export type RegisterExternalApplicantMutationVariables = Exact<{
  form: ExternalTARegistrationForm;
}>;


export type RegisterExternalApplicantMutation = { registerExternalTA?: boolean | null };

export type FindAllFaqsQueryVariables = Exact<{ [key: string]: never; }>;


export type FindAllFaqsQuery = { findAllFaqs: Array<{ id: number, question: string, answer: string }> };

export type FindVacancyQueryVariables = Exact<{
  vacancyId: Scalars['Int'];
}>;


export type FindVacancyQuery = { findVacancy?: { id: number, title: string, term: Array<string>, weeks: number, description?: string | null, skills: Array<string>, totalPositions: number, isFull: boolean, startDate: Date, endDate: Date, offeredHours: string, academicYear: number, details: string, taRecruiter: { name: string, email: string }, module: { id: number, code: string, title: string, degree?: string | null, description?: string | null, moduleLeader: { name: string, email: string } } } | null };

export type FindPreviousApplicationQueryVariables = Exact<{
  vacancyId: Scalars['Int'];
}>;


export type FindPreviousApplicationQuery = { findApplication?: { status: ApplicationStatus, applicationDate: Date, appliedHours: number, motivationLetter?: string | null, vacancy: { id: number, title: string } } | null };

export type FindTAApplicationsQueryVariables = Exact<{
  status: Array<ApplicationStatus> | ApplicationStatus;
}>;


export type FindTAApplicationsQuery = { findAllApplications: { data: Array<{ appliedHours: number, applicationDate: Date, status: ApplicationStatus, module: { title: string, code: string }, vacancy: { id: number, title: string, term: Array<string>, weeks: number, startDate: Date, endDate: Date, taRecruiter: { email: string } } }> } };

export type FindTAJobsQueryVariables = Exact<{ [key: string]: never; }>;


export type FindTAJobsQuery = { findAllJobs: Array<{ status: JobStatus, hours: number, module: { id: number, title: string, code: string }, vacancy: { id: number, title: string, term: Array<string>, weeks: number, startDate: Date, endDate: Date, taRecruiter: { email: string } } }> };

export type ApplyJobMutationVariables = Exact<{
  form: ApplicationForm;
}>;


export type ApplyJobMutation = { apply?: boolean | null };

export type SaveApplicationFormMutationVariables = Exact<{
  form: ApplicationForm;
}>;


export type SaveApplicationFormMutation = { saveApplication?: boolean | null };

export type FindAllInitialBudgetApplicationsQueryVariables = Exact<{
  first: Scalars['Int'];
  after: Scalars['Cursor'];
  status: InitialBudgetStatus;
  moduleCode?: InputMaybe<Scalars['String']>;
}>;


export type FindAllInitialBudgetApplicationsQuery = { findAllInitialBudgetApplications: { count: number, data: Array<{ id: number, totalHours?: number | null, applicationDate: Date, approvedHours?: number | null, approvedDate?: Date | null, module: { code: string, title: string, moduleLeader: { name: string } } }> } };

export type FindBudgetApplicationForReviewQueryVariables = Exact<{
  applicationId: Scalars['Int'];
}>;


export type FindBudgetApplicationForReviewQuery = { findInitialBudgetById?: { term: Array<string>, budgetOption: string, totalHours?: number | null, jobDescription?: string | null, teaching?: string | null, support?: string | null, marking?: string | null, module: { code: string, title: string, moduleLeader: { name: string } } } | null };

export type ApproveInitialBudgetApplicationMutationVariables = Exact<{
  applicationId: Scalars['Int'];
  hours: Scalars['Int'];
}>;


export type ApproveInitialBudgetApplicationMutation = { approveInitialBudget?: boolean | null };

export type FindAllExtraBudgetApplicationsQueryVariables = Exact<{
  first: Scalars['Int'];
  after: Scalars['Cursor'];
  status: ExtraBudgetStatus;
  moduleCode?: InputMaybe<Scalars['String']>;
}>;


export type FindAllExtraBudgetApplicationsQuery = { findAllExtraBudgetApplications: { count: number, data: Array<{ id: number, totalHours: number, applicationDate: Date, approvedHours?: number | null, decisionDate?: Date | null, module: { code: string, title: string, moduleLeader: { name: string } } }> } };

export type FindExtraBudgetApplicationForReviewQueryVariables = Exact<{
  applicationId: Scalars['Int'];
}>;


export type FindExtraBudgetApplicationForReviewQuery = { findExtraBudgetApplication?: { term: Array<string>, totalHours: number, jobDescription: string, teaching: string, support: string, marking: string, module: { code: string, title: string, moduleLeader: { name: string } } } | null };

export type FindAllModulesWithPaginationQueryVariables = Exact<{
  first: Scalars['Int'];
  after: Scalars['Cursor'];
  moduleCode?: InputMaybe<Scalars['String']>;
}>;


export type FindAllModulesWithPaginationQuery = { findAllModulesWithPagination: { count: number, data: Array<{ id: number, code: string, title: string, term: Array<string>, moduleLeader: { name: string } }> } };

export type FindModuleForReviewQueryVariables = Exact<{
  moduleId: Scalars['Int'];
}>;


export type FindModuleForReviewQuery = { findModule?: { code: string, title: string, degree?: string | null, term: Array<string>, description?: string | null, moduleLeader: { email: string, title?: string | null, firstName: string, lastName: string } } | null };

export type FindAllAdminTeamMembersQueryVariables = Exact<{ [key: string]: never; }>;


export type FindAllAdminTeamMembersQuery = { findAllAdminTeamMembers: Array<{ email: string, name: string, permissions: Array<{ role: UserRole }> }> };

export type FindAdminTeamMemberQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type FindAdminTeamMemberQuery = { findAdminTeamMember?: { email: string, title?: string | null, firstName: string, lastName: string, permissions: Array<{ role: UserRole }> } | null };

export type FindFaqListQueryVariables = Exact<{ [key: string]: never; }>;


export type FindFaqListQuery = { findAllFaqs: Array<{ id: number, question: string, updateDate: Date }> };

export type FindFaqQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type FindFaqQuery = { findFaq?: { question: string, answer: string } | null };

export type SystemSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type SystemSettingsQuery = { systemSettings: { id: number, minimumWorkingHours: number, taAllowedWorkingHours: number, applicationDeadline: Date } };

export type FindAllTasWithTotalHoursQueryVariables = Exact<{
  first: Scalars['Int'];
  after: Scalars['Cursor'];
  totalHoursOption: TotalHoursOption;
  email?: InputMaybe<Scalars['String']>;
}>;


export type FindAllTasWithTotalHoursQuery = { findAllTAsWithTotalHours: { count: number, data: Array<{ id: number, email: string, name: string, profile: { jobRole?: string | null, totalHours: number, studentType: StudentType }, jobs: Array<{ module: { id: number } }> }> } };

export type ApproveExtraBudgetApplicationMutationVariables = Exact<{
  applicationId: Scalars['Int'];
  hours: Scalars['Int'];
}>;


export type ApproveExtraBudgetApplicationMutation = { approveExtraBudget?: boolean | null };

export type RejectExtraBudgetApplicationMutationVariables = Exact<{
  applicationId: Scalars['Int'];
}>;


export type RejectExtraBudgetApplicationMutation = { rejectExtraBudget?: boolean | null };

export type CreateModuleMutationVariables = Exact<{
  data: CourseCreateInput;
}>;


export type CreateModuleMutation = { createModule?: boolean | null };

export type CreateAdminTeamMemberMutationVariables = Exact<{
  data: AdminTeamRoleUpdateInput;
}>;


export type CreateAdminTeamMemberMutation = { createAdminTeamMember?: boolean | null };

export type UpdateAdminTeamMemberRoleMutationVariables = Exact<{
  data: AdminTeamRoleUpdateInput;
}>;


export type UpdateAdminTeamMemberRoleMutation = { updateAdminTeamMemberRole?: boolean | null };

export type DeleteAdminTeamMemberMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type DeleteAdminTeamMemberMutation = { deleteAdminTeamMember?: boolean | null };

export type CreateFaqMutationVariables = Exact<{
  data: FaqCreateInput;
}>;


export type CreateFaqMutation = { createFaq?: boolean | null };

export type UpdateFaqMutationVariables = Exact<{
  data: FaqUpdateInput;
}>;


export type UpdateFaqMutation = { updateFaq?: boolean | null };

export type DeleteFaqMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteFaqMutation = { deleteFaq?: boolean | null };

export type UpdateSystemSettingsMutationVariables = Exact<{
  data: SystemSettingsUpdateInput;
}>;


export type UpdateSystemSettingsMutation = { updateSystemSettings?: boolean | null };

export type FindAllNotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type FindAllNotificationsQuery = { findAllNotifications: Array<{ subject: string, body: string, read: boolean, createTime: Date }> };

export type ReadAllNotificationMutationVariables = Exact<{ [key: string]: never; }>;


export type ReadAllNotificationMutation = { readAllNotifications?: boolean | null };

export type TABasicProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type TABasicProfileQuery = { taProfile: { jobRole?: string | null, allowWorkingHours: number, department?: string | null, phone?: string | null, researchGroup?: string | null, studentType: StudentType, university?: string | null, year?: string | null, homeAddress?: string | null, workBefore: boolean, needTraining: boolean, cvUrl?: string | null } };

export type TADocumentsQueryVariables = Exact<{ [key: string]: never; }>;


export type TADocumentsQuery = { taProfile: { passportUrl?: string | null, visaUrl?: string | null, rtwStatus: RTWStatus, bankUrl?: string | null, hrFormUrl?: string | null } };

export type UpdateBasicProfileMutationVariables = Exact<{
  data: BasicProfileUpdateInput;
}>;


export type UpdateBasicProfileMutation = { updateProfile?: boolean | null };

export type UpdateDocumentProfileMutationVariables = Exact<{
  data: DocumentProfileUpdateInput;
}>;


export type UpdateDocumentProfileMutation = { updateDocument?: boolean | null };

export type FindAllTAsWithRTWStatusQueryVariables = Exact<{
  rtwStatus: RTWStatus;
  first: Scalars['Int'];
  after: Scalars['Cursor'];
  email?: InputMaybe<Scalars['String']>;
}>;


export type FindAllTAsWithRTWStatusQuery = { findAllTAsWithRTWStatus: { count: number, data: Array<{ id: number, name: string, email: string, profile: { jobRole?: string | null, studentType: StudentType, passportUrl?: string | null, visaUrl?: string | null, rtwCheckedDate?: Date | null } }> } };

export type CheckRTWMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type CheckRTWMutation = { checkRTW?: boolean | null };

export type FindUserWithNameQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type FindUserWithNameQuery = { findUserWithName: Array<{ id: number, name: string, email: string }> };

export type FindTAProfileQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type FindTAProfileQuery = { findTAById?: { name: string, email: string, profile: { researchGroup?: string | null, department?: string | null, university?: string | null, jobRole?: string | null, year?: string | null, studentType: StudentType, allowWorkingHours: number, needTraining: boolean, workBefore: boolean, cvUrl?: string | null }, jobs: Array<{ hours: number, module: { title: string, code: string }, vacancy: { title: string, startDate: Date, endDate: Date, weeks: number, term: Array<string> } }> } | null };

export type FindAllTAByModuleCodeQueryVariables = Exact<{
  moduleCode: Scalars['String'];
}>;


export type FindAllTAByModuleCodeQuery = { findAllTAs: Array<{ id: number, name: string, email: string, profile: { studentType: StudentType }, jobs: Array<{ status: JobStatus, hours: number, approvedDate: Date, vacancy: { title: string } }> }> };

export type UserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type UserProfileQuery = { authProfile: { id: number, title?: string | null, name: string, firstName: string, lastName: string, email: string, permissions: Array<{ role: UserRole }> } };


export const LoginPGTADocument = gql`
    mutation LoginPGTA($email: String!, $password: String!) {
  loginPGTA(email: $email, password: $password) {
    accessToken
    user {
      id
      title
      name
      firstName
      lastName
      email
      permissions {
        role
      }
    }
  }
}
    `;
export type LoginPGTAMutationFn = Apollo.MutationFunction<LoginPGTAMutation, LoginPGTAMutationVariables>;

/**
 * __useLoginPGTAMutation__
 *
 * To run a mutation, you first call `useLoginPGTAMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginPGTAMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginPgtaMutation, { data, loading, error }] = useLoginPGTAMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginPGTAMutation(baseOptions?: Apollo.MutationHookOptions<LoginPGTAMutation, LoginPGTAMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginPGTAMutation, LoginPGTAMutationVariables>(LoginPGTADocument, options);
      }
export type LoginPGTAMutationHookResult = ReturnType<typeof useLoginPGTAMutation>;
export type LoginPGTAMutationResult = Apollo.MutationResult<LoginPGTAMutation>;
export type LoginPGTAMutationOptions = Apollo.BaseMutationOptions<LoginPGTAMutation, LoginPGTAMutationVariables>;
export const LogoutPGTADocument = gql`
    mutation LogoutPGTA {
  logout
}
    `;
export type LogoutPGTAMutationFn = Apollo.MutationFunction<LogoutPGTAMutation, LogoutPGTAMutationVariables>;

/**
 * __useLogoutPGTAMutation__
 *
 * To run a mutation, you first call `useLogoutPGTAMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutPGTAMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutPgtaMutation, { data, loading, error }] = useLogoutPGTAMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutPGTAMutation(baseOptions?: Apollo.MutationHookOptions<LogoutPGTAMutation, LogoutPGTAMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutPGTAMutation, LogoutPGTAMutationVariables>(LogoutPGTADocument, options);
      }
export type LogoutPGTAMutationHookResult = ReturnType<typeof useLogoutPGTAMutation>;
export type LogoutPGTAMutationResult = Apollo.MutationResult<LogoutPGTAMutation>;
export type LogoutPGTAMutationOptions = Apollo.BaseMutationOptions<LogoutPGTAMutation, LogoutPGTAMutationVariables>;
export const SendVerificationEmailDocument = gql`
    mutation SendVerificationEmail($email: String!) {
  sendVerificationEmail(email: $email)
}
    `;
export type SendVerificationEmailMutationFn = Apollo.MutationFunction<SendVerificationEmailMutation, SendVerificationEmailMutationVariables>;

/**
 * __useSendVerificationEmailMutation__
 *
 * To run a mutation, you first call `useSendVerificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendVerificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendVerificationEmailMutation, { data, loading, error }] = useSendVerificationEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendVerificationEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendVerificationEmailMutation, SendVerificationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendVerificationEmailMutation, SendVerificationEmailMutationVariables>(SendVerificationEmailDocument, options);
      }
export type SendVerificationEmailMutationHookResult = ReturnType<typeof useSendVerificationEmailMutation>;
export type SendVerificationEmailMutationResult = Apollo.MutationResult<SendVerificationEmailMutation>;
export type SendVerificationEmailMutationOptions = Apollo.BaseMutationOptions<SendVerificationEmailMutation, SendVerificationEmailMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($newPassword: String!, $token: String!) {
  resetPassword(newPassword: $newPassword, token: $token)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const FindAllInitialBudgetApplicationsForLecturerDocument = gql`
    query FindAllInitialBudgetApplicationsForLecturer {
  findAllInitialBudgetApplicationsForLecturer {
    module {
      code
    }
    totalHours
    applicationDate
    approvedHours
    approvedDate
    status
  }
}
    `;

/**
 * __useFindAllInitialBudgetApplicationsForLecturerQuery__
 *
 * To run a query within a React component, call `useFindAllInitialBudgetApplicationsForLecturerQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllInitialBudgetApplicationsForLecturerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllInitialBudgetApplicationsForLecturerQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindAllInitialBudgetApplicationsForLecturerQuery(baseOptions?: Apollo.QueryHookOptions<FindAllInitialBudgetApplicationsForLecturerQuery, FindAllInitialBudgetApplicationsForLecturerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllInitialBudgetApplicationsForLecturerQuery, FindAllInitialBudgetApplicationsForLecturerQueryVariables>(FindAllInitialBudgetApplicationsForLecturerDocument, options);
      }
export function useFindAllInitialBudgetApplicationsForLecturerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllInitialBudgetApplicationsForLecturerQuery, FindAllInitialBudgetApplicationsForLecturerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllInitialBudgetApplicationsForLecturerQuery, FindAllInitialBudgetApplicationsForLecturerQueryVariables>(FindAllInitialBudgetApplicationsForLecturerDocument, options);
        }
export type FindAllInitialBudgetApplicationsForLecturerQueryHookResult = ReturnType<typeof useFindAllInitialBudgetApplicationsForLecturerQuery>;
export type FindAllInitialBudgetApplicationsForLecturerLazyQueryHookResult = ReturnType<typeof useFindAllInitialBudgetApplicationsForLecturerLazyQuery>;
export type FindAllInitialBudgetApplicationsForLecturerQueryResult = Apollo.QueryResult<FindAllInitialBudgetApplicationsForLecturerQuery, FindAllInitialBudgetApplicationsForLecturerQueryVariables>;
export const FindAllContractsDocument = gql`
    query FindAllContracts($status: JobStatus!, $first: Int!, $after: Cursor!, $email: String) {
  findAllContracts(status: $status, first: $first, after: $after, email: $email) {
    data {
      id
      name
      email
      profile {
        jobRole
        studentType
        totalHours
      }
      jobs {
        module {
          code
        }
      }
    }
    count
  }
}
    `;

/**
 * __useFindAllContractsQuery__
 *
 * To run a query within a React component, call `useFindAllContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllContractsQuery({
 *   variables: {
 *      status: // value for 'status'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useFindAllContractsQuery(baseOptions: Apollo.QueryHookOptions<FindAllContractsQuery, FindAllContractsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllContractsQuery, FindAllContractsQueryVariables>(FindAllContractsDocument, options);
      }
export function useFindAllContractsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllContractsQuery, FindAllContractsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllContractsQuery, FindAllContractsQueryVariables>(FindAllContractsDocument, options);
        }
export type FindAllContractsQueryHookResult = ReturnType<typeof useFindAllContractsQuery>;
export type FindAllContractsLazyQueryHookResult = ReturnType<typeof useFindAllContractsLazyQuery>;
export type FindAllContractsQueryResult = Apollo.QueryResult<FindAllContractsQuery, FindAllContractsQueryVariables>;
export const FindContractDocument = gql`
    query FindContract($userId: Int!) {
  findContract(userId: $userId) {
    id
    name
    email
    profile {
      department
      university
      studentType
      passportUrl
      visaUrl
      bankUrl
      hrFormUrl
    }
    jobs {
      module {
        code
        title
        moduleLeader {
          name
        }
      }
      vacancy {
        title
        taRecruiter {
          name
        }
      }
      hours
      status
      approvedDate
      contractIssuedDate
    }
  }
}
    `;

/**
 * __useFindContractQuery__
 *
 * To run a query within a React component, call `useFindContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindContractQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useFindContractQuery(baseOptions: Apollo.QueryHookOptions<FindContractQuery, FindContractQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindContractQuery, FindContractQueryVariables>(FindContractDocument, options);
      }
export function useFindContractLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindContractQuery, FindContractQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindContractQuery, FindContractQueryVariables>(FindContractDocument, options);
        }
export type FindContractQueryHookResult = ReturnType<typeof useFindContractQuery>;
export type FindContractLazyQueryHookResult = ReturnType<typeof useFindContractLazyQuery>;
export type FindContractQueryResult = Apollo.QueryResult<FindContractQuery, FindContractQueryVariables>;
export const IssueContractDocument = gql`
    mutation IssueContract($userId: Int!) {
  issueContract(userId: $userId)
}
    `;
export type IssueContractMutationFn = Apollo.MutationFunction<IssueContractMutation, IssueContractMutationVariables>;

/**
 * __useIssueContractMutation__
 *
 * To run a mutation, you first call `useIssueContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueContractMutation, { data, loading, error }] = useIssueContractMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useIssueContractMutation(baseOptions?: Apollo.MutationHookOptions<IssueContractMutation, IssueContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IssueContractMutation, IssueContractMutationVariables>(IssueContractDocument, options);
      }
export type IssueContractMutationHookResult = ReturnType<typeof useIssueContractMutation>;
export type IssueContractMutationResult = Apollo.MutationResult<IssueContractMutation>;
export type IssueContractMutationOptions = Apollo.BaseMutationOptions<IssueContractMutation, IssueContractMutationVariables>;
export const MyModuleMenusDocument = gql`
    query MyModuleMenus {
  myModules {
    code
    title
    myRole
  }
}
    `;

/**
 * __useMyModuleMenusQuery__
 *
 * To run a query within a React component, call `useMyModuleMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyModuleMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyModuleMenusQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyModuleMenusQuery(baseOptions?: Apollo.QueryHookOptions<MyModuleMenusQuery, MyModuleMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyModuleMenusQuery, MyModuleMenusQueryVariables>(MyModuleMenusDocument, options);
      }
export function useMyModuleMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyModuleMenusQuery, MyModuleMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyModuleMenusQuery, MyModuleMenusQueryVariables>(MyModuleMenusDocument, options);
        }
export type MyModuleMenusQueryHookResult = ReturnType<typeof useMyModuleMenusQuery>;
export type MyModuleMenusLazyQueryHookResult = ReturnType<typeof useMyModuleMenusLazyQuery>;
export type MyModuleMenusQueryResult = Apollo.QueryResult<MyModuleMenusQuery, MyModuleMenusQueryVariables>;
export const MyModulesDocument = gql`
    query MyModules {
  myModules {
    title
    code
    initialBudget {
      approvedHours
      approvedDate
    }
    remainingHours
    confirmedTAs
    applicationAmount
    myRole
  }
}
    `;

/**
 * __useMyModulesQuery__
 *
 * To run a query within a React component, call `useMyModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyModulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyModulesQuery(baseOptions?: Apollo.QueryHookOptions<MyModulesQuery, MyModulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyModulesQuery, MyModulesQueryVariables>(MyModulesDocument, options);
      }
export function useMyModulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyModulesQuery, MyModulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyModulesQuery, MyModulesQueryVariables>(MyModulesDocument, options);
        }
export type MyModulesQueryHookResult = ReturnType<typeof useMyModulesQuery>;
export type MyModulesLazyQueryHookResult = ReturnType<typeof useMyModulesLazyQuery>;
export type MyModulesQueryResult = Apollo.QueryResult<MyModulesQuery, MyModulesQueryVariables>;
export const MyModuleProfileDocument = gql`
    query MyModuleProfile($moduleCode: String!) {
  findModuleByCode(moduleCode: $moduleCode) {
    id
    code
    title
    moduleLeader {
      name
      email
    }
    degree
    term
    description
    permission
  }
}
    `;

/**
 * __useMyModuleProfileQuery__
 *
 * To run a query within a React component, call `useMyModuleProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyModuleProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyModuleProfileQuery({
 *   variables: {
 *      moduleCode: // value for 'moduleCode'
 *   },
 * });
 */
export function useMyModuleProfileQuery(baseOptions: Apollo.QueryHookOptions<MyModuleProfileQuery, MyModuleProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyModuleProfileQuery, MyModuleProfileQueryVariables>(MyModuleProfileDocument, options);
      }
export function useMyModuleProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyModuleProfileQuery, MyModuleProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyModuleProfileQuery, MyModuleProfileQueryVariables>(MyModuleProfileDocument, options);
        }
export type MyModuleProfileQueryHookResult = ReturnType<typeof useMyModuleProfileQuery>;
export type MyModuleProfileLazyQueryHookResult = ReturnType<typeof useMyModuleProfileLazyQuery>;
export type MyModuleProfileQueryResult = Apollo.QueryResult<MyModuleProfileQuery, MyModuleProfileQueryVariables>;
export const ApprovedApplicationsDocument = gql`
    query ApprovedApplications($first: Int!, $after: Cursor!, $moduleCode: String) {
  findAllApplications(
    status: [Approved]
    first: $first
    after: $after
    moduleCode: $moduleCode
  ) {
    data {
      id
      appliedHours
      applicant {
        name
        profile {
          jobRole
          researchGroup
          studentType
        }
      }
      vacancy {
        title
      }
      module {
        code
      }
      decisionDate
      status
    }
    count
  }
}
    `;

/**
 * __useApprovedApplicationsQuery__
 *
 * To run a query within a React component, call `useApprovedApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApprovedApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApprovedApplicationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      moduleCode: // value for 'moduleCode'
 *   },
 * });
 */
export function useApprovedApplicationsQuery(baseOptions: Apollo.QueryHookOptions<ApprovedApplicationsQuery, ApprovedApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApprovedApplicationsQuery, ApprovedApplicationsQueryVariables>(ApprovedApplicationsDocument, options);
      }
export function useApprovedApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApprovedApplicationsQuery, ApprovedApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApprovedApplicationsQuery, ApprovedApplicationsQueryVariables>(ApprovedApplicationsDocument, options);
        }
export type ApprovedApplicationsQueryHookResult = ReturnType<typeof useApprovedApplicationsQuery>;
export type ApprovedApplicationsLazyQueryHookResult = ReturnType<typeof useApprovedApplicationsLazyQuery>;
export type ApprovedApplicationsQueryResult = Apollo.QueryResult<ApprovedApplicationsQuery, ApprovedApplicationsQueryVariables>;
export const PendingApplicationsDocument = gql`
    query PendingApplications($first: Int!, $after: Cursor!, $moduleCode: String) {
  findAllApplications(
    status: [Pending]
    moduleCode: $moduleCode
    first: $first
    after: $after
  ) {
    data {
      id
      appliedHours
      motivationLetter
      applicationDate
      applicant {
        name
        profile {
          jobRole
          researchGroup
          studentType
        }
      }
      module {
        code
      }
      vacancy {
        title
      }
    }
    count
    remainingBudgetHours
    totalBudgetHours
    spentBudgetHours
  }
}
    `;

/**
 * __usePendingApplicationsQuery__
 *
 * To run a query within a React component, call `usePendingApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingApplicationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      moduleCode: // value for 'moduleCode'
 *   },
 * });
 */
export function usePendingApplicationsQuery(baseOptions: Apollo.QueryHookOptions<PendingApplicationsQuery, PendingApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PendingApplicationsQuery, PendingApplicationsQueryVariables>(PendingApplicationsDocument, options);
      }
export function usePendingApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PendingApplicationsQuery, PendingApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PendingApplicationsQuery, PendingApplicationsQueryVariables>(PendingApplicationsDocument, options);
        }
export type PendingApplicationsQueryHookResult = ReturnType<typeof usePendingApplicationsQuery>;
export type PendingApplicationsLazyQueryHookResult = ReturnType<typeof usePendingApplicationsLazyQuery>;
export type PendingApplicationsQueryResult = Apollo.QueryResult<PendingApplicationsQuery, PendingApplicationsQueryVariables>;
export const FindApplicationDocument = gql`
    query FindApplication($applicationId: Int!) {
  findApplication(applicationId: $applicationId) {
    id
    applicant {
      name
      email
      profile {
        researchGroup
        department
        university
        jobRole
        year
        studentType
        allowWorkingHours
        needTraining
        workBefore
        cvUrl
      }
    }
    appliedHours
    motivationLetter
    vacancy {
      title
    }
  }
}
    `;

/**
 * __useFindApplicationQuery__
 *
 * To run a query within a React component, call `useFindApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindApplicationQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useFindApplicationQuery(baseOptions: Apollo.QueryHookOptions<FindApplicationQuery, FindApplicationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindApplicationQuery, FindApplicationQueryVariables>(FindApplicationDocument, options);
      }
export function useFindApplicationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindApplicationQuery, FindApplicationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindApplicationQuery, FindApplicationQueryVariables>(FindApplicationDocument, options);
        }
export type FindApplicationQueryHookResult = ReturnType<typeof useFindApplicationQuery>;
export type FindApplicationLazyQueryHookResult = ReturnType<typeof useFindApplicationLazyQuery>;
export type FindApplicationQueryResult = Apollo.QueryResult<FindApplicationQuery, FindApplicationQueryVariables>;
export const FindInitialBudgetApplicationForLecturerDocument = gql`
    query FindInitialBudgetApplicationForLecturer($moduleCode: String!) {
  findInitialBudgetByCode(moduleCode: $moduleCode) {
    status
    approvedHours
    term
    budgetOption
    totalHours
    jobDescription
    teaching
    support
    marking
    applicationDate
    approvedDate
  }
}
    `;

/**
 * __useFindInitialBudgetApplicationForLecturerQuery__
 *
 * To run a query within a React component, call `useFindInitialBudgetApplicationForLecturerQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindInitialBudgetApplicationForLecturerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindInitialBudgetApplicationForLecturerQuery({
 *   variables: {
 *      moduleCode: // value for 'moduleCode'
 *   },
 * });
 */
export function useFindInitialBudgetApplicationForLecturerQuery(baseOptions: Apollo.QueryHookOptions<FindInitialBudgetApplicationForLecturerQuery, FindInitialBudgetApplicationForLecturerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindInitialBudgetApplicationForLecturerQuery, FindInitialBudgetApplicationForLecturerQueryVariables>(FindInitialBudgetApplicationForLecturerDocument, options);
      }
export function useFindInitialBudgetApplicationForLecturerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindInitialBudgetApplicationForLecturerQuery, FindInitialBudgetApplicationForLecturerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindInitialBudgetApplicationForLecturerQuery, FindInitialBudgetApplicationForLecturerQueryVariables>(FindInitialBudgetApplicationForLecturerDocument, options);
        }
export type FindInitialBudgetApplicationForLecturerQueryHookResult = ReturnType<typeof useFindInitialBudgetApplicationForLecturerQuery>;
export type FindInitialBudgetApplicationForLecturerLazyQueryHookResult = ReturnType<typeof useFindInitialBudgetApplicationForLecturerLazyQuery>;
export type FindInitialBudgetApplicationForLecturerQueryResult = Apollo.QueryResult<FindInitialBudgetApplicationForLecturerQuery, FindInitialBudgetApplicationForLecturerQueryVariables>;
export const FindAllModuleVacanciesDocument = gql`
    query FindAllModuleVacancies($moduleCode: String!) {
  findAllModuleVacanciesByCode(moduleCode: $moduleCode) {
    id
    title
    offeredHours
    weeks
    term
    skills
    startDate
    endDate
    taRecruiter {
      email
    }
    createdDate
    isFull
  }
}
    `;

/**
 * __useFindAllModuleVacanciesQuery__
 *
 * To run a query within a React component, call `useFindAllModuleVacanciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllModuleVacanciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllModuleVacanciesQuery({
 *   variables: {
 *      moduleCode: // value for 'moduleCode'
 *   },
 * });
 */
export function useFindAllModuleVacanciesQuery(baseOptions: Apollo.QueryHookOptions<FindAllModuleVacanciesQuery, FindAllModuleVacanciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllModuleVacanciesQuery, FindAllModuleVacanciesQueryVariables>(FindAllModuleVacanciesDocument, options);
      }
export function useFindAllModuleVacanciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllModuleVacanciesQuery, FindAllModuleVacanciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllModuleVacanciesQuery, FindAllModuleVacanciesQueryVariables>(FindAllModuleVacanciesDocument, options);
        }
export type FindAllModuleVacanciesQueryHookResult = ReturnType<typeof useFindAllModuleVacanciesQuery>;
export type FindAllModuleVacanciesLazyQueryHookResult = ReturnType<typeof useFindAllModuleVacanciesLazyQuery>;
export type FindAllModuleVacanciesQueryResult = Apollo.QueryResult<FindAllModuleVacanciesQuery, FindAllModuleVacanciesQueryVariables>;
export const FindModuleVacancyDocument = gql`
    query FindModuleVacancy($vacancyId: Int!) {
  findVacancy(vacancyId: $vacancyId) {
    title
    description
    skills
    term
    weeks
    totalPositions
    startDate
    endDate
    offeredHours
    academicYear
    details
    taRecruiter {
      title
      email
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useFindModuleVacancyQuery__
 *
 * To run a query within a React component, call `useFindModuleVacancyQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindModuleVacancyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindModuleVacancyQuery({
 *   variables: {
 *      vacancyId: // value for 'vacancyId'
 *   },
 * });
 */
export function useFindModuleVacancyQuery(baseOptions: Apollo.QueryHookOptions<FindModuleVacancyQuery, FindModuleVacancyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindModuleVacancyQuery, FindModuleVacancyQueryVariables>(FindModuleVacancyDocument, options);
      }
export function useFindModuleVacancyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindModuleVacancyQuery, FindModuleVacancyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindModuleVacancyQuery, FindModuleVacancyQueryVariables>(FindModuleVacancyDocument, options);
        }
export type FindModuleVacancyQueryHookResult = ReturnType<typeof useFindModuleVacancyQuery>;
export type FindModuleVacancyLazyQueryHookResult = ReturnType<typeof useFindModuleVacancyLazyQuery>;
export type FindModuleVacancyQueryResult = Apollo.QueryResult<FindModuleVacancyQuery, FindModuleVacancyQueryVariables>;
export const FindAllModuleRecruitersDocument = gql`
    query FindAllModuleRecruiters($moduleCode: String!) {
  findAllModuleRecruiters(moduleCode: $moduleCode) {
    name
    email
    recruitVacancies {
      module {
        code
      }
      title
    }
  }
}
    `;

/**
 * __useFindAllModuleRecruitersQuery__
 *
 * To run a query within a React component, call `useFindAllModuleRecruitersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllModuleRecruitersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllModuleRecruitersQuery({
 *   variables: {
 *      moduleCode: // value for 'moduleCode'
 *   },
 * });
 */
export function useFindAllModuleRecruitersQuery(baseOptions: Apollo.QueryHookOptions<FindAllModuleRecruitersQuery, FindAllModuleRecruitersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllModuleRecruitersQuery, FindAllModuleRecruitersQueryVariables>(FindAllModuleRecruitersDocument, options);
      }
export function useFindAllModuleRecruitersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllModuleRecruitersQuery, FindAllModuleRecruitersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllModuleRecruitersQuery, FindAllModuleRecruitersQueryVariables>(FindAllModuleRecruitersDocument, options);
        }
export type FindAllModuleRecruitersQueryHookResult = ReturnType<typeof useFindAllModuleRecruitersQuery>;
export type FindAllModuleRecruitersLazyQueryHookResult = ReturnType<typeof useFindAllModuleRecruitersLazyQuery>;
export type FindAllModuleRecruitersQueryResult = Apollo.QueryResult<FindAllModuleRecruitersQuery, FindAllModuleRecruitersQueryVariables>;
export const FuzzySearchUserByEmailDocument = gql`
    query FuzzySearchUserByEmail($email: String!) {
  findUserWithEmail(email: $email) {
    name
    title
    firstName
    lastName
    email
  }
}
    `;

/**
 * __useFuzzySearchUserByEmailQuery__
 *
 * To run a query within a React component, call `useFuzzySearchUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useFuzzySearchUserByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFuzzySearchUserByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useFuzzySearchUserByEmailQuery(baseOptions: Apollo.QueryHookOptions<FuzzySearchUserByEmailQuery, FuzzySearchUserByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FuzzySearchUserByEmailQuery, FuzzySearchUserByEmailQueryVariables>(FuzzySearchUserByEmailDocument, options);
      }
export function useFuzzySearchUserByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FuzzySearchUserByEmailQuery, FuzzySearchUserByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FuzzySearchUserByEmailQuery, FuzzySearchUserByEmailQueryVariables>(FuzzySearchUserByEmailDocument, options);
        }
export type FuzzySearchUserByEmailQueryHookResult = ReturnType<typeof useFuzzySearchUserByEmailQuery>;
export type FuzzySearchUserByEmailLazyQueryHookResult = ReturnType<typeof useFuzzySearchUserByEmailLazyQuery>;
export type FuzzySearchUserByEmailQueryResult = Apollo.QueryResult<FuzzySearchUserByEmailQuery, FuzzySearchUserByEmailQueryVariables>;
export const FindModuleVacancyOptionsDocument = gql`
    query FindModuleVacancyOptions($moduleCode: String!) {
  findAllModuleVacanciesByCode(moduleCode: $moduleCode) {
    id
    title
  }
}
    `;

/**
 * __useFindModuleVacancyOptionsQuery__
 *
 * To run a query within a React component, call `useFindModuleVacancyOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindModuleVacancyOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindModuleVacancyOptionsQuery({
 *   variables: {
 *      moduleCode: // value for 'moduleCode'
 *   },
 * });
 */
export function useFindModuleVacancyOptionsQuery(baseOptions: Apollo.QueryHookOptions<FindModuleVacancyOptionsQuery, FindModuleVacancyOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindModuleVacancyOptionsQuery, FindModuleVacancyOptionsQueryVariables>(FindModuleVacancyOptionsDocument, options);
      }
export function useFindModuleVacancyOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindModuleVacancyOptionsQuery, FindModuleVacancyOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindModuleVacancyOptionsQuery, FindModuleVacancyOptionsQueryVariables>(FindModuleVacancyOptionsDocument, options);
        }
export type FindModuleVacancyOptionsQueryHookResult = ReturnType<typeof useFindModuleVacancyOptionsQuery>;
export type FindModuleVacancyOptionsLazyQueryHookResult = ReturnType<typeof useFindModuleVacancyOptionsLazyQuery>;
export type FindModuleVacancyOptionsQueryResult = Apollo.QueryResult<FindModuleVacancyOptionsQuery, FindModuleVacancyOptionsQueryVariables>;
export const FindAllExtraBudgetApplicationsForLecturerDocument = gql`
    query FindAllExtraBudgetApplicationsForLecturer($moduleCode: String) {
  findAllExtraBudgetApplicationsForLecturer(moduleCode: $moduleCode) {
    id
    module {
      code
    }
    totalHours
    applicationDate
    approvedHours
    decisionDate
    status
  }
}
    `;

/**
 * __useFindAllExtraBudgetApplicationsForLecturerQuery__
 *
 * To run a query within a React component, call `useFindAllExtraBudgetApplicationsForLecturerQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllExtraBudgetApplicationsForLecturerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllExtraBudgetApplicationsForLecturerQuery({
 *   variables: {
 *      moduleCode: // value for 'moduleCode'
 *   },
 * });
 */
export function useFindAllExtraBudgetApplicationsForLecturerQuery(baseOptions?: Apollo.QueryHookOptions<FindAllExtraBudgetApplicationsForLecturerQuery, FindAllExtraBudgetApplicationsForLecturerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllExtraBudgetApplicationsForLecturerQuery, FindAllExtraBudgetApplicationsForLecturerQueryVariables>(FindAllExtraBudgetApplicationsForLecturerDocument, options);
      }
export function useFindAllExtraBudgetApplicationsForLecturerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllExtraBudgetApplicationsForLecturerQuery, FindAllExtraBudgetApplicationsForLecturerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllExtraBudgetApplicationsForLecturerQuery, FindAllExtraBudgetApplicationsForLecturerQueryVariables>(FindAllExtraBudgetApplicationsForLecturerDocument, options);
        }
export type FindAllExtraBudgetApplicationsForLecturerQueryHookResult = ReturnType<typeof useFindAllExtraBudgetApplicationsForLecturerQuery>;
export type FindAllExtraBudgetApplicationsForLecturerLazyQueryHookResult = ReturnType<typeof useFindAllExtraBudgetApplicationsForLecturerLazyQuery>;
export type FindAllExtraBudgetApplicationsForLecturerQueryResult = Apollo.QueryResult<FindAllExtraBudgetApplicationsForLecturerQuery, FindAllExtraBudgetApplicationsForLecturerQueryVariables>;
export const FindExtraBudgetApplicationDocument = gql`
    query FindExtraBudgetApplication($applicationId: Int!) {
  findExtraBudgetApplication(applicationId: $applicationId) {
    term
    teaching
    support
    marking
    totalHours
    jobDescription
  }
}
    `;

/**
 * __useFindExtraBudgetApplicationQuery__
 *
 * To run a query within a React component, call `useFindExtraBudgetApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindExtraBudgetApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindExtraBudgetApplicationQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useFindExtraBudgetApplicationQuery(baseOptions: Apollo.QueryHookOptions<FindExtraBudgetApplicationQuery, FindExtraBudgetApplicationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindExtraBudgetApplicationQuery, FindExtraBudgetApplicationQueryVariables>(FindExtraBudgetApplicationDocument, options);
      }
export function useFindExtraBudgetApplicationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindExtraBudgetApplicationQuery, FindExtraBudgetApplicationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindExtraBudgetApplicationQuery, FindExtraBudgetApplicationQueryVariables>(FindExtraBudgetApplicationDocument, options);
        }
export type FindExtraBudgetApplicationQueryHookResult = ReturnType<typeof useFindExtraBudgetApplicationQuery>;
export type FindExtraBudgetApplicationLazyQueryHookResult = ReturnType<typeof useFindExtraBudgetApplicationLazyQuery>;
export type FindExtraBudgetApplicationQueryResult = Apollo.QueryResult<FindExtraBudgetApplicationQuery, FindExtraBudgetApplicationQueryVariables>;
export const AddRecruiterToModuleDocument = gql`
    mutation AddRecruiterToModule($data: AddRecruiterInput!) {
  addRecruiterToModule(data: $data)
}
    `;
export type AddRecruiterToModuleMutationFn = Apollo.MutationFunction<AddRecruiterToModuleMutation, AddRecruiterToModuleMutationVariables>;

/**
 * __useAddRecruiterToModuleMutation__
 *
 * To run a mutation, you first call `useAddRecruiterToModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRecruiterToModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRecruiterToModuleMutation, { data, loading, error }] = useAddRecruiterToModuleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddRecruiterToModuleMutation(baseOptions?: Apollo.MutationHookOptions<AddRecruiterToModuleMutation, AddRecruiterToModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRecruiterToModuleMutation, AddRecruiterToModuleMutationVariables>(AddRecruiterToModuleDocument, options);
      }
export type AddRecruiterToModuleMutationHookResult = ReturnType<typeof useAddRecruiterToModuleMutation>;
export type AddRecruiterToModuleMutationResult = Apollo.MutationResult<AddRecruiterToModuleMutation>;
export type AddRecruiterToModuleMutationOptions = Apollo.BaseMutationOptions<AddRecruiterToModuleMutation, AddRecruiterToModuleMutationVariables>;
export const RemoveRecruiterFromModuleDocument = gql`
    mutation RemoveRecruiterFromModule($moduleCode: String!, $email: String!) {
  removeRecruiterFromModule(moduleCode: $moduleCode, email: $email)
}
    `;
export type RemoveRecruiterFromModuleMutationFn = Apollo.MutationFunction<RemoveRecruiterFromModuleMutation, RemoveRecruiterFromModuleMutationVariables>;

/**
 * __useRemoveRecruiterFromModuleMutation__
 *
 * To run a mutation, you first call `useRemoveRecruiterFromModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRecruiterFromModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRecruiterFromModuleMutation, { data, loading, error }] = useRemoveRecruiterFromModuleMutation({
 *   variables: {
 *      moduleCode: // value for 'moduleCode'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRemoveRecruiterFromModuleMutation(baseOptions?: Apollo.MutationHookOptions<RemoveRecruiterFromModuleMutation, RemoveRecruiterFromModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveRecruiterFromModuleMutation, RemoveRecruiterFromModuleMutationVariables>(RemoveRecruiterFromModuleDocument, options);
      }
export type RemoveRecruiterFromModuleMutationHookResult = ReturnType<typeof useRemoveRecruiterFromModuleMutation>;
export type RemoveRecruiterFromModuleMutationResult = Apollo.MutationResult<RemoveRecruiterFromModuleMutation>;
export type RemoveRecruiterFromModuleMutationOptions = Apollo.BaseMutationOptions<RemoveRecruiterFromModuleMutation, RemoveRecruiterFromModuleMutationVariables>;
export const UpdateModuleDocument = gql`
    mutation UpdateModule($data: CourseUpdateInput!) {
  updateModule(data: $data)
}
    `;
export type UpdateModuleMutationFn = Apollo.MutationFunction<UpdateModuleMutation, UpdateModuleMutationVariables>;

/**
 * __useUpdateModuleMutation__
 *
 * To run a mutation, you first call `useUpdateModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModuleMutation, { data, loading, error }] = useUpdateModuleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateModuleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateModuleMutation, UpdateModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateModuleMutation, UpdateModuleMutationVariables>(UpdateModuleDocument, options);
      }
export type UpdateModuleMutationHookResult = ReturnType<typeof useUpdateModuleMutation>;
export type UpdateModuleMutationResult = Apollo.MutationResult<UpdateModuleMutation>;
export type UpdateModuleMutationOptions = Apollo.BaseMutationOptions<UpdateModuleMutation, UpdateModuleMutationVariables>;
export const ApproveApplicationDocument = gql`
    mutation ApproveApplication($applicationId: Int!) {
  approveApplication(applicationId: $applicationId)
}
    `;
export type ApproveApplicationMutationFn = Apollo.MutationFunction<ApproveApplicationMutation, ApproveApplicationMutationVariables>;

/**
 * __useApproveApplicationMutation__
 *
 * To run a mutation, you first call `useApproveApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveApplicationMutation, { data, loading, error }] = useApproveApplicationMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useApproveApplicationMutation(baseOptions?: Apollo.MutationHookOptions<ApproveApplicationMutation, ApproveApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveApplicationMutation, ApproveApplicationMutationVariables>(ApproveApplicationDocument, options);
      }
export type ApproveApplicationMutationHookResult = ReturnType<typeof useApproveApplicationMutation>;
export type ApproveApplicationMutationResult = Apollo.MutationResult<ApproveApplicationMutation>;
export type ApproveApplicationMutationOptions = Apollo.BaseMutationOptions<ApproveApplicationMutation, ApproveApplicationMutationVariables>;
export const RejectApplicationDocument = gql`
    mutation RejectApplication($applicationId: Int!, $reason: String!) {
  rejectApplication(applicationId: $applicationId, reason: $reason)
}
    `;
export type RejectApplicationMutationFn = Apollo.MutationFunction<RejectApplicationMutation, RejectApplicationMutationVariables>;

/**
 * __useRejectApplicationMutation__
 *
 * To run a mutation, you first call `useRejectApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectApplicationMutation, { data, loading, error }] = useRejectApplicationMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useRejectApplicationMutation(baseOptions?: Apollo.MutationHookOptions<RejectApplicationMutation, RejectApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectApplicationMutation, RejectApplicationMutationVariables>(RejectApplicationDocument, options);
      }
export type RejectApplicationMutationHookResult = ReturnType<typeof useRejectApplicationMutation>;
export type RejectApplicationMutationResult = Apollo.MutationResult<RejectApplicationMutation>;
export type RejectApplicationMutationOptions = Apollo.BaseMutationOptions<RejectApplicationMutation, RejectApplicationMutationVariables>;
export const SubmitInitialBudgetApplicationDocument = gql`
    mutation SubmitInitialBudgetApplication($form: InitialBudgetApplicationForm!) {
  submitInitialBudget(applicationForm: $form)
}
    `;
export type SubmitInitialBudgetApplicationMutationFn = Apollo.MutationFunction<SubmitInitialBudgetApplicationMutation, SubmitInitialBudgetApplicationMutationVariables>;

/**
 * __useSubmitInitialBudgetApplicationMutation__
 *
 * To run a mutation, you first call `useSubmitInitialBudgetApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitInitialBudgetApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitInitialBudgetApplicationMutation, { data, loading, error }] = useSubmitInitialBudgetApplicationMutation({
 *   variables: {
 *      form: // value for 'form'
 *   },
 * });
 */
export function useSubmitInitialBudgetApplicationMutation(baseOptions?: Apollo.MutationHookOptions<SubmitInitialBudgetApplicationMutation, SubmitInitialBudgetApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitInitialBudgetApplicationMutation, SubmitInitialBudgetApplicationMutationVariables>(SubmitInitialBudgetApplicationDocument, options);
      }
export type SubmitInitialBudgetApplicationMutationHookResult = ReturnType<typeof useSubmitInitialBudgetApplicationMutation>;
export type SubmitInitialBudgetApplicationMutationResult = Apollo.MutationResult<SubmitInitialBudgetApplicationMutation>;
export type SubmitInitialBudgetApplicationMutationOptions = Apollo.BaseMutationOptions<SubmitInitialBudgetApplicationMutation, SubmitInitialBudgetApplicationMutationVariables>;
export const DeleteVacancyDocument = gql`
    mutation DeleteVacancy($vacancyId: Int!) {
  deleteVacancy(vacancyId: $vacancyId)
}
    `;
export type DeleteVacancyMutationFn = Apollo.MutationFunction<DeleteVacancyMutation, DeleteVacancyMutationVariables>;

/**
 * __useDeleteVacancyMutation__
 *
 * To run a mutation, you first call `useDeleteVacancyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVacancyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVacancyMutation, { data, loading, error }] = useDeleteVacancyMutation({
 *   variables: {
 *      vacancyId: // value for 'vacancyId'
 *   },
 * });
 */
export function useDeleteVacancyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVacancyMutation, DeleteVacancyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVacancyMutation, DeleteVacancyMutationVariables>(DeleteVacancyDocument, options);
      }
export type DeleteVacancyMutationHookResult = ReturnType<typeof useDeleteVacancyMutation>;
export type DeleteVacancyMutationResult = Apollo.MutationResult<DeleteVacancyMutation>;
export type DeleteVacancyMutationOptions = Apollo.BaseMutationOptions<DeleteVacancyMutation, DeleteVacancyMutationVariables>;
export const MarkVacancyFullDocument = gql`
    mutation MarkVacancyFull($vacancyId: Int!) {
  markVacancyFull(vacancyId: $vacancyId)
}
    `;
export type MarkVacancyFullMutationFn = Apollo.MutationFunction<MarkVacancyFullMutation, MarkVacancyFullMutationVariables>;

/**
 * __useMarkVacancyFullMutation__
 *
 * To run a mutation, you first call `useMarkVacancyFullMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkVacancyFullMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markVacancyFullMutation, { data, loading, error }] = useMarkVacancyFullMutation({
 *   variables: {
 *      vacancyId: // value for 'vacancyId'
 *   },
 * });
 */
export function useMarkVacancyFullMutation(baseOptions?: Apollo.MutationHookOptions<MarkVacancyFullMutation, MarkVacancyFullMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkVacancyFullMutation, MarkVacancyFullMutationVariables>(MarkVacancyFullDocument, options);
      }
export type MarkVacancyFullMutationHookResult = ReturnType<typeof useMarkVacancyFullMutation>;
export type MarkVacancyFullMutationResult = Apollo.MutationResult<MarkVacancyFullMutation>;
export type MarkVacancyFullMutationOptions = Apollo.BaseMutationOptions<MarkVacancyFullMutation, MarkVacancyFullMutationVariables>;
export const ReopenVacancyDocument = gql`
    mutation ReopenVacancy($vacancyId: Int!) {
  reopenVacancy(vacancyId: $vacancyId)
}
    `;
export type ReopenVacancyMutationFn = Apollo.MutationFunction<ReopenVacancyMutation, ReopenVacancyMutationVariables>;

/**
 * __useReopenVacancyMutation__
 *
 * To run a mutation, you first call `useReopenVacancyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReopenVacancyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reopenVacancyMutation, { data, loading, error }] = useReopenVacancyMutation({
 *   variables: {
 *      vacancyId: // value for 'vacancyId'
 *   },
 * });
 */
export function useReopenVacancyMutation(baseOptions?: Apollo.MutationHookOptions<ReopenVacancyMutation, ReopenVacancyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReopenVacancyMutation, ReopenVacancyMutationVariables>(ReopenVacancyDocument, options);
      }
export type ReopenVacancyMutationHookResult = ReturnType<typeof useReopenVacancyMutation>;
export type ReopenVacancyMutationResult = Apollo.MutationResult<ReopenVacancyMutation>;
export type ReopenVacancyMutationOptions = Apollo.BaseMutationOptions<ReopenVacancyMutation, ReopenVacancyMutationVariables>;
export const CreateVacancyDocument = gql`
    mutation CreateVacancy($form: CreateVacancyFormInput!) {
  createVacancy(form: $form)
}
    `;
export type CreateVacancyMutationFn = Apollo.MutationFunction<CreateVacancyMutation, CreateVacancyMutationVariables>;

/**
 * __useCreateVacancyMutation__
 *
 * To run a mutation, you first call `useCreateVacancyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVacancyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVacancyMutation, { data, loading, error }] = useCreateVacancyMutation({
 *   variables: {
 *      form: // value for 'form'
 *   },
 * });
 */
export function useCreateVacancyMutation(baseOptions?: Apollo.MutationHookOptions<CreateVacancyMutation, CreateVacancyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVacancyMutation, CreateVacancyMutationVariables>(CreateVacancyDocument, options);
      }
export type CreateVacancyMutationHookResult = ReturnType<typeof useCreateVacancyMutation>;
export type CreateVacancyMutationResult = Apollo.MutationResult<CreateVacancyMutation>;
export type CreateVacancyMutationOptions = Apollo.BaseMutationOptions<CreateVacancyMutation, CreateVacancyMutationVariables>;
export const UpdateVacancyDocument = gql`
    mutation UpdateVacancy($form: UpdateVacancyFormInput!) {
  updateVacancy(form: $form)
}
    `;
export type UpdateVacancyMutationFn = Apollo.MutationFunction<UpdateVacancyMutation, UpdateVacancyMutationVariables>;

/**
 * __useUpdateVacancyMutation__
 *
 * To run a mutation, you first call `useUpdateVacancyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVacancyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVacancyMutation, { data, loading, error }] = useUpdateVacancyMutation({
 *   variables: {
 *      form: // value for 'form'
 *   },
 * });
 */
export function useUpdateVacancyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVacancyMutation, UpdateVacancyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVacancyMutation, UpdateVacancyMutationVariables>(UpdateVacancyDocument, options);
      }
export type UpdateVacancyMutationHookResult = ReturnType<typeof useUpdateVacancyMutation>;
export type UpdateVacancyMutationResult = Apollo.MutationResult<UpdateVacancyMutation>;
export type UpdateVacancyMutationOptions = Apollo.BaseMutationOptions<UpdateVacancyMutation, UpdateVacancyMutationVariables>;
export const SaveExtraBudgetApplicationDocument = gql`
    mutation SaveExtraBudgetApplication($form: ExtraBudgetApplicationForm!) {
  saveExtraBudget(applicationForm: $form)
}
    `;
export type SaveExtraBudgetApplicationMutationFn = Apollo.MutationFunction<SaveExtraBudgetApplicationMutation, SaveExtraBudgetApplicationMutationVariables>;

/**
 * __useSaveExtraBudgetApplicationMutation__
 *
 * To run a mutation, you first call `useSaveExtraBudgetApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveExtraBudgetApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveExtraBudgetApplicationMutation, { data, loading, error }] = useSaveExtraBudgetApplicationMutation({
 *   variables: {
 *      form: // value for 'form'
 *   },
 * });
 */
export function useSaveExtraBudgetApplicationMutation(baseOptions?: Apollo.MutationHookOptions<SaveExtraBudgetApplicationMutation, SaveExtraBudgetApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveExtraBudgetApplicationMutation, SaveExtraBudgetApplicationMutationVariables>(SaveExtraBudgetApplicationDocument, options);
      }
export type SaveExtraBudgetApplicationMutationHookResult = ReturnType<typeof useSaveExtraBudgetApplicationMutation>;
export type SaveExtraBudgetApplicationMutationResult = Apollo.MutationResult<SaveExtraBudgetApplicationMutation>;
export type SaveExtraBudgetApplicationMutationOptions = Apollo.BaseMutationOptions<SaveExtraBudgetApplicationMutation, SaveExtraBudgetApplicationMutationVariables>;
export const UpdateExtraBudgetApplicationDocument = gql`
    mutation UpdateExtraBudgetApplication($form: UpdateExtraBudgetApplicationForm!) {
  updateExtraBudget(applicationForm: $form)
}
    `;
export type UpdateExtraBudgetApplicationMutationFn = Apollo.MutationFunction<UpdateExtraBudgetApplicationMutation, UpdateExtraBudgetApplicationMutationVariables>;

/**
 * __useUpdateExtraBudgetApplicationMutation__
 *
 * To run a mutation, you first call `useUpdateExtraBudgetApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExtraBudgetApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExtraBudgetApplicationMutation, { data, loading, error }] = useUpdateExtraBudgetApplicationMutation({
 *   variables: {
 *      form: // value for 'form'
 *   },
 * });
 */
export function useUpdateExtraBudgetApplicationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateExtraBudgetApplicationMutation, UpdateExtraBudgetApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateExtraBudgetApplicationMutation, UpdateExtraBudgetApplicationMutationVariables>(UpdateExtraBudgetApplicationDocument, options);
      }
export type UpdateExtraBudgetApplicationMutationHookResult = ReturnType<typeof useUpdateExtraBudgetApplicationMutation>;
export type UpdateExtraBudgetApplicationMutationResult = Apollo.MutationResult<UpdateExtraBudgetApplicationMutation>;
export type UpdateExtraBudgetApplicationMutationOptions = Apollo.BaseMutationOptions<UpdateExtraBudgetApplicationMutation, UpdateExtraBudgetApplicationMutationVariables>;
export const DeleteExtraBudgetApplicationDocument = gql`
    mutation DeleteExtraBudgetApplication($applicationId: Int!) {
  deleteExtraBudget(applicationId: $applicationId)
}
    `;
export type DeleteExtraBudgetApplicationMutationFn = Apollo.MutationFunction<DeleteExtraBudgetApplicationMutation, DeleteExtraBudgetApplicationMutationVariables>;

/**
 * __useDeleteExtraBudgetApplicationMutation__
 *
 * To run a mutation, you first call `useDeleteExtraBudgetApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExtraBudgetApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExtraBudgetApplicationMutation, { data, loading, error }] = useDeleteExtraBudgetApplicationMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useDeleteExtraBudgetApplicationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteExtraBudgetApplicationMutation, DeleteExtraBudgetApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteExtraBudgetApplicationMutation, DeleteExtraBudgetApplicationMutationVariables>(DeleteExtraBudgetApplicationDocument, options);
      }
export type DeleteExtraBudgetApplicationMutationHookResult = ReturnType<typeof useDeleteExtraBudgetApplicationMutation>;
export type DeleteExtraBudgetApplicationMutationResult = Apollo.MutationResult<DeleteExtraBudgetApplicationMutation>;
export type DeleteExtraBudgetApplicationMutationOptions = Apollo.BaseMutationOptions<DeleteExtraBudgetApplicationMutation, DeleteExtraBudgetApplicationMutationVariables>;
export const FindAllModuleOptionsDocument = gql`
    query FindAllModuleOptions {
  findAllModules {
    id
    code
    title
    vacancies {
      id
      title
      isFull
    }
  }
}
    `;

/**
 * __useFindAllModuleOptionsQuery__
 *
 * To run a query within a React component, call `useFindAllModuleOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllModuleOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllModuleOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindAllModuleOptionsQuery(baseOptions?: Apollo.QueryHookOptions<FindAllModuleOptionsQuery, FindAllModuleOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllModuleOptionsQuery, FindAllModuleOptionsQueryVariables>(FindAllModuleOptionsDocument, options);
      }
export function useFindAllModuleOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllModuleOptionsQuery, FindAllModuleOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllModuleOptionsQuery, FindAllModuleOptionsQueryVariables>(FindAllModuleOptionsDocument, options);
        }
export type FindAllModuleOptionsQueryHookResult = ReturnType<typeof useFindAllModuleOptionsQuery>;
export type FindAllModuleOptionsLazyQueryHookResult = ReturnType<typeof useFindAllModuleOptionsLazyQuery>;
export type FindAllModuleOptionsQueryResult = Apollo.QueryResult<FindAllModuleOptionsQuery, FindAllModuleOptionsQueryVariables>;
export const FindAllVacanciesDocument = gql`
    query FindAllVacancies($first: Int, $after: Cursor, $degree: [Degree!], $term: [Term!], $vacancyState: VacancyState, $search: String, $searchBy: SearchByOption) {
  findAllVacancies(
    first: $first
    after: $after
    degree: $degree
    term: $term
    vacancyState: $vacancyState
    search: $search
    searchBy: $searchBy
  ) {
    edges {
      cursor
      node {
        id
        title
        weeks
        term
        skills
        offeredHours
        totalPositions
        isFull
        taRecruiter {
          email
        }
        module {
          title
          code
        }
        isTAFavorite
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    `;

/**
 * __useFindAllVacanciesQuery__
 *
 * To run a query within a React component, call `useFindAllVacanciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllVacanciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllVacanciesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      degree: // value for 'degree'
 *      term: // value for 'term'
 *      vacancyState: // value for 'vacancyState'
 *      search: // value for 'search'
 *      searchBy: // value for 'searchBy'
 *   },
 * });
 */
export function useFindAllVacanciesQuery(baseOptions?: Apollo.QueryHookOptions<FindAllVacanciesQuery, FindAllVacanciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllVacanciesQuery, FindAllVacanciesQueryVariables>(FindAllVacanciesDocument, options);
      }
export function useFindAllVacanciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllVacanciesQuery, FindAllVacanciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllVacanciesQuery, FindAllVacanciesQueryVariables>(FindAllVacanciesDocument, options);
        }
export type FindAllVacanciesQueryHookResult = ReturnType<typeof useFindAllVacanciesQuery>;
export type FindAllVacanciesLazyQueryHookResult = ReturnType<typeof useFindAllVacanciesLazyQuery>;
export type FindAllVacanciesQueryResult = Apollo.QueryResult<FindAllVacanciesQuery, FindAllVacanciesQueryVariables>;
export const FindAllFavoritesDocument = gql`
    query FindAllFavorites {
  findAllFavorites {
    applied
    createdDate
    vacancy {
      id
      title
      skills
      offeredHours
      weeks
      term
      taRecruiter {
        email
      }
      module {
        title
        code
        moduleLeader {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useFindAllFavoritesQuery__
 *
 * To run a query within a React component, call `useFindAllFavoritesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllFavoritesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllFavoritesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindAllFavoritesQuery(baseOptions?: Apollo.QueryHookOptions<FindAllFavoritesQuery, FindAllFavoritesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllFavoritesQuery, FindAllFavoritesQueryVariables>(FindAllFavoritesDocument, options);
      }
export function useFindAllFavoritesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllFavoritesQuery, FindAllFavoritesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllFavoritesQuery, FindAllFavoritesQueryVariables>(FindAllFavoritesDocument, options);
        }
export type FindAllFavoritesQueryHookResult = ReturnType<typeof useFindAllFavoritesQuery>;
export type FindAllFavoritesLazyQueryHookResult = ReturnType<typeof useFindAllFavoritesLazyQuery>;
export type FindAllFavoritesQueryResult = Apollo.QueryResult<FindAllFavoritesQuery, FindAllFavoritesQueryVariables>;
export const AddFavoriteDocument = gql`
    mutation AddFavorite($vacancyId: Int!) {
  addFavorite(vacancyId: $vacancyId)
}
    `;
export type AddFavoriteMutationFn = Apollo.MutationFunction<AddFavoriteMutation, AddFavoriteMutationVariables>;

/**
 * __useAddFavoriteMutation__
 *
 * To run a mutation, you first call `useAddFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFavoriteMutation, { data, loading, error }] = useAddFavoriteMutation({
 *   variables: {
 *      vacancyId: // value for 'vacancyId'
 *   },
 * });
 */
export function useAddFavoriteMutation(baseOptions?: Apollo.MutationHookOptions<AddFavoriteMutation, AddFavoriteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFavoriteMutation, AddFavoriteMutationVariables>(AddFavoriteDocument, options);
      }
export type AddFavoriteMutationHookResult = ReturnType<typeof useAddFavoriteMutation>;
export type AddFavoriteMutationResult = Apollo.MutationResult<AddFavoriteMutation>;
export type AddFavoriteMutationOptions = Apollo.BaseMutationOptions<AddFavoriteMutation, AddFavoriteMutationVariables>;
export const RemoveShortlistedVacancyDocument = gql`
    mutation RemoveShortlistedVacancy($vacancyId: Int!) {
  removeFavorite(vacancyId: $vacancyId)
}
    `;
export type RemoveShortlistedVacancyMutationFn = Apollo.MutationFunction<RemoveShortlistedVacancyMutation, RemoveShortlistedVacancyMutationVariables>;

/**
 * __useRemoveShortlistedVacancyMutation__
 *
 * To run a mutation, you first call `useRemoveShortlistedVacancyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveShortlistedVacancyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeShortlistedVacancyMutation, { data, loading, error }] = useRemoveShortlistedVacancyMutation({
 *   variables: {
 *      vacancyId: // value for 'vacancyId'
 *   },
 * });
 */
export function useRemoveShortlistedVacancyMutation(baseOptions?: Apollo.MutationHookOptions<RemoveShortlistedVacancyMutation, RemoveShortlistedVacancyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveShortlistedVacancyMutation, RemoveShortlistedVacancyMutationVariables>(RemoveShortlistedVacancyDocument, options);
      }
export type RemoveShortlistedVacancyMutationHookResult = ReturnType<typeof useRemoveShortlistedVacancyMutation>;
export type RemoveShortlistedVacancyMutationResult = Apollo.MutationResult<RemoveShortlistedVacancyMutation>;
export type RemoveShortlistedVacancyMutationOptions = Apollo.BaseMutationOptions<RemoveShortlistedVacancyMutation, RemoveShortlistedVacancyMutationVariables>;
export const TADashboardDocument = gql`
    query TADashboard {
  taDashboard {
    totalVacancies
    totalWorkingHours
    remainingWorkingHours
    jobs {
      module {
        title
      }
      hours
    }
    applications {
      applicationDate
      status
      appliedHours
      module {
        title
        moduleLeader {
          name
        }
      }
    }
    vacancies(first: 5) {
      term
      offeredHours
      module {
        title
        code
        moduleLeader {
          name
        }
      }
    }
    favorites {
      vacancy {
        module {
          title
        }
        offeredHours
      }
    }
  }
}
    `;

/**
 * __useTADashboardQuery__
 *
 * To run a query within a React component, call `useTADashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useTADashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTADashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useTADashboardQuery(baseOptions?: Apollo.QueryHookOptions<TADashboardQuery, TADashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TADashboardQuery, TADashboardQueryVariables>(TADashboardDocument, options);
      }
export function useTADashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TADashboardQuery, TADashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TADashboardQuery, TADashboardQueryVariables>(TADashboardDocument, options);
        }
export type TADashboardQueryHookResult = ReturnType<typeof useTADashboardQuery>;
export type TADashboardLazyQueryHookResult = ReturnType<typeof useTADashboardLazyQuery>;
export type TADashboardQueryResult = Apollo.QueryResult<TADashboardQuery, TADashboardQueryVariables>;
export const FindAllTemplatesDocument = gql`
    query FindAllTemplates {
  findAllTemplates {
    type
    template
  }
}
    `;

/**
 * __useFindAllTemplatesQuery__
 *
 * To run a query within a React component, call `useFindAllTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindAllTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<FindAllTemplatesQuery, FindAllTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllTemplatesQuery, FindAllTemplatesQueryVariables>(FindAllTemplatesDocument, options);
      }
export function useFindAllTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllTemplatesQuery, FindAllTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllTemplatesQuery, FindAllTemplatesQueryVariables>(FindAllTemplatesDocument, options);
        }
export type FindAllTemplatesQueryHookResult = ReturnType<typeof useFindAllTemplatesQuery>;
export type FindAllTemplatesLazyQueryHookResult = ReturnType<typeof useFindAllTemplatesLazyQuery>;
export type FindAllTemplatesQueryResult = Apollo.QueryResult<FindAllTemplatesQuery, FindAllTemplatesQueryVariables>;
export const EditTemplateDocument = gql`
    mutation EditTemplate($type: EmailType!, $template: String!) {
  editTemplate(type: $type, template: $template)
}
    `;
export type EditTemplateMutationFn = Apollo.MutationFunction<EditTemplateMutation, EditTemplateMutationVariables>;

/**
 * __useEditTemplateMutation__
 *
 * To run a mutation, you first call `useEditTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTemplateMutation, { data, loading, error }] = useEditTemplateMutation({
 *   variables: {
 *      type: // value for 'type'
 *      template: // value for 'template'
 *   },
 * });
 */
export function useEditTemplateMutation(baseOptions?: Apollo.MutationHookOptions<EditTemplateMutation, EditTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTemplateMutation, EditTemplateMutationVariables>(EditTemplateDocument, options);
      }
export type EditTemplateMutationHookResult = ReturnType<typeof useEditTemplateMutation>;
export type EditTemplateMutationResult = Apollo.MutationResult<EditTemplateMutation>;
export type EditTemplateMutationOptions = Apollo.BaseMutationOptions<EditTemplateMutation, EditTemplateMutationVariables>;
export const FindAllExternalApplicantsDocument = gql`
    query FindAllExternalApplicants {
  findAllExternalApplicantRegistry {
    user {
      name
      email
      profile {
        university
      }
    }
    registrationDate
  }
}
    `;

/**
 * __useFindAllExternalApplicantsQuery__
 *
 * To run a query within a React component, call `useFindAllExternalApplicantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllExternalApplicantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllExternalApplicantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindAllExternalApplicantsQuery(baseOptions?: Apollo.QueryHookOptions<FindAllExternalApplicantsQuery, FindAllExternalApplicantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllExternalApplicantsQuery, FindAllExternalApplicantsQueryVariables>(FindAllExternalApplicantsDocument, options);
      }
export function useFindAllExternalApplicantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllExternalApplicantsQuery, FindAllExternalApplicantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllExternalApplicantsQuery, FindAllExternalApplicantsQueryVariables>(FindAllExternalApplicantsDocument, options);
        }
export type FindAllExternalApplicantsQueryHookResult = ReturnType<typeof useFindAllExternalApplicantsQuery>;
export type FindAllExternalApplicantsLazyQueryHookResult = ReturnType<typeof useFindAllExternalApplicantsLazyQuery>;
export type FindAllExternalApplicantsQueryResult = Apollo.QueryResult<FindAllExternalApplicantsQuery, FindAllExternalApplicantsQueryVariables>;
export const RegisterExternalApplicantDocument = gql`
    mutation RegisterExternalApplicant($form: ExternalTARegistrationForm!) {
  registerExternalTA(form: $form)
}
    `;
export type RegisterExternalApplicantMutationFn = Apollo.MutationFunction<RegisterExternalApplicantMutation, RegisterExternalApplicantMutationVariables>;

/**
 * __useRegisterExternalApplicantMutation__
 *
 * To run a mutation, you first call `useRegisterExternalApplicantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterExternalApplicantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerExternalApplicantMutation, { data, loading, error }] = useRegisterExternalApplicantMutation({
 *   variables: {
 *      form: // value for 'form'
 *   },
 * });
 */
export function useRegisterExternalApplicantMutation(baseOptions?: Apollo.MutationHookOptions<RegisterExternalApplicantMutation, RegisterExternalApplicantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterExternalApplicantMutation, RegisterExternalApplicantMutationVariables>(RegisterExternalApplicantDocument, options);
      }
export type RegisterExternalApplicantMutationHookResult = ReturnType<typeof useRegisterExternalApplicantMutation>;
export type RegisterExternalApplicantMutationResult = Apollo.MutationResult<RegisterExternalApplicantMutation>;
export type RegisterExternalApplicantMutationOptions = Apollo.BaseMutationOptions<RegisterExternalApplicantMutation, RegisterExternalApplicantMutationVariables>;
export const FindAllFaqsDocument = gql`
    query FindAllFaqs {
  findAllFaqs {
    id
    question
    answer
  }
}
    `;

/**
 * __useFindAllFaqsQuery__
 *
 * To run a query within a React component, call `useFindAllFaqsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllFaqsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllFaqsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindAllFaqsQuery(baseOptions?: Apollo.QueryHookOptions<FindAllFaqsQuery, FindAllFaqsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllFaqsQuery, FindAllFaqsQueryVariables>(FindAllFaqsDocument, options);
      }
export function useFindAllFaqsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllFaqsQuery, FindAllFaqsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllFaqsQuery, FindAllFaqsQueryVariables>(FindAllFaqsDocument, options);
        }
export type FindAllFaqsQueryHookResult = ReturnType<typeof useFindAllFaqsQuery>;
export type FindAllFaqsLazyQueryHookResult = ReturnType<typeof useFindAllFaqsLazyQuery>;
export type FindAllFaqsQueryResult = Apollo.QueryResult<FindAllFaqsQuery, FindAllFaqsQueryVariables>;
export const FindVacancyDocument = gql`
    query FindVacancy($vacancyId: Int!) {
  findVacancy(vacancyId: $vacancyId) {
    id
    title
    term
    weeks
    description
    skills
    totalPositions
    isFull
    startDate
    endDate
    offeredHours
    academicYear
    details
    taRecruiter {
      name
      email
    }
    module {
      id
      code
      title
      degree
      description
      moduleLeader {
        name
        email
      }
    }
  }
}
    `;

/**
 * __useFindVacancyQuery__
 *
 * To run a query within a React component, call `useFindVacancyQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindVacancyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindVacancyQuery({
 *   variables: {
 *      vacancyId: // value for 'vacancyId'
 *   },
 * });
 */
export function useFindVacancyQuery(baseOptions: Apollo.QueryHookOptions<FindVacancyQuery, FindVacancyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindVacancyQuery, FindVacancyQueryVariables>(FindVacancyDocument, options);
      }
export function useFindVacancyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindVacancyQuery, FindVacancyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindVacancyQuery, FindVacancyQueryVariables>(FindVacancyDocument, options);
        }
export type FindVacancyQueryHookResult = ReturnType<typeof useFindVacancyQuery>;
export type FindVacancyLazyQueryHookResult = ReturnType<typeof useFindVacancyLazyQuery>;
export type FindVacancyQueryResult = Apollo.QueryResult<FindVacancyQuery, FindVacancyQueryVariables>;
export const FindPreviousApplicationDocument = gql`
    query FindPreviousApplication($vacancyId: Int!) {
  findApplication(vacancyId: $vacancyId) {
    status
    applicationDate
    vacancy {
      id
      title
    }
    appliedHours
    motivationLetter
  }
}
    `;

/**
 * __useFindPreviousApplicationQuery__
 *
 * To run a query within a React component, call `useFindPreviousApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPreviousApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPreviousApplicationQuery({
 *   variables: {
 *      vacancyId: // value for 'vacancyId'
 *   },
 * });
 */
export function useFindPreviousApplicationQuery(baseOptions: Apollo.QueryHookOptions<FindPreviousApplicationQuery, FindPreviousApplicationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindPreviousApplicationQuery, FindPreviousApplicationQueryVariables>(FindPreviousApplicationDocument, options);
      }
export function useFindPreviousApplicationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindPreviousApplicationQuery, FindPreviousApplicationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindPreviousApplicationQuery, FindPreviousApplicationQueryVariables>(FindPreviousApplicationDocument, options);
        }
export type FindPreviousApplicationQueryHookResult = ReturnType<typeof useFindPreviousApplicationQuery>;
export type FindPreviousApplicationLazyQueryHookResult = ReturnType<typeof useFindPreviousApplicationLazyQuery>;
export type FindPreviousApplicationQueryResult = Apollo.QueryResult<FindPreviousApplicationQuery, FindPreviousApplicationQueryVariables>;
export const FindTAApplicationsDocument = gql`
    query FindTAApplications($status: [ApplicationStatus!]!) {
  findAllApplications(status: $status) {
    data {
      module {
        title
        code
      }
      vacancy {
        id
        title
        term
        weeks
        startDate
        endDate
        taRecruiter {
          email
        }
      }
      appliedHours
      applicationDate
      status
    }
  }
}
    `;

/**
 * __useFindTAApplicationsQuery__
 *
 * To run a query within a React component, call `useFindTAApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindTAApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindTAApplicationsQuery({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useFindTAApplicationsQuery(baseOptions: Apollo.QueryHookOptions<FindTAApplicationsQuery, FindTAApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindTAApplicationsQuery, FindTAApplicationsQueryVariables>(FindTAApplicationsDocument, options);
      }
export function useFindTAApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindTAApplicationsQuery, FindTAApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindTAApplicationsQuery, FindTAApplicationsQueryVariables>(FindTAApplicationsDocument, options);
        }
export type FindTAApplicationsQueryHookResult = ReturnType<typeof useFindTAApplicationsQuery>;
export type FindTAApplicationsLazyQueryHookResult = ReturnType<typeof useFindTAApplicationsLazyQuery>;
export type FindTAApplicationsQueryResult = Apollo.QueryResult<FindTAApplicationsQuery, FindTAApplicationsQueryVariables>;
export const FindTAJobsDocument = gql`
    query FindTAJobs {
  findAllJobs {
    module {
      id
      title
      code
    }
    vacancy {
      id
      title
      term
      weeks
      startDate
      endDate
      taRecruiter {
        email
      }
    }
    status
    hours
  }
}
    `;

/**
 * __useFindTAJobsQuery__
 *
 * To run a query within a React component, call `useFindTAJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindTAJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindTAJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindTAJobsQuery(baseOptions?: Apollo.QueryHookOptions<FindTAJobsQuery, FindTAJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindTAJobsQuery, FindTAJobsQueryVariables>(FindTAJobsDocument, options);
      }
export function useFindTAJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindTAJobsQuery, FindTAJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindTAJobsQuery, FindTAJobsQueryVariables>(FindTAJobsDocument, options);
        }
export type FindTAJobsQueryHookResult = ReturnType<typeof useFindTAJobsQuery>;
export type FindTAJobsLazyQueryHookResult = ReturnType<typeof useFindTAJobsLazyQuery>;
export type FindTAJobsQueryResult = Apollo.QueryResult<FindTAJobsQuery, FindTAJobsQueryVariables>;
export const ApplyJobDocument = gql`
    mutation ApplyJob($form: ApplicationForm!) {
  apply(applicationForm: $form)
}
    `;
export type ApplyJobMutationFn = Apollo.MutationFunction<ApplyJobMutation, ApplyJobMutationVariables>;

/**
 * __useApplyJobMutation__
 *
 * To run a mutation, you first call `useApplyJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyJobMutation, { data, loading, error }] = useApplyJobMutation({
 *   variables: {
 *      form: // value for 'form'
 *   },
 * });
 */
export function useApplyJobMutation(baseOptions?: Apollo.MutationHookOptions<ApplyJobMutation, ApplyJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyJobMutation, ApplyJobMutationVariables>(ApplyJobDocument, options);
      }
export type ApplyJobMutationHookResult = ReturnType<typeof useApplyJobMutation>;
export type ApplyJobMutationResult = Apollo.MutationResult<ApplyJobMutation>;
export type ApplyJobMutationOptions = Apollo.BaseMutationOptions<ApplyJobMutation, ApplyJobMutationVariables>;
export const SaveApplicationFormDocument = gql`
    mutation SaveApplicationForm($form: ApplicationForm!) {
  saveApplication(applicationForm: $form)
}
    `;
export type SaveApplicationFormMutationFn = Apollo.MutationFunction<SaveApplicationFormMutation, SaveApplicationFormMutationVariables>;

/**
 * __useSaveApplicationFormMutation__
 *
 * To run a mutation, you first call `useSaveApplicationFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveApplicationFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveApplicationFormMutation, { data, loading, error }] = useSaveApplicationFormMutation({
 *   variables: {
 *      form: // value for 'form'
 *   },
 * });
 */
export function useSaveApplicationFormMutation(baseOptions?: Apollo.MutationHookOptions<SaveApplicationFormMutation, SaveApplicationFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveApplicationFormMutation, SaveApplicationFormMutationVariables>(SaveApplicationFormDocument, options);
      }
export type SaveApplicationFormMutationHookResult = ReturnType<typeof useSaveApplicationFormMutation>;
export type SaveApplicationFormMutationResult = Apollo.MutationResult<SaveApplicationFormMutation>;
export type SaveApplicationFormMutationOptions = Apollo.BaseMutationOptions<SaveApplicationFormMutation, SaveApplicationFormMutationVariables>;
export const FindAllInitialBudgetApplicationsDocument = gql`
    query FindAllInitialBudgetApplications($first: Int!, $after: Cursor!, $status: InitialBudgetStatus!, $moduleCode: String) {
  findAllInitialBudgetApplications(
    first: $first
    after: $after
    status: $status
    moduleCode: $moduleCode
  ) {
    data {
      id
      module {
        code
        title
        moduleLeader {
          name
        }
      }
      totalHours
      applicationDate
      approvedHours
      approvedDate
    }
    count
  }
}
    `;

/**
 * __useFindAllInitialBudgetApplicationsQuery__
 *
 * To run a query within a React component, call `useFindAllInitialBudgetApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllInitialBudgetApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllInitialBudgetApplicationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      status: // value for 'status'
 *      moduleCode: // value for 'moduleCode'
 *   },
 * });
 */
export function useFindAllInitialBudgetApplicationsQuery(baseOptions: Apollo.QueryHookOptions<FindAllInitialBudgetApplicationsQuery, FindAllInitialBudgetApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllInitialBudgetApplicationsQuery, FindAllInitialBudgetApplicationsQueryVariables>(FindAllInitialBudgetApplicationsDocument, options);
      }
export function useFindAllInitialBudgetApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllInitialBudgetApplicationsQuery, FindAllInitialBudgetApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllInitialBudgetApplicationsQuery, FindAllInitialBudgetApplicationsQueryVariables>(FindAllInitialBudgetApplicationsDocument, options);
        }
export type FindAllInitialBudgetApplicationsQueryHookResult = ReturnType<typeof useFindAllInitialBudgetApplicationsQuery>;
export type FindAllInitialBudgetApplicationsLazyQueryHookResult = ReturnType<typeof useFindAllInitialBudgetApplicationsLazyQuery>;
export type FindAllInitialBudgetApplicationsQueryResult = Apollo.QueryResult<FindAllInitialBudgetApplicationsQuery, FindAllInitialBudgetApplicationsQueryVariables>;
export const FindBudgetApplicationForReviewDocument = gql`
    query FindBudgetApplicationForReview($applicationId: Int!) {
  findInitialBudgetById(applicationId: $applicationId) {
    module {
      code
      title
      moduleLeader {
        name
      }
    }
    term
    budgetOption
    totalHours
    jobDescription
    teaching
    support
    marking
  }
}
    `;

/**
 * __useFindBudgetApplicationForReviewQuery__
 *
 * To run a query within a React component, call `useFindBudgetApplicationForReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindBudgetApplicationForReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindBudgetApplicationForReviewQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useFindBudgetApplicationForReviewQuery(baseOptions: Apollo.QueryHookOptions<FindBudgetApplicationForReviewQuery, FindBudgetApplicationForReviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindBudgetApplicationForReviewQuery, FindBudgetApplicationForReviewQueryVariables>(FindBudgetApplicationForReviewDocument, options);
      }
export function useFindBudgetApplicationForReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindBudgetApplicationForReviewQuery, FindBudgetApplicationForReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindBudgetApplicationForReviewQuery, FindBudgetApplicationForReviewQueryVariables>(FindBudgetApplicationForReviewDocument, options);
        }
export type FindBudgetApplicationForReviewQueryHookResult = ReturnType<typeof useFindBudgetApplicationForReviewQuery>;
export type FindBudgetApplicationForReviewLazyQueryHookResult = ReturnType<typeof useFindBudgetApplicationForReviewLazyQuery>;
export type FindBudgetApplicationForReviewQueryResult = Apollo.QueryResult<FindBudgetApplicationForReviewQuery, FindBudgetApplicationForReviewQueryVariables>;
export const ApproveInitialBudgetApplicationDocument = gql`
    mutation ApproveInitialBudgetApplication($applicationId: Int!, $hours: Int!) {
  approveInitialBudget(applicationId: $applicationId, hours: $hours)
}
    `;
export type ApproveInitialBudgetApplicationMutationFn = Apollo.MutationFunction<ApproveInitialBudgetApplicationMutation, ApproveInitialBudgetApplicationMutationVariables>;

/**
 * __useApproveInitialBudgetApplicationMutation__
 *
 * To run a mutation, you first call `useApproveInitialBudgetApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveInitialBudgetApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveInitialBudgetApplicationMutation, { data, loading, error }] = useApproveInitialBudgetApplicationMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      hours: // value for 'hours'
 *   },
 * });
 */
export function useApproveInitialBudgetApplicationMutation(baseOptions?: Apollo.MutationHookOptions<ApproveInitialBudgetApplicationMutation, ApproveInitialBudgetApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveInitialBudgetApplicationMutation, ApproveInitialBudgetApplicationMutationVariables>(ApproveInitialBudgetApplicationDocument, options);
      }
export type ApproveInitialBudgetApplicationMutationHookResult = ReturnType<typeof useApproveInitialBudgetApplicationMutation>;
export type ApproveInitialBudgetApplicationMutationResult = Apollo.MutationResult<ApproveInitialBudgetApplicationMutation>;
export type ApproveInitialBudgetApplicationMutationOptions = Apollo.BaseMutationOptions<ApproveInitialBudgetApplicationMutation, ApproveInitialBudgetApplicationMutationVariables>;
export const FindAllExtraBudgetApplicationsDocument = gql`
    query FindAllExtraBudgetApplications($first: Int!, $after: Cursor!, $status: ExtraBudgetStatus!, $moduleCode: String) {
  findAllExtraBudgetApplications(
    first: $first
    after: $after
    status: $status
    moduleCode: $moduleCode
  ) {
    data {
      id
      module {
        code
        title
        moduleLeader {
          name
        }
      }
      totalHours
      applicationDate
      approvedHours
      decisionDate
    }
    count
  }
}
    `;

/**
 * __useFindAllExtraBudgetApplicationsQuery__
 *
 * To run a query within a React component, call `useFindAllExtraBudgetApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllExtraBudgetApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllExtraBudgetApplicationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      status: // value for 'status'
 *      moduleCode: // value for 'moduleCode'
 *   },
 * });
 */
export function useFindAllExtraBudgetApplicationsQuery(baseOptions: Apollo.QueryHookOptions<FindAllExtraBudgetApplicationsQuery, FindAllExtraBudgetApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllExtraBudgetApplicationsQuery, FindAllExtraBudgetApplicationsQueryVariables>(FindAllExtraBudgetApplicationsDocument, options);
      }
export function useFindAllExtraBudgetApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllExtraBudgetApplicationsQuery, FindAllExtraBudgetApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllExtraBudgetApplicationsQuery, FindAllExtraBudgetApplicationsQueryVariables>(FindAllExtraBudgetApplicationsDocument, options);
        }
export type FindAllExtraBudgetApplicationsQueryHookResult = ReturnType<typeof useFindAllExtraBudgetApplicationsQuery>;
export type FindAllExtraBudgetApplicationsLazyQueryHookResult = ReturnType<typeof useFindAllExtraBudgetApplicationsLazyQuery>;
export type FindAllExtraBudgetApplicationsQueryResult = Apollo.QueryResult<FindAllExtraBudgetApplicationsQuery, FindAllExtraBudgetApplicationsQueryVariables>;
export const FindExtraBudgetApplicationForReviewDocument = gql`
    query FindExtraBudgetApplicationForReview($applicationId: Int!) {
  findExtraBudgetApplication(applicationId: $applicationId) {
    module {
      code
      title
      moduleLeader {
        name
      }
    }
    term
    totalHours
    jobDescription
    teaching
    support
    marking
  }
}
    `;

/**
 * __useFindExtraBudgetApplicationForReviewQuery__
 *
 * To run a query within a React component, call `useFindExtraBudgetApplicationForReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindExtraBudgetApplicationForReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindExtraBudgetApplicationForReviewQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useFindExtraBudgetApplicationForReviewQuery(baseOptions: Apollo.QueryHookOptions<FindExtraBudgetApplicationForReviewQuery, FindExtraBudgetApplicationForReviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindExtraBudgetApplicationForReviewQuery, FindExtraBudgetApplicationForReviewQueryVariables>(FindExtraBudgetApplicationForReviewDocument, options);
      }
export function useFindExtraBudgetApplicationForReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindExtraBudgetApplicationForReviewQuery, FindExtraBudgetApplicationForReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindExtraBudgetApplicationForReviewQuery, FindExtraBudgetApplicationForReviewQueryVariables>(FindExtraBudgetApplicationForReviewDocument, options);
        }
export type FindExtraBudgetApplicationForReviewQueryHookResult = ReturnType<typeof useFindExtraBudgetApplicationForReviewQuery>;
export type FindExtraBudgetApplicationForReviewLazyQueryHookResult = ReturnType<typeof useFindExtraBudgetApplicationForReviewLazyQuery>;
export type FindExtraBudgetApplicationForReviewQueryResult = Apollo.QueryResult<FindExtraBudgetApplicationForReviewQuery, FindExtraBudgetApplicationForReviewQueryVariables>;
export const FindAllModulesWithPaginationDocument = gql`
    query FindAllModulesWithPagination($first: Int!, $after: Cursor!, $moduleCode: String) {
  findAllModulesWithPagination(
    first: $first
    after: $after
    moduleCode: $moduleCode
  ) {
    data {
      id
      code
      title
      term
      moduleLeader {
        name
      }
    }
    count
  }
}
    `;

/**
 * __useFindAllModulesWithPaginationQuery__
 *
 * To run a query within a React component, call `useFindAllModulesWithPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllModulesWithPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllModulesWithPaginationQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      moduleCode: // value for 'moduleCode'
 *   },
 * });
 */
export function useFindAllModulesWithPaginationQuery(baseOptions: Apollo.QueryHookOptions<FindAllModulesWithPaginationQuery, FindAllModulesWithPaginationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllModulesWithPaginationQuery, FindAllModulesWithPaginationQueryVariables>(FindAllModulesWithPaginationDocument, options);
      }
export function useFindAllModulesWithPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllModulesWithPaginationQuery, FindAllModulesWithPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllModulesWithPaginationQuery, FindAllModulesWithPaginationQueryVariables>(FindAllModulesWithPaginationDocument, options);
        }
export type FindAllModulesWithPaginationQueryHookResult = ReturnType<typeof useFindAllModulesWithPaginationQuery>;
export type FindAllModulesWithPaginationLazyQueryHookResult = ReturnType<typeof useFindAllModulesWithPaginationLazyQuery>;
export type FindAllModulesWithPaginationQueryResult = Apollo.QueryResult<FindAllModulesWithPaginationQuery, FindAllModulesWithPaginationQueryVariables>;
export const FindModuleForReviewDocument = gql`
    query FindModuleForReview($moduleId: Int!) {
  findModule(moduleId: $moduleId) {
    code
    title
    moduleLeader {
      email
      title
      firstName
      lastName
    }
    degree
    term
    description
  }
}
    `;

/**
 * __useFindModuleForReviewQuery__
 *
 * To run a query within a React component, call `useFindModuleForReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindModuleForReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindModuleForReviewQuery({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useFindModuleForReviewQuery(baseOptions: Apollo.QueryHookOptions<FindModuleForReviewQuery, FindModuleForReviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindModuleForReviewQuery, FindModuleForReviewQueryVariables>(FindModuleForReviewDocument, options);
      }
export function useFindModuleForReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindModuleForReviewQuery, FindModuleForReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindModuleForReviewQuery, FindModuleForReviewQueryVariables>(FindModuleForReviewDocument, options);
        }
export type FindModuleForReviewQueryHookResult = ReturnType<typeof useFindModuleForReviewQuery>;
export type FindModuleForReviewLazyQueryHookResult = ReturnType<typeof useFindModuleForReviewLazyQuery>;
export type FindModuleForReviewQueryResult = Apollo.QueryResult<FindModuleForReviewQuery, FindModuleForReviewQueryVariables>;
export const FindAllAdminTeamMembersDocument = gql`
    query FindAllAdminTeamMembers {
  findAllAdminTeamMembers {
    email
    name
    permissions {
      role
    }
  }
}
    `;

/**
 * __useFindAllAdminTeamMembersQuery__
 *
 * To run a query within a React component, call `useFindAllAdminTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllAdminTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllAdminTeamMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindAllAdminTeamMembersQuery(baseOptions?: Apollo.QueryHookOptions<FindAllAdminTeamMembersQuery, FindAllAdminTeamMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllAdminTeamMembersQuery, FindAllAdminTeamMembersQueryVariables>(FindAllAdminTeamMembersDocument, options);
      }
export function useFindAllAdminTeamMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllAdminTeamMembersQuery, FindAllAdminTeamMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllAdminTeamMembersQuery, FindAllAdminTeamMembersQueryVariables>(FindAllAdminTeamMembersDocument, options);
        }
export type FindAllAdminTeamMembersQueryHookResult = ReturnType<typeof useFindAllAdminTeamMembersQuery>;
export type FindAllAdminTeamMembersLazyQueryHookResult = ReturnType<typeof useFindAllAdminTeamMembersLazyQuery>;
export type FindAllAdminTeamMembersQueryResult = Apollo.QueryResult<FindAllAdminTeamMembersQuery, FindAllAdminTeamMembersQueryVariables>;
export const FindAdminTeamMemberDocument = gql`
    query FindAdminTeamMember($email: String!) {
  findAdminTeamMember(email: $email) {
    email
    title
    firstName
    lastName
    permissions {
      role
    }
  }
}
    `;

/**
 * __useFindAdminTeamMemberQuery__
 *
 * To run a query within a React component, call `useFindAdminTeamMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAdminTeamMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAdminTeamMemberQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useFindAdminTeamMemberQuery(baseOptions: Apollo.QueryHookOptions<FindAdminTeamMemberQuery, FindAdminTeamMemberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAdminTeamMemberQuery, FindAdminTeamMemberQueryVariables>(FindAdminTeamMemberDocument, options);
      }
export function useFindAdminTeamMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAdminTeamMemberQuery, FindAdminTeamMemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAdminTeamMemberQuery, FindAdminTeamMemberQueryVariables>(FindAdminTeamMemberDocument, options);
        }
export type FindAdminTeamMemberQueryHookResult = ReturnType<typeof useFindAdminTeamMemberQuery>;
export type FindAdminTeamMemberLazyQueryHookResult = ReturnType<typeof useFindAdminTeamMemberLazyQuery>;
export type FindAdminTeamMemberQueryResult = Apollo.QueryResult<FindAdminTeamMemberQuery, FindAdminTeamMemberQueryVariables>;
export const FindFaqListDocument = gql`
    query FindFaqList {
  findAllFaqs {
    id
    question
    updateDate
  }
}
    `;

/**
 * __useFindFaqListQuery__
 *
 * To run a query within a React component, call `useFindFaqListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindFaqListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindFaqListQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindFaqListQuery(baseOptions?: Apollo.QueryHookOptions<FindFaqListQuery, FindFaqListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindFaqListQuery, FindFaqListQueryVariables>(FindFaqListDocument, options);
      }
export function useFindFaqListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindFaqListQuery, FindFaqListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindFaqListQuery, FindFaqListQueryVariables>(FindFaqListDocument, options);
        }
export type FindFaqListQueryHookResult = ReturnType<typeof useFindFaqListQuery>;
export type FindFaqListLazyQueryHookResult = ReturnType<typeof useFindFaqListLazyQuery>;
export type FindFaqListQueryResult = Apollo.QueryResult<FindFaqListQuery, FindFaqListQueryVariables>;
export const FindFaqDocument = gql`
    query FindFaq($id: Int!) {
  findFaq(id: $id) {
    question
    answer
  }
}
    `;

/**
 * __useFindFaqQuery__
 *
 * To run a query within a React component, call `useFindFaqQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindFaqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindFaqQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindFaqQuery(baseOptions: Apollo.QueryHookOptions<FindFaqQuery, FindFaqQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindFaqQuery, FindFaqQueryVariables>(FindFaqDocument, options);
      }
export function useFindFaqLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindFaqQuery, FindFaqQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindFaqQuery, FindFaqQueryVariables>(FindFaqDocument, options);
        }
export type FindFaqQueryHookResult = ReturnType<typeof useFindFaqQuery>;
export type FindFaqLazyQueryHookResult = ReturnType<typeof useFindFaqLazyQuery>;
export type FindFaqQueryResult = Apollo.QueryResult<FindFaqQuery, FindFaqQueryVariables>;
export const SystemSettingsDocument = gql`
    query SystemSettings {
  systemSettings {
    id
    minimumWorkingHours
    taAllowedWorkingHours
    applicationDeadline
  }
}
    `;

/**
 * __useSystemSettingsQuery__
 *
 * To run a query within a React component, call `useSystemSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSystemSettingsQuery(baseOptions?: Apollo.QueryHookOptions<SystemSettingsQuery, SystemSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SystemSettingsQuery, SystemSettingsQueryVariables>(SystemSettingsDocument, options);
      }
export function useSystemSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SystemSettingsQuery, SystemSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SystemSettingsQuery, SystemSettingsQueryVariables>(SystemSettingsDocument, options);
        }
export type SystemSettingsQueryHookResult = ReturnType<typeof useSystemSettingsQuery>;
export type SystemSettingsLazyQueryHookResult = ReturnType<typeof useSystemSettingsLazyQuery>;
export type SystemSettingsQueryResult = Apollo.QueryResult<SystemSettingsQuery, SystemSettingsQueryVariables>;
export const FindAllTasWithTotalHoursDocument = gql`
    query FindAllTasWithTotalHours($first: Int!, $after: Cursor!, $totalHoursOption: TotalHoursOption!, $email: String) {
  findAllTAsWithTotalHours(
    first: $first
    after: $after
    email: $email
    totalHoursOption: $totalHoursOption
  ) {
    data {
      id
      email
      name
      profile {
        jobRole
        totalHours
        studentType
      }
      jobs {
        module {
          id
        }
      }
    }
    count
  }
}
    `;

/**
 * __useFindAllTasWithTotalHoursQuery__
 *
 * To run a query within a React component, call `useFindAllTasWithTotalHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllTasWithTotalHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllTasWithTotalHoursQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      totalHoursOption: // value for 'totalHoursOption'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useFindAllTasWithTotalHoursQuery(baseOptions: Apollo.QueryHookOptions<FindAllTasWithTotalHoursQuery, FindAllTasWithTotalHoursQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllTasWithTotalHoursQuery, FindAllTasWithTotalHoursQueryVariables>(FindAllTasWithTotalHoursDocument, options);
      }
export function useFindAllTasWithTotalHoursLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllTasWithTotalHoursQuery, FindAllTasWithTotalHoursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllTasWithTotalHoursQuery, FindAllTasWithTotalHoursQueryVariables>(FindAllTasWithTotalHoursDocument, options);
        }
export type FindAllTasWithTotalHoursQueryHookResult = ReturnType<typeof useFindAllTasWithTotalHoursQuery>;
export type FindAllTasWithTotalHoursLazyQueryHookResult = ReturnType<typeof useFindAllTasWithTotalHoursLazyQuery>;
export type FindAllTasWithTotalHoursQueryResult = Apollo.QueryResult<FindAllTasWithTotalHoursQuery, FindAllTasWithTotalHoursQueryVariables>;
export const ApproveExtraBudgetApplicationDocument = gql`
    mutation ApproveExtraBudgetApplication($applicationId: Int!, $hours: Int!) {
  approveExtraBudget(applicationId: $applicationId, hours: $hours)
}
    `;
export type ApproveExtraBudgetApplicationMutationFn = Apollo.MutationFunction<ApproveExtraBudgetApplicationMutation, ApproveExtraBudgetApplicationMutationVariables>;

/**
 * __useApproveExtraBudgetApplicationMutation__
 *
 * To run a mutation, you first call `useApproveExtraBudgetApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveExtraBudgetApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveExtraBudgetApplicationMutation, { data, loading, error }] = useApproveExtraBudgetApplicationMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      hours: // value for 'hours'
 *   },
 * });
 */
export function useApproveExtraBudgetApplicationMutation(baseOptions?: Apollo.MutationHookOptions<ApproveExtraBudgetApplicationMutation, ApproveExtraBudgetApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveExtraBudgetApplicationMutation, ApproveExtraBudgetApplicationMutationVariables>(ApproveExtraBudgetApplicationDocument, options);
      }
export type ApproveExtraBudgetApplicationMutationHookResult = ReturnType<typeof useApproveExtraBudgetApplicationMutation>;
export type ApproveExtraBudgetApplicationMutationResult = Apollo.MutationResult<ApproveExtraBudgetApplicationMutation>;
export type ApproveExtraBudgetApplicationMutationOptions = Apollo.BaseMutationOptions<ApproveExtraBudgetApplicationMutation, ApproveExtraBudgetApplicationMutationVariables>;
export const RejectExtraBudgetApplicationDocument = gql`
    mutation RejectExtraBudgetApplication($applicationId: Int!) {
  rejectExtraBudget(applicationId: $applicationId)
}
    `;
export type RejectExtraBudgetApplicationMutationFn = Apollo.MutationFunction<RejectExtraBudgetApplicationMutation, RejectExtraBudgetApplicationMutationVariables>;

/**
 * __useRejectExtraBudgetApplicationMutation__
 *
 * To run a mutation, you first call `useRejectExtraBudgetApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectExtraBudgetApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectExtraBudgetApplicationMutation, { data, loading, error }] = useRejectExtraBudgetApplicationMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useRejectExtraBudgetApplicationMutation(baseOptions?: Apollo.MutationHookOptions<RejectExtraBudgetApplicationMutation, RejectExtraBudgetApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectExtraBudgetApplicationMutation, RejectExtraBudgetApplicationMutationVariables>(RejectExtraBudgetApplicationDocument, options);
      }
export type RejectExtraBudgetApplicationMutationHookResult = ReturnType<typeof useRejectExtraBudgetApplicationMutation>;
export type RejectExtraBudgetApplicationMutationResult = Apollo.MutationResult<RejectExtraBudgetApplicationMutation>;
export type RejectExtraBudgetApplicationMutationOptions = Apollo.BaseMutationOptions<RejectExtraBudgetApplicationMutation, RejectExtraBudgetApplicationMutationVariables>;
export const CreateModuleDocument = gql`
    mutation CreateModule($data: CourseCreateInput!) {
  createModule(data: $data)
}
    `;
export type CreateModuleMutationFn = Apollo.MutationFunction<CreateModuleMutation, CreateModuleMutationVariables>;

/**
 * __useCreateModuleMutation__
 *
 * To run a mutation, you first call `useCreateModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModuleMutation, { data, loading, error }] = useCreateModuleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateModuleMutation(baseOptions?: Apollo.MutationHookOptions<CreateModuleMutation, CreateModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateModuleMutation, CreateModuleMutationVariables>(CreateModuleDocument, options);
      }
export type CreateModuleMutationHookResult = ReturnType<typeof useCreateModuleMutation>;
export type CreateModuleMutationResult = Apollo.MutationResult<CreateModuleMutation>;
export type CreateModuleMutationOptions = Apollo.BaseMutationOptions<CreateModuleMutation, CreateModuleMutationVariables>;
export const CreateAdminTeamMemberDocument = gql`
    mutation CreateAdminTeamMember($data: AdminTeamRoleUpdateInput!) {
  createAdminTeamMember(data: $data)
}
    `;
export type CreateAdminTeamMemberMutationFn = Apollo.MutationFunction<CreateAdminTeamMemberMutation, CreateAdminTeamMemberMutationVariables>;

/**
 * __useCreateAdminTeamMemberMutation__
 *
 * To run a mutation, you first call `useCreateAdminTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdminTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdminTeamMemberMutation, { data, loading, error }] = useCreateAdminTeamMemberMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAdminTeamMemberMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdminTeamMemberMutation, CreateAdminTeamMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdminTeamMemberMutation, CreateAdminTeamMemberMutationVariables>(CreateAdminTeamMemberDocument, options);
      }
export type CreateAdminTeamMemberMutationHookResult = ReturnType<typeof useCreateAdminTeamMemberMutation>;
export type CreateAdminTeamMemberMutationResult = Apollo.MutationResult<CreateAdminTeamMemberMutation>;
export type CreateAdminTeamMemberMutationOptions = Apollo.BaseMutationOptions<CreateAdminTeamMemberMutation, CreateAdminTeamMemberMutationVariables>;
export const UpdateAdminTeamMemberRoleDocument = gql`
    mutation UpdateAdminTeamMemberRole($data: AdminTeamRoleUpdateInput!) {
  updateAdminTeamMemberRole(data: $data)
}
    `;
export type UpdateAdminTeamMemberRoleMutationFn = Apollo.MutationFunction<UpdateAdminTeamMemberRoleMutation, UpdateAdminTeamMemberRoleMutationVariables>;

/**
 * __useUpdateAdminTeamMemberRoleMutation__
 *
 * To run a mutation, you first call `useUpdateAdminTeamMemberRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminTeamMemberRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminTeamMemberRoleMutation, { data, loading, error }] = useUpdateAdminTeamMemberRoleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAdminTeamMemberRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdminTeamMemberRoleMutation, UpdateAdminTeamMemberRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdminTeamMemberRoleMutation, UpdateAdminTeamMemberRoleMutationVariables>(UpdateAdminTeamMemberRoleDocument, options);
      }
export type UpdateAdminTeamMemberRoleMutationHookResult = ReturnType<typeof useUpdateAdminTeamMemberRoleMutation>;
export type UpdateAdminTeamMemberRoleMutationResult = Apollo.MutationResult<UpdateAdminTeamMemberRoleMutation>;
export type UpdateAdminTeamMemberRoleMutationOptions = Apollo.BaseMutationOptions<UpdateAdminTeamMemberRoleMutation, UpdateAdminTeamMemberRoleMutationVariables>;
export const DeleteAdminTeamMemberDocument = gql`
    mutation DeleteAdminTeamMember($email: String!) {
  deleteAdminTeamMember(email: $email)
}
    `;
export type DeleteAdminTeamMemberMutationFn = Apollo.MutationFunction<DeleteAdminTeamMemberMutation, DeleteAdminTeamMemberMutationVariables>;

/**
 * __useDeleteAdminTeamMemberMutation__
 *
 * To run a mutation, you first call `useDeleteAdminTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdminTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdminTeamMemberMutation, { data, loading, error }] = useDeleteAdminTeamMemberMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useDeleteAdminTeamMemberMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAdminTeamMemberMutation, DeleteAdminTeamMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAdminTeamMemberMutation, DeleteAdminTeamMemberMutationVariables>(DeleteAdminTeamMemberDocument, options);
      }
export type DeleteAdminTeamMemberMutationHookResult = ReturnType<typeof useDeleteAdminTeamMemberMutation>;
export type DeleteAdminTeamMemberMutationResult = Apollo.MutationResult<DeleteAdminTeamMemberMutation>;
export type DeleteAdminTeamMemberMutationOptions = Apollo.BaseMutationOptions<DeleteAdminTeamMemberMutation, DeleteAdminTeamMemberMutationVariables>;
export const CreateFaqDocument = gql`
    mutation CreateFaq($data: FaqCreateInput!) {
  createFaq(data: $data)
}
    `;
export type CreateFaqMutationFn = Apollo.MutationFunction<CreateFaqMutation, CreateFaqMutationVariables>;

/**
 * __useCreateFaqMutation__
 *
 * To run a mutation, you first call `useCreateFaqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFaqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFaqMutation, { data, loading, error }] = useCreateFaqMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateFaqMutation(baseOptions?: Apollo.MutationHookOptions<CreateFaqMutation, CreateFaqMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFaqMutation, CreateFaqMutationVariables>(CreateFaqDocument, options);
      }
export type CreateFaqMutationHookResult = ReturnType<typeof useCreateFaqMutation>;
export type CreateFaqMutationResult = Apollo.MutationResult<CreateFaqMutation>;
export type CreateFaqMutationOptions = Apollo.BaseMutationOptions<CreateFaqMutation, CreateFaqMutationVariables>;
export const UpdateFaqDocument = gql`
    mutation UpdateFaq($data: FaqUpdateInput!) {
  updateFaq(data: $data)
}
    `;
export type UpdateFaqMutationFn = Apollo.MutationFunction<UpdateFaqMutation, UpdateFaqMutationVariables>;

/**
 * __useUpdateFaqMutation__
 *
 * To run a mutation, you first call `useUpdateFaqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFaqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFaqMutation, { data, loading, error }] = useUpdateFaqMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateFaqMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFaqMutation, UpdateFaqMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFaqMutation, UpdateFaqMutationVariables>(UpdateFaqDocument, options);
      }
export type UpdateFaqMutationHookResult = ReturnType<typeof useUpdateFaqMutation>;
export type UpdateFaqMutationResult = Apollo.MutationResult<UpdateFaqMutation>;
export type UpdateFaqMutationOptions = Apollo.BaseMutationOptions<UpdateFaqMutation, UpdateFaqMutationVariables>;
export const DeleteFaqDocument = gql`
    mutation DeleteFaq($id: Int!) {
  deleteFaq(id: $id)
}
    `;
export type DeleteFaqMutationFn = Apollo.MutationFunction<DeleteFaqMutation, DeleteFaqMutationVariables>;

/**
 * __useDeleteFaqMutation__
 *
 * To run a mutation, you first call `useDeleteFaqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFaqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFaqMutation, { data, loading, error }] = useDeleteFaqMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFaqMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFaqMutation, DeleteFaqMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFaqMutation, DeleteFaqMutationVariables>(DeleteFaqDocument, options);
      }
export type DeleteFaqMutationHookResult = ReturnType<typeof useDeleteFaqMutation>;
export type DeleteFaqMutationResult = Apollo.MutationResult<DeleteFaqMutation>;
export type DeleteFaqMutationOptions = Apollo.BaseMutationOptions<DeleteFaqMutation, DeleteFaqMutationVariables>;
export const UpdateSystemSettingsDocument = gql`
    mutation UpdateSystemSettings($data: SystemSettingsUpdateInput!) {
  updateSystemSettings(data: $data)
}
    `;
export type UpdateSystemSettingsMutationFn = Apollo.MutationFunction<UpdateSystemSettingsMutation, UpdateSystemSettingsMutationVariables>;

/**
 * __useUpdateSystemSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateSystemSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSystemSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSystemSettingsMutation, { data, loading, error }] = useUpdateSystemSettingsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSystemSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSystemSettingsMutation, UpdateSystemSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSystemSettingsMutation, UpdateSystemSettingsMutationVariables>(UpdateSystemSettingsDocument, options);
      }
export type UpdateSystemSettingsMutationHookResult = ReturnType<typeof useUpdateSystemSettingsMutation>;
export type UpdateSystemSettingsMutationResult = Apollo.MutationResult<UpdateSystemSettingsMutation>;
export type UpdateSystemSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateSystemSettingsMutation, UpdateSystemSettingsMutationVariables>;
export const FindAllNotificationsDocument = gql`
    query FindAllNotifications {
  findAllNotifications {
    subject
    body
    read
    createTime
  }
}
    `;

/**
 * __useFindAllNotificationsQuery__
 *
 * To run a query within a React component, call `useFindAllNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindAllNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<FindAllNotificationsQuery, FindAllNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllNotificationsQuery, FindAllNotificationsQueryVariables>(FindAllNotificationsDocument, options);
      }
export function useFindAllNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllNotificationsQuery, FindAllNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllNotificationsQuery, FindAllNotificationsQueryVariables>(FindAllNotificationsDocument, options);
        }
export type FindAllNotificationsQueryHookResult = ReturnType<typeof useFindAllNotificationsQuery>;
export type FindAllNotificationsLazyQueryHookResult = ReturnType<typeof useFindAllNotificationsLazyQuery>;
export type FindAllNotificationsQueryResult = Apollo.QueryResult<FindAllNotificationsQuery, FindAllNotificationsQueryVariables>;
export const ReadAllNotificationDocument = gql`
    mutation ReadAllNotification {
  readAllNotifications
}
    `;
export type ReadAllNotificationMutationFn = Apollo.MutationFunction<ReadAllNotificationMutation, ReadAllNotificationMutationVariables>;

/**
 * __useReadAllNotificationMutation__
 *
 * To run a mutation, you first call `useReadAllNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadAllNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readAllNotificationMutation, { data, loading, error }] = useReadAllNotificationMutation({
 *   variables: {
 *   },
 * });
 */
export function useReadAllNotificationMutation(baseOptions?: Apollo.MutationHookOptions<ReadAllNotificationMutation, ReadAllNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReadAllNotificationMutation, ReadAllNotificationMutationVariables>(ReadAllNotificationDocument, options);
      }
export type ReadAllNotificationMutationHookResult = ReturnType<typeof useReadAllNotificationMutation>;
export type ReadAllNotificationMutationResult = Apollo.MutationResult<ReadAllNotificationMutation>;
export type ReadAllNotificationMutationOptions = Apollo.BaseMutationOptions<ReadAllNotificationMutation, ReadAllNotificationMutationVariables>;
export const TABasicProfileDocument = gql`
    query TABasicProfile {
  taProfile {
    jobRole
    allowWorkingHours
    department
    phone
    researchGroup
    studentType
    university
    year
    homeAddress
    workBefore
    needTraining
    cvUrl
  }
}
    `;

/**
 * __useTABasicProfileQuery__
 *
 * To run a query within a React component, call `useTABasicProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useTABasicProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTABasicProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useTABasicProfileQuery(baseOptions?: Apollo.QueryHookOptions<TABasicProfileQuery, TABasicProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TABasicProfileQuery, TABasicProfileQueryVariables>(TABasicProfileDocument, options);
      }
export function useTABasicProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TABasicProfileQuery, TABasicProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TABasicProfileQuery, TABasicProfileQueryVariables>(TABasicProfileDocument, options);
        }
export type TABasicProfileQueryHookResult = ReturnType<typeof useTABasicProfileQuery>;
export type TABasicProfileLazyQueryHookResult = ReturnType<typeof useTABasicProfileLazyQuery>;
export type TABasicProfileQueryResult = Apollo.QueryResult<TABasicProfileQuery, TABasicProfileQueryVariables>;
export const TADocumentsDocument = gql`
    query TADocuments {
  taProfile {
    passportUrl
    visaUrl
    rtwStatus
    bankUrl
    hrFormUrl
  }
}
    `;

/**
 * __useTADocumentsQuery__
 *
 * To run a query within a React component, call `useTADocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTADocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTADocumentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTADocumentsQuery(baseOptions?: Apollo.QueryHookOptions<TADocumentsQuery, TADocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TADocumentsQuery, TADocumentsQueryVariables>(TADocumentsDocument, options);
      }
export function useTADocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TADocumentsQuery, TADocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TADocumentsQuery, TADocumentsQueryVariables>(TADocumentsDocument, options);
        }
export type TADocumentsQueryHookResult = ReturnType<typeof useTADocumentsQuery>;
export type TADocumentsLazyQueryHookResult = ReturnType<typeof useTADocumentsLazyQuery>;
export type TADocumentsQueryResult = Apollo.QueryResult<TADocumentsQuery, TADocumentsQueryVariables>;
export const UpdateBasicProfileDocument = gql`
    mutation UpdateBasicProfile($data: BasicProfileUpdateInput!) {
  updateProfile(data: $data)
}
    `;
export type UpdateBasicProfileMutationFn = Apollo.MutationFunction<UpdateBasicProfileMutation, UpdateBasicProfileMutationVariables>;

/**
 * __useUpdateBasicProfileMutation__
 *
 * To run a mutation, you first call `useUpdateBasicProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBasicProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBasicProfileMutation, { data, loading, error }] = useUpdateBasicProfileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateBasicProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBasicProfileMutation, UpdateBasicProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBasicProfileMutation, UpdateBasicProfileMutationVariables>(UpdateBasicProfileDocument, options);
      }
export type UpdateBasicProfileMutationHookResult = ReturnType<typeof useUpdateBasicProfileMutation>;
export type UpdateBasicProfileMutationResult = Apollo.MutationResult<UpdateBasicProfileMutation>;
export type UpdateBasicProfileMutationOptions = Apollo.BaseMutationOptions<UpdateBasicProfileMutation, UpdateBasicProfileMutationVariables>;
export const UpdateDocumentProfileDocument = gql`
    mutation UpdateDocumentProfile($data: DocumentProfileUpdateInput!) {
  updateDocument(data: $data)
}
    `;
export type UpdateDocumentProfileMutationFn = Apollo.MutationFunction<UpdateDocumentProfileMutation, UpdateDocumentProfileMutationVariables>;

/**
 * __useUpdateDocumentProfileMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentProfileMutation, { data, loading, error }] = useUpdateDocumentProfileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDocumentProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocumentProfileMutation, UpdateDocumentProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocumentProfileMutation, UpdateDocumentProfileMutationVariables>(UpdateDocumentProfileDocument, options);
      }
export type UpdateDocumentProfileMutationHookResult = ReturnType<typeof useUpdateDocumentProfileMutation>;
export type UpdateDocumentProfileMutationResult = Apollo.MutationResult<UpdateDocumentProfileMutation>;
export type UpdateDocumentProfileMutationOptions = Apollo.BaseMutationOptions<UpdateDocumentProfileMutation, UpdateDocumentProfileMutationVariables>;
export const FindAllTAsWithRTWStatusDocument = gql`
    query FindAllTAsWithRTWStatus($rtwStatus: RTWStatus!, $first: Int!, $after: Cursor!, $email: String) {
  findAllTAsWithRTWStatus(
    rtwStatus: $rtwStatus
    first: $first
    after: $after
    email: $email
  ) {
    data {
      id
      name
      email
      profile {
        jobRole
        studentType
        passportUrl
        visaUrl
        rtwCheckedDate
      }
    }
    count
  }
}
    `;

/**
 * __useFindAllTAsWithRTWStatusQuery__
 *
 * To run a query within a React component, call `useFindAllTAsWithRTWStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllTAsWithRTWStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllTAsWithRTWStatusQuery({
 *   variables: {
 *      rtwStatus: // value for 'rtwStatus'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useFindAllTAsWithRTWStatusQuery(baseOptions: Apollo.QueryHookOptions<FindAllTAsWithRTWStatusQuery, FindAllTAsWithRTWStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllTAsWithRTWStatusQuery, FindAllTAsWithRTWStatusQueryVariables>(FindAllTAsWithRTWStatusDocument, options);
      }
export function useFindAllTAsWithRTWStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllTAsWithRTWStatusQuery, FindAllTAsWithRTWStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllTAsWithRTWStatusQuery, FindAllTAsWithRTWStatusQueryVariables>(FindAllTAsWithRTWStatusDocument, options);
        }
export type FindAllTAsWithRTWStatusQueryHookResult = ReturnType<typeof useFindAllTAsWithRTWStatusQuery>;
export type FindAllTAsWithRTWStatusLazyQueryHookResult = ReturnType<typeof useFindAllTAsWithRTWStatusLazyQuery>;
export type FindAllTAsWithRTWStatusQueryResult = Apollo.QueryResult<FindAllTAsWithRTWStatusQuery, FindAllTAsWithRTWStatusQueryVariables>;
export const CheckRTWDocument = gql`
    mutation CheckRTW($email: String!) {
  checkRTW(email: $email)
}
    `;
export type CheckRTWMutationFn = Apollo.MutationFunction<CheckRTWMutation, CheckRTWMutationVariables>;

/**
 * __useCheckRTWMutation__
 *
 * To run a mutation, you first call `useCheckRTWMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckRTWMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkRtwMutation, { data, loading, error }] = useCheckRTWMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCheckRTWMutation(baseOptions?: Apollo.MutationHookOptions<CheckRTWMutation, CheckRTWMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckRTWMutation, CheckRTWMutationVariables>(CheckRTWDocument, options);
      }
export type CheckRTWMutationHookResult = ReturnType<typeof useCheckRTWMutation>;
export type CheckRTWMutationResult = Apollo.MutationResult<CheckRTWMutation>;
export type CheckRTWMutationOptions = Apollo.BaseMutationOptions<CheckRTWMutation, CheckRTWMutationVariables>;
export const FindUserWithNameDocument = gql`
    query FindUserWithName($name: String!) {
  findUserWithName(name: $name) {
    id
    name
    email
  }
}
    `;

/**
 * __useFindUserWithNameQuery__
 *
 * To run a query within a React component, call `useFindUserWithNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUserWithNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUserWithNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useFindUserWithNameQuery(baseOptions: Apollo.QueryHookOptions<FindUserWithNameQuery, FindUserWithNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindUserWithNameQuery, FindUserWithNameQueryVariables>(FindUserWithNameDocument, options);
      }
export function useFindUserWithNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUserWithNameQuery, FindUserWithNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindUserWithNameQuery, FindUserWithNameQueryVariables>(FindUserWithNameDocument, options);
        }
export type FindUserWithNameQueryHookResult = ReturnType<typeof useFindUserWithNameQuery>;
export type FindUserWithNameLazyQueryHookResult = ReturnType<typeof useFindUserWithNameLazyQuery>;
export type FindUserWithNameQueryResult = Apollo.QueryResult<FindUserWithNameQuery, FindUserWithNameQueryVariables>;
export const FindTAProfileDocument = gql`
    query FindTAProfile($id: Int!) {
  findTAById(id: $id) {
    name
    email
    profile {
      researchGroup
      department
      university
      jobRole
      year
      studentType
      allowWorkingHours
      needTraining
      workBefore
      cvUrl
    }
    jobs {
      module {
        title
        code
      }
      vacancy {
        title
        startDate
        endDate
        weeks
        term
      }
      hours
    }
  }
}
    `;

/**
 * __useFindTAProfileQuery__
 *
 * To run a query within a React component, call `useFindTAProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindTAProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindTAProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindTAProfileQuery(baseOptions: Apollo.QueryHookOptions<FindTAProfileQuery, FindTAProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindTAProfileQuery, FindTAProfileQueryVariables>(FindTAProfileDocument, options);
      }
export function useFindTAProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindTAProfileQuery, FindTAProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindTAProfileQuery, FindTAProfileQueryVariables>(FindTAProfileDocument, options);
        }
export type FindTAProfileQueryHookResult = ReturnType<typeof useFindTAProfileQuery>;
export type FindTAProfileLazyQueryHookResult = ReturnType<typeof useFindTAProfileLazyQuery>;
export type FindTAProfileQueryResult = Apollo.QueryResult<FindTAProfileQuery, FindTAProfileQueryVariables>;
export const FindAllTAByModuleCodeDocument = gql`
    query FindAllTAByModuleCode($moduleCode: String!) {
  findAllTAs(moduleCode: $moduleCode) {
    id
    name
    email
    profile {
      studentType
    }
    jobs {
      status
      vacancy {
        title
      }
      hours
      approvedDate
    }
  }
}
    `;

/**
 * __useFindAllTAByModuleCodeQuery__
 *
 * To run a query within a React component, call `useFindAllTAByModuleCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllTAByModuleCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllTAByModuleCodeQuery({
 *   variables: {
 *      moduleCode: // value for 'moduleCode'
 *   },
 * });
 */
export function useFindAllTAByModuleCodeQuery(baseOptions: Apollo.QueryHookOptions<FindAllTAByModuleCodeQuery, FindAllTAByModuleCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllTAByModuleCodeQuery, FindAllTAByModuleCodeQueryVariables>(FindAllTAByModuleCodeDocument, options);
      }
export function useFindAllTAByModuleCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllTAByModuleCodeQuery, FindAllTAByModuleCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllTAByModuleCodeQuery, FindAllTAByModuleCodeQueryVariables>(FindAllTAByModuleCodeDocument, options);
        }
export type FindAllTAByModuleCodeQueryHookResult = ReturnType<typeof useFindAllTAByModuleCodeQuery>;
export type FindAllTAByModuleCodeLazyQueryHookResult = ReturnType<typeof useFindAllTAByModuleCodeLazyQuery>;
export type FindAllTAByModuleCodeQueryResult = Apollo.QueryResult<FindAllTAByModuleCodeQuery, FindAllTAByModuleCodeQueryVariables>;
export const UserProfileDocument = gql`
    query UserProfile {
  authProfile {
    id
    title
    name
    firstName
    lastName
    email
    permissions {
      role
    }
  }
}
    `;

/**
 * __useUserProfileQuery__
 *
 * To run a query within a React component, call `useUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserProfileQuery(baseOptions?: Apollo.QueryHookOptions<UserProfileQuery, UserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
      }
export function useUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserProfileQuery, UserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
        }
export type UserProfileQueryHookResult = ReturnType<typeof useUserProfileQuery>;
export type UserProfileLazyQueryHookResult = ReturnType<typeof useUserProfileLazyQuery>;
export type UserProfileQueryResult = Apollo.QueryResult<UserProfileQuery, UserProfileQueryVariables>;