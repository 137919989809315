import { IconDashboard as DashboardIcon } from '@tabler/icons';

import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as JobHuntingIconSvg } from '../assets/images/icons/JobHuntingIcon.svg';

export const IconVacancy = () => (
    <SvgIcon>
        <JobHuntingIconSvg width={24} height={24} strokeWidth={1.5} />
    </SvgIcon>
);

export const IconDashboard = () => <DashboardIcon width={24} height={24} strokeWidth={1.5} />;
