import PGTA from './pgta';
import ModuleLeader from './ml';
import AcademicLeader from './al';
import HR from './hr';
import { IMenuItem } from 'types/menu';
import { UserRole } from 'generated/graphql';

const menuItems: Record<UserRole, IMenuItem> = {
    PGTA,
    ModuleLeader,
    AcademicLeader,
    HR,
    TARecruiter: HR
};

export default menuItems;
