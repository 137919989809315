// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Chip, Grid, List, Typography } from '@mui/material';
import { FindAllNotificationsQuery } from 'generated/graphql';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

interface NotificationListProps {
    data: FindAllNotificationsQuery;
}
const NotificationList = ({ data }: NotificationListProps) => {
    const theme = useTheme();

    const chipSX = {
        height: 24,
        padding: '0 6px'
    };

    const chipWarningSX = {
        ...chipSX,
        color: theme.palette.warning.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light
    };

    const sortedData = [...data.findAllNotifications].sort((a, b) => {
        if (a.createTime > b.createTime) return -1;
        return 1;
    });

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {sortedData.map((notification, index) => (
                <ListItemWrapper key={index}>
                    <Grid container direction="column">
                        <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Typography variant="subtitle1" fontWeight="normal" color="black">
                                    {notification.subject}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">{notification.createTime.toISOString().slice(0, 10)}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} pb={1}>
                            <Typography variant="subtitle2">{notification.body}</Typography>
                        </Grid>
                        {!notification.read && (
                            <Grid item xs={12}>
                                <Grid item>
                                    <Chip size="small" label="New" sx={chipWarningSX} />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </ListItemWrapper>
            ))}
        </List>
    );
};

export default NotificationList;
