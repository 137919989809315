import { Vacancy } from 'generated/graphql';

export interface AppliedVacancy extends Vacancy {
    hours: number;
}

export const typesMap = {
    Timestamp: {
        serialize: (parsed: unknown): number | null => (parsed instanceof Date ? parsed.getTime() : null),
        parseValue: (raw: unknown): Date | null => {
            if (!raw) return null;
            if (typeof raw === 'number') {
                return new Date(Number(raw));
            }
            throw new Error('invalid value to parse Timestamp');
        }
    },
    Cursor: {
        serialize: (parsed: unknown): number | null => {
            // console.log('pass parameter cursor: ', parsed);
            if (typeof parsed === 'number') {
                return parsed;
            }
            return null;
        },
        parseValue: (raw: unknown): number | null => {
            // console.log('cursor', raw);
            if (!raw) return null;
            if (typeof raw === 'number') {
                return raw;
            }
            throw new Error('invalid value to parse Cursor');
        }
    }
};
