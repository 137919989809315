// types
import { ConfigProps } from 'types/config';

const isDev = process.env.NODE_ENV === 'development';

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
export const BASE_PATH = '';
export const BASE_URL = isDev ? `http://localhost:3001${BASE_PATH}` : `https://${process.env.REACT_APP_HOST}${BASE_PATH}`;
export const API_BASE_PATH = `${BASE_URL}/api`;
export const GRAPHQL_PATH = `${BASE_URL}/graphql`;
export const MODULE_IMPORT_PATH = `${API_BASE_PATH}/module/import-module-csv`;
export const MODULE_EXAMPLE_PATH = `${API_BASE_PATH}/module/download-module-example-csv`;
export const TA_IMPORT_PATH = `${API_BASE_PATH}/user/import-ta-csv`;
export const TA_EXAMPLE_PATH = `${API_BASE_PATH}/user/download-ta-example-csv`;
export const REDIRECT_OAUTH_PATH = `${API_BASE_PATH}/oauth/authorise`;

export const DASHBOARD_PATH = '/';

const config: ConfigProps = {
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: false,
    navType: 'light', // light, dark
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: false
};

export default config;

/**
 * Old example config for MSAL authentication library
 * No longer used now
 * Switched to UCL API instead
    export const msalConfig: Configuration = {
        auth: {
            clientId: '405c1b01-8c70-4ef5-9878-81dbb05d1c2f',
            authority: 'https://login.microsoftonline.com/1faf88fe-a998-4c5b-93c9-210a11d9a5c2/',
            redirectUri: BASE_URL,
            navigateToLoginRequestUrl: false
        },
        cache: {
            cacheLocation: 'localStorage', // This configures where your cache will be stored
            storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
        }
    };

    // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
    export const loginRequest = {
        scopes: []
    };

    // Add here the endpoints and scopes for the web API you would like to use.
    const apiConfig = {
        uri: BASE_URL,
        scopes: ['api://b8e14cfa-54fd-47dc-8a6b-9da760203a19/Read-Token_Email'] // e.g. ["scp1", "scp2"]
    };


    // Scopes you add here will be used to request a token from Azure AD to be used for accessing a protected resource.
    // To learn more about how to work with scopes and resources, see: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
    export const tokenRequest = {
        scopes: [...apiConfig.scopes]
    };
 */
