import Routes from 'routes';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import { ApolloProvider } from '@apollo/client';
import client from './apolloClient';
import { ConfirmDialogContextProvider } from 'ui-component/dialog/ConfirmationDialog';

const App = () => (
    <ThemeCustomization>
        <RTLLayout>
            <NavigationScroll>
                <ApolloProvider client={client}>
                    <AuthProvider>
                        <ConfirmDialogContextProvider>
                            <Routes />
                            <Snackbar />
                        </ConfirmDialogContextProvider>
                    </AuthProvider>
                </ApolloProvider>
            </NavigationScroll>
        </RTLLayout>
    </ThemeCustomization>
);

export default App;
