import React, { createContext, useEffect, useReducer } from 'react';
import { LOGIN, LOGOUT } from 'store/actions';
import { initialState, accountReducer } from 'store/accountReducer';
import Loader from 'ui-component/Loader';
import { JWTContextType } from 'types/auth';
import { REDIRECT_OAUTH_PATH } from 'config';
import { useApolloClient } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/slices/snackbar';
import {
    useLoginPGTAMutation,
    useLogoutPGTAMutation,
    UserProfileDocument,
    UserProfileQuery,
    UserProfileQueryVariables,
    useSendVerificationEmailMutation
} from 'generated/graphql';
import { useLocation, useNavigate } from 'react-router-dom';

export const setSession = (accessToken?: string | null) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
    } else {
        localStorage.removeItem('accessToken');
    }
};

const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const dispatcher = useDispatch();
    const client = useApolloClient();
    const navigate = useNavigate();
    const pathname = useLocation().pathname;

    useEffect(() => {
        checkUserAuth();
        // Although using dispatchers without declaring react hook dependency is an anti-pattern approach,
        // we disable lint here because loading hook 3 times which is caused by 3 dependencies' states change is unnecessary.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const [loginPGTA] = useLoginPGTAMutation();

    const [logoutPGTA] = useLogoutPGTAMutation();

    const [sendEmail] = useSendVerificationEmailMutation();

    async function handleUCLAPIRedirectPromise(): Promise<string | null> {
        const params: any = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop: any) => searchParams.get(prop)
        });

        // throw promise error when login is not successful
        if (params.success && params.success !== 'true') {
            let errorMessage: string;
            switch (params.errType) {
                case 'state_code_not_found':
                    errorMessage = 'Invalid state code from UCL API';
                    break;
                case 'state_code_expired':
                    errorMessage = 'SSO login session expired, please login again';
                    break;
                case 'err_user_denied':
                    errorMessage = 'SSO login cancelled';
                    break;
                case 'err_uclapi_down':
                    errorMessage = 'UCL API Service is down, please try again later';
                    break;
                case 'err_unauthorized':
                    errorMessage = 'You are not authorized to access this application, please contact system admin';
                    break;
                default:
                    errorMessage = 'Unknown error';
                    break;
            }
            throw Error(errorMessage);
        }
        if (params.success === 'true' && params.token) {
            return params.token;
        }
        return null;
    }

    async function checkUserAuth() {
        try {
            const token = await handleUCLAPIRedirectPromise();
            if (token) {
                setSession(token);
                navigate('/'); // clear url query parameters
            }
        } catch (err: any) {
            dispatch({
                type: LOGOUT,
                payload: {
                    isLoggedIn: false,
                    user: initialState.user
                }
            });
            dispatcher(
                openSnackbar({
                    open: true,
                    message: err.message,
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
            return;
        }

        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
            dispatch({
                type: LOGOUT,
                payload: {
                    isLoggedIn: false,
                    user: initialState.user
                }
            });
            return;
        }

        try {
            const queryResult = await client.query<UserProfileQuery, UserProfileQueryVariables>({
                query: UserProfileDocument
            });

            const isUCL = queryResult.data.authProfile!.email.endsWith('@ucl.ac.uk');
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    user: queryResult.data.authProfile!,
                    isUCL
                }
            });
        } catch (error) {
            dispatch({
                type: LOGOUT,
                payload: {
                    isLoggedIn: false,
                    user: initialState.user
                }
            });
            dispatcher(
                openSnackbar({
                    open: true,
                    message: 'Your session has expired, please sign in again',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
        }
    }

    async function emailPasswordLogin(email: string, password: string) {
        const result = await loginPGTA({ variables: { email, password } });

        if (result.errors) {
            dispatcher(
                openSnackbar({
                    open: true,
                    message: result.errors[0].message,
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
        }

        setSession(result.data?.loginPGTA.accessToken);
        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                isUCL: false,
                user: result.data?.loginPGTA.user!
            }
        });
    }

    // async function loginByMsal(resp: AuthenticationResult) {
    //     setSession(resp.accessToken);

    //     // try fetch user info after successful login
    //     const { data, error } = await client.query<UserProfileQuery, UserProfileQueryVariables>({
    //         query: UserProfileDocument
    //     });

    //     if (error) {
    //         dispatch({
    //             type: LOGOUT,
    //             payload: {
    //                 isLoggedIn: false,
    //                 user: initialState.user
    //             }
    //         });
    //         dispatcher(
    //             openSnackbar({
    //                 open: true,
    //                 message: `Login Unsuccessful: ${error.message}`,
    //                 variant: 'alert',
    //                 alert: {
    //                     color: 'error'
    //                 },
    //                 close: false
    //             })
    //         );
    //     }

    //     dispatch({
    //         type: LOGIN,
    //         payload: {
    //             isLoggedIn: true,
    //             isUCL: true,
    //             user: data.userProfile
    //         }
    //     });
    // }

    async function ssoLogin() {
        window.location.href = REDIRECT_OAUTH_PATH;
        // const request = {
        //     ...tokenRequest,
        //     account: accounts[0]
        // };

        // // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        // try {
        //     const result = await instance.acquireTokenSilent(request);
        //     await loginByMsal(result);
        // } catch {
        //     await instance.acquireTokenRedirect(request);
        // }
    }

    async function logout() {
        const result = await logoutPGTA();
        if (result.errors) {
            throw new Error(result.errors[0].message);
        }
        client.clearStore();
        setSession(null);
        dispatch({ type: LOGOUT });

        // if (state.isUCL) {
        //     try {
        //         await instance.logoutRedirect();
        //     } catch (err) {
        //         console.error(err);
        //     }
        // } else {
        //     dispatch({ type: LOGOUT });
        // }
    }

    async function resetPassword(newPassword: string, token: string) {
        throw new Error('not implemented');
    }

    async function sendVerificationEmail(email: string) {
        await sendEmail({ variables: { email } });
    }

    async function updateProfile() {
        throw new Error('not implemented');
    }

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider
            value={{
                ...state,
                emailPasswordLogin,
                ssoLogin,
                logout,
                resetPassword,
                sendVerificationEmail,
                updateProfile,
                checkUserAuth
            }}
        >
            {children}
        </JWTContext.Provider>
    );
};

export default JWTContext;
