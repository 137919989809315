import { combineReducers } from 'redux';
// persist reducer not used currently
// may added to feature in the future
// import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import menuReducer from './slices/menu';
import snackbarReducer from './slices/snackbar';

const reducer = combineReducers({
    snackbar: snackbarReducer,
    menu: menuReducer
});

export default reducer;
