import React, { createContext, useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, Link, useNavigate } from 'react-router-dom';
import { styled, useTheme, Theme } from '@mui/material/styles';
import { AppBar, Box, Container, CssBaseline, Toolbar, useMediaQuery, Tab, Tabs } from '@mui/material';
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import useConfig from 'hooks/useConfig';
import { drawerWidth } from 'store/constant';
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';
import { IconChevronRight } from '@tabler/icons';
import useAuth from 'hooks/useAuth';
import menuItems from 'menu-items';
import { UserRole } from 'generated/graphql';
import { BASE_PATH } from 'config';
import MainCard from 'ui-component/cards/MainCard';
import { IMenuItem } from 'types/menu';

import ModuleIcon from '@mui/icons-material/BookOutlined';
import { onLoadMyModules } from 'menu-items/al';

interface MainStyleProps {
    theme: Theme;
    open: boolean;
}

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }: MainStyleProps) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

export interface IMenuContext {
    /**
     * @property menuitems the app left hand side menu to be shared for menu, custom page and breadcrumbs
     */
    navigation: IMenuItem;

    /**
     * @params lazyMenuId an unique menu id to update specific menu in menuItems
     * This async function will fetch menu data from onLoad function, and set its children
     */
    updateLazyMenuChildren: (lazyMenuId: string, onLoad?: () => Promise<IMenuItem[]>) => Promise<void>;
}

const MenuContext = createContext<IMenuContext | null>(null);

export function useMenu(): IMenuContext {
    const context = useContext(MenuContext);
    if (!context) throw new Error('context must be use inside provider');
    return context;
}

export const MenuProvider = ({ children }: { children: React.ReactElement[] }) => {
    const { user } = useAuth();

    let menuData: IMenuItem;
    if (user.permissions.some((permission) => permission.role === UserRole.PGTA)) {
        menuData = menuItems.PGTA;
    } else if (user.permissions.some((permission) => permission.role === UserRole.AcademicLeader)) {
        menuData = menuItems.AcademicLeader;
        // Feat 2022.11.01 - What if user has both Module Leader and AcademicLeader role?
        if (!menuData.children?.some((child) => child.id === 'ac-ml-my-modules')) {
            if (user.permissions.some((permission) => permission.role === UserRole.ModuleLeader)) {
                menuData?.children?.unshift({
                    id: 'ac-ml-my-modules',
                    title: 'My Modules',
                    pageTitle: 'My Modules',
                    url: '/my-modules',
                    breadcrumbs: true,
                    icon: ModuleIcon,
                    type: 'collapse',
                    defaultOpen: true,
                    lazy: true,
                    onLoad: onLoadMyModules
                });
            }
        }
    } else if (user.permissions.some((permission) => permission.role === UserRole.ModuleLeader)) {
        menuData = menuItems.ModuleLeader;
    } else if (user.permissions.some((permission) => permission.role === UserRole.TARecruiter)) {
        menuData = menuItems.ModuleLeader;
    } else if (user.permissions.some((permission) => permission.role === UserRole.HR)) {
        menuData = menuItems.HR;
    } else {
        menuData = { id: 'default', type: 'item' };
    }

    const [navigation, setNavigation] = useState<IMenuItem>(menuData);

    if (menuData.id === 'default') return <p>Sorry, you role is not recognized</p>;

    const updateLazyMenuChildren = async (lazyMenuId: string, onLoad?: () => Promise<IMenuItem[]>) => {
        if (!navigation) throw Error('no active navigation');

        const updateMenu = async (navigationItem: IMenuItem): Promise<IMenuItem> => {
            let result;
            if (!navigationItem.children || (onLoad && navigationItem.id === lazyMenuId)) {
                if (navigationItem.id === lazyMenuId) {
                    if (onLoad) {
                        result = await onLoad();
                    } else {
                        if (!navigationItem.onLoad) {
                            throw Error('onLoad function is required for lazy menu, pass it either in menuItems or updateLazyMenuChildren');
                        }
                        result = await navigationItem.onLoad();
                    }
                    return { ...navigationItem, children: result };
                }
                return navigationItem;
            }
            const { children: navigationChildren, ...menuItem } = navigationItem;
            const data: IMenuItem[] = [];
            for (const child of navigationChildren) {
                // eslint-disable-next-line no-await-in-loop
                const childItem = await updateMenu(child);
                data.push(childItem);
            }
            return {
                ...menuItem,
                children: data
            };
        };

        const newNavigation = await updateMenu(navigation);
        setNavigation(newNavigation);
    };

    return <MenuContext.Provider value={{ navigation, updateLazyMenuChildren }}>{children}</MenuContext.Provider>;
};

const MainLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    const dispatch = useDispatch();

    const { drawerOpen } = useSelector((state) => state.menu);
    const { container } = useConfig();
    const { isLoggedIn } = useAuth();

    React.useEffect(() => {
        dispatch(openDrawer(!matchDownMd));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    if (!isLoggedIn) return <></>;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: drawerOpen ? theme.transitions.create('width') : 'none'
                }}
            >
                <Toolbar>
                    <Header />
                </Toolbar>
            </AppBar>

            <MenuProvider>
                {/* drawer */}
                <Sidebar />

                {/* main content */}
                <Main theme={theme} open={drawerOpen}>
                    {/* breadcrumb */}
                    {container && (
                        <Container maxWidth="lg">
                            <Breadcrumbs separator={IconChevronRight} icon title rightAlign />
                            <CustomPage />
                        </Container>
                    )}
                    {!container && (
                        <>
                            <Breadcrumbs separator={IconChevronRight} icon title rightAlign />
                            <CustomPage />
                        </>
                    )}
                </Main>
            </MenuProvider>
        </Box>
    );
};

const CustomPage = () => {
    let tabGroup: IMenuItem | null = null;
    const pathname = useLocation().pathname;
    const { navigation, updateLazyMenuChildren } = useMenu();

    const resolveGroup = (menu: IMenuItem) => {
        if (!menu.children) {
            if (menu.lazy) {
                updateLazyMenuChildren(menu.id);
                return;
            }
            throw Error('menu group must have children');
        }
        menu.children.forEach((item) => {
            if (item.type === 'collapse') {
                resolveGroup(item);
            } else if (item.type === 'tabGroup') {
                if (!item.url) throw Error('missing field `url` in `tabGroup` type');
                if (pathname.startsWith(BASE_PATH + item.url!)) {
                    tabGroup = item;
                }
            }
        });
    };

    navigation.children?.forEach((menu) => {
        if (menu.type === 'group' || menu.type === 'collapse') {
            resolveGroup(menu);
        } else if (menu.type === 'tabGroup') {
            if (!menu.url) throw Error('missing field `url` in `tabGroup` type');
            if (pathname.startsWith(BASE_PATH + menu.url!)) {
                tabGroup = menu;
            }
        }
    });

    if (!tabGroup) {
        return <Outlet />;
    }

    return <ResolveTabPage tabNavigation={tabGroup} />;
};

const ResolveTabPage = ({ tabNavigation }: { tabNavigation: IMenuItem }) => {
    const pathname = useLocation().pathname;
    const navigate = useNavigate();

    useEffect(() => {
        if (!tabNavigation.children?.some((child) => pathname === child.url)) {
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    if (tabNavigation.type !== 'tabGroup') throw Error('navigation passed in must be `tabGroup` type');
    if (tabNavigation.children?.length === 0) throw Error('you need to have at least one element for `tabGroup` component');

    let pageIndex = 0;
    tabNavigation.children?.forEach((child, index) => {
        if (!child.url) throw Error('navigation must have a url');
        if (document.location.pathname.startsWith(child.url!)) {
            pageIndex = index;
        }
    });

    const a11yProps = (index: number) => ({
        id: `application-tab-${index}`,
        'aria-controls': `application-tabpanel-${index}`
    });

    const tabStyle = {
        mb: 3,
        minHeight: 'auto',
        '& button': {
            minWidth: 100
        },
        '& a': {
            minHeight: 'auto',
            minWidth: 10,
            py: 1.5,
            px: 1,
            mr: 2.25,
            color: 'grey.600'
        },
        '& a.Mui-selected': {
            color: 'primary.main'
        }
    };

    return (
        <MainCard>
            <Tabs value={pageIndex} indicatorColor="primary" sx={tabStyle} aria-label="Application Tabs" variant="scrollable">
                {tabNavigation.children?.map((tab, index) => {
                    if (!tab.url || !tab.title) throw Error('url and title property must exist in tab page');
                    return <Tab component={Link} to={tab.url} label={tab.title} key={index} {...a11yProps(index)} />;
                })}
            </Tabs>
            <Outlet />
        </MainCard>
    );
};

export default MainLayout;
