import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { RouteObject } from 'react-router-dom';
import { lazy } from 'react';

const PendingInitial = Loadable(lazy(() => import('views/management/budget/Pending/Initial')));
const PendingExtra = Loadable(lazy(() => import('views/management/budget/Pending/Extra')));
const ApprovedInitial = Loadable(lazy(() => import('views/management/budget/Approved/Initial')));
const ApprovedExtra = Loadable(lazy(() => import('views/management/budget/Approved/Extra')));
const Module = Loadable(lazy(() => import('views/management/module')));
const TA = Loadable(lazy(() => import('views/management/ta')));
const Admin = Loadable(lazy(() => import('views/management/admin-team/Team')));
const FAQ = Loadable(lazy(() => import('views/management/faq/Faq')));
const Setting = Loadable(lazy(() => import('views/management/settings/Setting')));
const ModuleProfilePage = Loadable(lazy(() => import('../views/module/profile')));
const ModuleApprovedAppPage = Loadable(lazy(() => import('../views/module/approved')));
const ModulePendingAppPage = Loadable(lazy(() => import('../views/module/pending')));
const ModuleInitialBudgetPage = Loadable(lazy(() => import('../views/module/initial')));
const ModuleExtraBudgetPage = Loadable(lazy(() => import('../views/module/extra')));
const ModuleVacancyPage = Loadable(lazy(() => import('../views/module/vacancies')));
const ModuleTeamPage = Loadable(lazy(() => import('../views/module/team')));
const ModuleManagePage = Loadable(lazy(() => import('../views/module')));
const TemplatePage = Loadable(lazy(() => import('../views/management/templates')));

const MainRoutes: RouteObject = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: 'budget-management',
            children: [
                {
                    path: 'pending',
                    children: [
                        {
                            path: 'initial',
                            element: <PendingInitial />
                        },
                        {
                            path: 'extra',
                            element: <PendingExtra />
                        }
                    ]
                },
                {
                    path: 'approved',
                    children: [
                        {
                            path: 'initial',
                            element: <ApprovedInitial />
                        },
                        {
                            path: 'extra',
                            element: <ApprovedExtra />
                        }
                    ]
                }
            ]
        },
        {
            path: 'module-management',
            element: <Module />
        },
        {
            path: 'ta-management',
            element: <TA />
        },
        {
            path: 'admin-team',
            element: <Admin />
        },
        {
            path: 'faq-management',
            element: <FAQ />
        },
        {
            path: 'settings',
            element: <Setting />
        },
        // Feat 2022.10.31 - Academic Leader can manage module now
        {
            path: '/module/:moduleCode',
            children: [
                {
                    path: 'profile',
                    element: <ModuleProfilePage />
                },
                {
                    path: 'approved',
                    element: <ModuleApprovedAppPage />
                },
                {
                    path: 'pending',
                    element: <ModulePendingAppPage />
                },
                {
                    path: 'initial',
                    element: <ModuleInitialBudgetPage />
                },
                {
                    path: 'extra',
                    element: <ModuleExtraBudgetPage />
                },
                {
                    path: 'vacancies',
                    element: <ModuleVacancyPage />
                },
                {
                    path: 'team',
                    element: <ModuleTeamPage />
                }
            ]
        },
        {
            path: 'my-modules',
            element: <ModuleManagePage />
        },
        {
            path: 'templates',
            element: <TemplatePage />
        }
    ]
};

export default MainRoutes;
