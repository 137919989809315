import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import NavItem from '../NavItem';
import useConfig from 'hooks/useConfig';
import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import { IMenuItem } from 'types/menu';
import { useMenu } from 'layout/MainLayout';
import { useDispatch } from 'store';
import { activeItem } from 'store/slices/menu';

interface NavCollapseProps {
    menu: IMenuItem;
    level: number;
}

const NavCollapse = ({ menu, level }: NavCollapseProps) => {
    const theme = useTheme();
    const { borderRadius } = useConfig();
    const { updateLazyMenuChildren } = useMenu();

    const [open, setOpen] = useState(false || menu.defaultOpen);
    const [selected, setSelected] = useState<string | null | undefined>(null);
    const componentMounted = useRef(true);

    const [lazyMenuLoading, setLazyMenuLoading] = useState(false);
    const [lazyMenuErrorMsg, setLazyMenuErrorMsg] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleClick = async () => {
        setOpen(!open || menu.defaultOpen);
        setSelected(!open || menu.defaultOpen ? menu.id : null);

        if (menu.defaultOpen) {
            dispatch(activeItem([menu.id]));
        }

        if (menu.url) {
            navigate(menu.url);
        }
    };

    useEffect(() => {
        // if user click the menu then load them
        // check menu state, reload if in original state
        const loadMenu = async () => {
            if (menu.lazy && !menu.children) {
                if (!menu.onLoad) throw Error('onLoad function should be defined for lazy menu');
                setLazyMenuLoading(true);
                try {
                    await updateLazyMenuChildren(menu.id);
                } catch (error: any) {
                    componentMounted.current && setLazyMenuErrorMsg(error.message);
                }
                componentMounted.current && setLazyMenuLoading(false);
            }
        };
        loadMenu();

        return () => {
            componentMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const { pathname } = useLocation();
    const checkOpenForParent = (child: any, id?: string) => {
        child.forEach((item: any) => {
            if (item.url === pathname) {
                setOpen(true);
                setSelected(id);
            }
        });
    };

    // menu collapse for sub-levels
    useEffect(() => {
        menu.children?.forEach((item: any) => {
            if (item.children?.length) {
                checkOpenForParent(item.children, menu.id);
            }
            if (item.url === pathname) {
                setOpen(true);
                setSelected(menu.id);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, menu.children]);

    // menu collapse & item
    const menus = menu.children?.map((item) => {
        switch (item.type) {
            case 'collapse':
                return <NavCollapse key={item.id} menu={item} level={level + 1} />;
            case 'item':
                return <NavItem key={item.id} item={item} level={level + 1} />;
            case 'tabGroup':
                return <NavItem key={item.id} item={item} level={level + 1} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    const Icon = menu.icon!;
    const menuIcon = menu.icon ? (
        <Icon strokeWidth={1.5} size="1.3rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: selected === menu.id ? 8 : 6,
                height: selected === menu.id ? 8 : 6
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    return (
        <>
            <ListItemButton
                sx={{
                    borderRadius: `${borderRadius}px`,
                    mb: 0.5,
                    alignItems: 'flex-start',
                    backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                    py: level > 1 ? 1 : 1.25,
                    pl: `${level * 24}px`
                }}
                selected={!menu.defaultOpen && selected === menu.id}
                onClick={handleClick}
            >
                <ListItemIcon sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 36 }}>{menuIcon}</ListItemIcon>
                <ListItemText
                    primary={
                        <Typography variant={selected === menu.id ? 'h5' : 'body1'} color="inherit" sx={{ my: 'auto' }}>
                            {menu.title}
                        </Typography>
                    }
                    secondary={
                        menu.caption && (
                            <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                {menu.caption}
                            </Typography>
                        )
                    }
                />
                {!menu.defaultOpen && open && (
                    <IconChevronUp stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                )}
                {!menu.defaultOpen && !open && (
                    <IconChevronDown stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                )}
            </ListItemButton>
            {!menu.hidden && (
                <Collapse in={open} timeout="auto" unmountOnExit>
                    {open && lazyMenuLoading && <p>Menu Loading</p>}
                    {open && lazyMenuErrorMsg !== '' && <p>Error {lazyMenuErrorMsg}</p>}
                    {open && menu.children && menu.children.length > 0 && (
                        <List
                            component="div"
                            disablePadding
                            sx={{
                                position: 'relative',
                                '&:after': {
                                    content: "''",
                                    position: 'absolute',
                                    left: '32px',
                                    top: 0,
                                    height: '100%',
                                    width: '1px',
                                    opacity: theme.palette.mode === 'dark' ? 0.2 : 1,
                                    background: theme.palette.mode === 'dark' ? theme.palette.dark.light : theme.palette.primary.light
                                }
                            }}
                        >
                            {menus}
                        </List>
                    )}
                </Collapse>
            )}
        </>
    );
};

export default NavCollapse;
