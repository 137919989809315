import SchoolIcon from '@mui/icons-material/SchoolOutlined';
import PeopleIcon from '@mui/icons-material/PeopleOutlined';
import FAQsIcon from '@mui/icons-material/QuestionAnswerOutlined';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SettingsIcon from '@mui/icons-material/Settings';
import { IconReportMoney, IconTemplate } from '@tabler/icons';
import { IMenuItem } from 'types/menu';
import { IconDashboard } from './menu-icons';
import client from 'apolloClient';
import {
    FindAllModulesWithPaginationDocument,
    FindAllModulesWithPaginationQuery,
    FindAllModulesWithPaginationQueryVariables,
    MyModuleMenusDocument,
    MyModuleMenusQuery,
    MyModuleMenusQueryVariables
} from 'generated/graphql';

const BudgetIcon = () => <IconReportMoney width={24} height={24} strokeWidth={2} />;
const TemplateIcon = () => <IconTemplate width={24} height={24} strokeWidth={2} />;

export interface IModuleMenuItem {
    code: string;
    title: string;
}

export const mapModuleToMenuItem = (moduleList: IModuleMenuItem[]): IMenuItem[] =>
    moduleList.map((module) => {
        const children: IMenuItem[] = [
            {
                id: `my-module-${module.code}-approved-application`,
                title: 'Approved Applications',
                type: 'item',
                url: `/module/${module.code}/approved`
            },
            {
                id: `my-module-${module.code}-pending-application`,
                title: 'Pending Applications',
                type: 'item',
                url: `/module/${module.code}/pending`
            },
            {
                id: `my-module-${module.code}-profile`,
                title: 'Module Profile',
                type: 'item',
                url: `/module/${module.code}/profile`
            },
            {
                id: `my-module-${module.code}-initial-budget`,
                title: 'Initial Budget',
                type: 'item',
                url: `/module/${module.code}/initial`
            },
            {
                id: `my-module-${module.code}-extra-budget`,
                title: 'Extra Budget',
                type: 'item',
                url: `/module/${module.code}/extra`
            },
            {
                id: `my-module-${module.code}-vacancies`,
                title: 'Vacancy',
                type: 'item',
                url: `/module/${module.code}/vacancies`
            },
            {
                id: `my-module-${module.code}-team`,
                title: 'Team',
                type: 'item',
                url: `/module/${module.code}/team`
            }
        ];
        return {
            id: `my-module-${module.code}`,
            title: module.code,
            pageTitle: `${module.code} ${module.title}`,
            type: 'tabGroup',
            url: `/module/${module.code}`,
            defaultTabUrl: `/module/${module.code}/approved`,
            breadcrumbs: true,
            children
        };
    });

export const onLoadAllModules = async (): Promise<IMenuItem[]> => {
    const queryResult = await client.query<FindAllModulesWithPaginationQuery, FindAllModulesWithPaginationQueryVariables>({
        query: FindAllModulesWithPaginationDocument,
        variables: {
            after: 0,
            first: 5
        }
    });
    return mapModuleToMenuItem(queryResult.data.findAllModulesWithPagination.data);
};

export const onLoadMyModules = async (): Promise<IMenuItem[]> => {
    const queryResult = await client.query<MyModuleMenusQuery, MyModuleMenusQueryVariables>({
        query: MyModuleMenusDocument
    });
    return mapModuleToMenuItem(queryResult.data.myModules);
};

const al: IMenuItem = {
    id: 'ac-navigation',
    type: 'group',
    children: [
        {
            id: 'al-overview',
            title: 'Statistics',
            type: 'item',
            url: '/overview',
            icon: IconDashboard,
            breadcrumbs: true
        },
        {
            id: 'module-budget-management',
            title: 'Module Budgets',
            type: 'collapse',
            icon: BudgetIcon,
            children: [
                {
                    id: 'module-budget-management-pending',
                    title: 'Pending',
                    pageTitle: 'Review Pending Budgets',
                    type: 'tabGroup',
                    url: '/budget-management/pending',
                    defaultTabUrl: '/budget-management/pending/initial',
                    breadcrumbs: true,
                    children: [
                        {
                            id: 'module-budget-management-pending-initial',
                            title: 'Initial Budgets',
                            type: 'item',
                            url: '/budget-management/pending/initial'
                        },
                        {
                            id: 'module-budget-management-pending-extra',
                            title: 'Extra Budgets',
                            type: 'item',
                            url: '/budget-management/pending/extra'
                        }
                    ]
                },
                {
                    id: 'module-budget-management-approved',
                    title: 'Approved',
                    pageTitle: 'Approved Budgets',
                    type: 'tabGroup',
                    url: '/budget-management/approved',
                    defaultTabUrl: '/budget-management/approved/initial',
                    breadcrumbs: true,
                    children: [
                        {
                            id: 'module-budget-management-approved-initial',
                            title: 'Initial Budgets',
                            type: 'item',
                            url: '/budget-management/approved/initial'
                        },
                        {
                            id: 'module-budget-management-approved-extra',
                            title: 'Extra Budgets',
                            type: 'item',
                            url: '/budget-management/approved/extra'
                        }
                    ]
                }
            ]
        },
        {
            id: 'ac-module-management',
            title: 'All Modules',
            pageTitle: 'All Modules',
            url: '/module-management',
            breadcrumbs: true,
            icon: SchoolIcon,
            type: 'collapse',
            lazy: true,
            hidden: true,
            defaultOpen: true,
            onLoad: onLoadAllModules
        },
        {
            id: 'ac-ta-management',
            title: 'TA Management',
            type: 'item',
            url: '/ta-management',
            breadcrumbs: true,
            icon: PeopleIcon
        },
        {
            id: 'ac-admin-team',
            title: 'Admin Team',
            type: 'item',
            url: '/admin-team',
            breadcrumbs: true,
            icon: AdminPanelSettingsIcon
        },
        {
            id: 'ac-faq-management',
            title: 'FAQs',
            type: 'item',
            url: '/faq-management',
            breadcrumbs: true,
            icon: FAQsIcon
        },
        {
            id: 'ac-settings',
            title: 'Settings',
            type: 'item',
            url: '/settings',
            breadcrumbs: true,
            icon: SettingsIcon
        },
        {
            id: 'ac-email-template',
            title: 'Email Templates',
            type: 'item',
            url: '/templates',
            breadcrumbs: true,
            icon: TemplateIcon
        }
    ]
};

export default al;
