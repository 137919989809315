import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { createContext, useContext, useRef, useState } from 'react';

export interface ConfirmDialogContextType {
    showConfirm: (message?: string, title?: string) => Promise<boolean>;
}

interface ConfirmDialogContextProviderProps {
    children: React.ReactNode;
}

const ConfirmDialogContext = createContext<ConfirmDialogContextType>({} as ConfirmDialogContextType);

export const ConfirmDialogContextProvider: React.FC<ConfirmDialogContextProviderProps> = ({ children }) => {
    const defaultContent = {
        title: 'Confirmation',
        message: 'Do you really want to do so?'
    };
    const [open, setOpen] = useState(false);
    const [content, setContent] = useState(defaultContent);
    const resolver = useRef<(value: boolean) => void>();

    const showConfirm = async (message?: string, title?: string): Promise<boolean> => {
        setOpen(true);
        setContent({
            title: title || defaultContent.title,
            message: message || defaultContent.message
        });
        return new Promise((resolve) => {
            resolver.current = resolve;
        });
    };

    const handleOK = () => {
        resolver.current && resolver.current(true);
        setOpen(false);
    };

    const handleCancel = () => {
        resolver.current && resolver.current(false);
        setOpen(false);
    };

    return (
        <ConfirmDialogContext.Provider value={{ showConfirm }}>
            {children}

            {open && (
                <Dialog
                    open={open}
                    onClose={handleCancel}
                    fullWidth
                    maxWidth="xs"
                    aria-labelledby="confirmation-dialog-title"
                    aria-describedby="confirmation-dialog-description"
                    sx={{ p: 3 }}
                >
                    <>
                        <DialogTitle id="confirmation-dialog-title">{content.title}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="confirmation-dialog-description">
                                <Typography variant="body2" component="span">
                                    {content.message}
                                </Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ pr: 2.5 }}>
                            <Button onClick={handleCancel} variant="outlined" autoFocus>
                                Cancel
                            </Button>
                            <Button variant="contained" onClick={handleOK}>
                                Confirm
                            </Button>
                        </DialogActions>
                    </>
                </Dialog>
            )}
        </ConfirmDialogContext.Provider>
    );
};

export const useConfirmDialogContext = (): ConfirmDialogContextType => useContext(ConfirmDialogContext);
