import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, Card, Divider, Grid, Typography } from '@mui/material';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { BASE_PATH } from 'config';
import { gridSpacing } from 'store/constant';
import { IconTallymark1 } from '@tabler/icons';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import HomeIcon from '@mui/icons-material/Home';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import { NavItemType, OverrideIcon } from 'types';
import { IMenuItem } from 'types/menu';
import { useMenu } from 'layout/MainLayout';

const linkSX = {
    display: 'flex',
    color: 'grey.900',
    textDecoration: 'none',
    alignContent: 'center',
    alignItems: 'center'
};

interface BreadCrumbSxProps extends React.CSSProperties {
    mb?: string;
    bgcolor?: string;
}

interface BreadCrumbsProps {
    card?: boolean;
    divider?: boolean;
    icon?: boolean;
    icons?: boolean;
    maxItems?: number;
    rightAlign?: boolean;
    separator?: OverrideIcon;
    title?: boolean;
    titleBottom?: boolean;
    sx?: BreadCrumbSxProps;
}

const Breadcrumbs = ({ card, divider, icon, icons, maxItems, rightAlign, separator, title, titleBottom, ...others }: BreadCrumbsProps) => {
    const theme = useTheme();
    const { navigation } = useMenu();

    const iconStyle = {
        marginRight: theme.spacing(0.75),
        marginTop: `-${theme.spacing(0.25)}`,
        width: '16px',
        height: '16px',
        color: theme.palette.secondary.main
    };

    const [main, setMain] = useState<NavItemType | undefined>();
    const [item, setItem] = useState<NavItemType>();
    const [needRendering, setNeedRendering] = useState(true);
    const pathname = useLocation().pathname;

    useEffect(() => {
        const resolveItemOrTabGroup = (father: IMenuItem, child: IMenuItem) => {
            const currentPath = BASE_PATH + child.url;
            const matchCurrentPath = pathname.startsWith(currentPath);

            if (!matchCurrentPath) {
                resolveCollapse(child);
            } else {
                setMain(father);
                setItem(child);
                setNeedRendering(!!child.breadcrumbs);
            }
        };

        const resolveCollapse = (menu: IMenuItem) => {
            if (!menu.children) return;

            const currentPath = BASE_PATH + menu.url;
            const matchCurrentPath = pathname === currentPath;

            if (!matchCurrentPath) {
                menu.children.forEach((child) => {
                    if (child.type === 'collapse') {
                        resolveCollapse(child);
                    } else if (child.type === 'item' || child.type === 'tabGroup') {
                        resolveItemOrTabGroup(menu, child);
                    }
                });
            } else {
                setMain(menu);
                setItem(menu);
                setNeedRendering(!!menu.breadcrumbs);
            }
        };

        navigation.children?.forEach((menu: IMenuItem, index: number) => {
            if (menu.type === 'group' || menu.type === 'collapse') {
                resolveCollapse(menu);
            } else if (menu.type === 'item' || menu.type === 'tabGroup') {
                resolveItemOrTabGroup(menu, menu);
            }
        });
    }, [pathname, navigation, needRendering]);

    // item separator
    const SeparatorIcon = separator!;
    const separatorIcon = separator ? <SeparatorIcon stroke={1.5} size="16px" /> : <IconTallymark1 stroke={1.5} size="16px" />;

    let mainContent = <></>;
    let itemContent = <></>;
    let breadcrumbContent = <></>;
    let itemTitle: NavItemType['title'] = '';
    let CollapseIcon;
    let ItemIcon;

    if (!needRendering || pathname === '/') return <></>;

    // collapse item
    if (main) {
        CollapseIcon = main.icon ? main.icon : AccountTreeTwoToneIcon;
        mainContent = (
            <Typography component={Link} to="#" variant="subtitle1" sx={linkSX}>
                {/** Add extra icons on path */}
                {icons && <CollapseIcon style={iconStyle} />}
                {/** The 1st level path title */}
                {main.title}
            </Typography>
        );
    }

    // items
    if (item) {
        // The trailing path title (default last element in the list)
        itemTitle = item.title;

        ItemIcon = item.icon ? item.icon : AccountTreeTwoToneIcon;
        itemContent = (
            <Typography
                variant="subtitle1"
                sx={{
                    display: 'flex',
                    textDecoration: 'none',
                    alignContent: 'center',
                    alignItems: 'center',
                    color: 'grey.500'
                }}
            >
                {icons && <ItemIcon style={iconStyle} />}
                {itemTitle}
            </Typography>
        );

        // main
        breadcrumbContent = (
            <Card
                sx={{
                    marginBottom: card === false ? 0 : theme.spacing(gridSpacing),
                    border: card === false ? 'none' : '1px solid',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.primary[200] + 75,
                    background: card === false ? 'transparent' : theme.palette.background.default
                }}
                {...others}
            >
                <Box sx={{ p: 2, pl: card === false ? 0 : 2 }}>
                    <Grid
                        container
                        direction={rightAlign ? 'row' : 'column'}
                        justifyContent={rightAlign ? 'space-between' : 'flex-start'}
                        alignItems={rightAlign ? 'center' : 'flex-start'}
                        spacing={1}
                    >
                        {title && !titleBottom && (
                            <Grid item>
                                <Typography variant="h3" sx={{ fontWeight: 500 }}>
                                    {/** The real page title */}
                                    {item.pageTitle ? item.pageTitle : item.title}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item>
                            {/** Generate real side breadcrumbs, only two level? */}
                            <MuiBreadcrumbs
                                sx={{ '& .MuiBreadcrumbs-separator': { width: 16, ml: 1.25, mr: 1.25 } }}
                                aria-label="breadcrumb"
                                maxItems={maxItems || 8}
                                separator={separatorIcon}
                            >
                                <Typography component={Link} to="/" color="inherit" variant="subtitle1" sx={linkSX}>
                                    {icons && <HomeTwoToneIcon sx={iconStyle} />}
                                    {icon && <HomeIcon sx={{ ...iconStyle, mr: 0 }} />}
                                    {!icon && 'Dashboard'}
                                </Typography>
                                {mainContent}
                                {main !== item && itemContent}
                            </MuiBreadcrumbs>
                        </Grid>
                        {/** Just reverse the position of page title and breadcrumbs */}
                        {title && titleBottom && (
                            <Grid item>
                                <Typography variant="h3" sx={{ fontWeight: 500 }}>
                                    {item.pageTitle ? item.pageTitle : item.title}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </Box>
                {card === false && divider !== false && <Divider sx={{ borderColor: theme.palette.primary.main, mb: gridSpacing }} />}
            </Card>
        );
    }

    return breadcrumbContent;
};

export default Breadcrumbs;
