import { useContext } from 'react';

// auth provider
import AuthContext from 'contexts/JWTContext';
import { JWTContextType } from 'types/auth';

// ==============================|| AUTH HOOKS ||============================== //

export default function useAuth(): JWTContextType {
    const context = useContext(AuthContext);

    if (!context) throw new Error('context must be use inside provider');

    return context;
}
