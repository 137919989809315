import { ApolloClient, createHttpLink, InMemoryCache, from, DefaultOptions } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { relayStylePagination } from '@apollo/client/utilities';
import { TypedTypePolicies } from 'generated/type-policies';
import { withScalars } from 'apollo-link-scalars';
import introspectionResult from './graphql.schema.json';
import { buildClientSchema, IntrospectionQuery } from 'graphql';
import { typesMap } from './typeMaps';
import { setSession } from 'contexts/JWTContext';
import { GRAPHQL_PATH } from 'config';

const httpLink = createHttpLink({
    uri: GRAPHQL_PATH
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('accessToken');

    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : ''
        }
    };
});

const errorLink = onError(({ graphQLErrors, networkError, response }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) => {
            if (message === 'Unauthorized' && !window.location.href.includes('/auth')) {
                setSession(null);
                // eslint-disable-next-line no-restricted-globals
                location.pathname = '/auth/login';
            }
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        });

    if (networkError) {
        console.log(`[Network error]: ${networkError}`);
    }
});

const defaultOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: 'cache-and-network'
    },
    query: {
        fetchPolicy: 'cache-first'
    },
    mutate: {
        fetchPolicy: 'no-cache'
    }
};

const typePolicies: TypedTypePolicies = {
    Query: {
        fields: {
            vacancies: relayStylePagination()
        }
    }
};

const schema = buildClientSchema(introspectionResult as unknown as IntrospectionQuery);

const additiveLink = from([errorLink, authLink, withScalars({ typesMap, schema }), httpLink]);

const client = new ApolloClient({
    link: additiveLink,
    cache: new InMemoryCache({ addTypename: false, typePolicies }),
    defaultOptions
});

export default client;
