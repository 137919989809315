import { IMenuItem } from 'types/menu';
import IconJob from '@mui/icons-material/WorkOutline';
import IconApproval from '@mui/icons-material/ApprovalOutlined';
import IconSchool from '@mui/icons-material/SchoolOutlined';
import IconPeople from '@mui/icons-material/PeopleOutlined';
import { IconDashboard } from './menu-icons';

const hr: IMenuItem = {
    id: 'hr-navigation',
    type: 'group',
    children: [
        {
            id: 'hr-statistics',
            title: 'Statistics',
            type: 'item',
            url: '/overview',
            icon: IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'rtw-review',
            title: 'RTW Review',
            type: 'collapse',
            icon: IconJob,
            children: [
                {
                    id: 'rtw-review-pending',
                    title: 'Pending',
                    pageTitle: 'Review Pending RTW',
                    type: 'item',
                    url: '/rtw-review/pending',
                    breadcrumbs: true
                },
                {
                    id: 'rtw-review-checked',
                    title: 'Checked',
                    pageTitle: 'Checked RTW',
                    type: 'item',
                    url: '/rtw-review/checked',
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'contract-information',
            title: 'Contract',
            type: 'collapse',
            icon: IconApproval,
            children: [
                {
                    id: 'contract-information-pending',
                    title: 'Pending',
                    pageTitle: 'Review Pending Contract',
                    type: 'item',
                    url: '/contract-information/pending',
                    breadcrumbs: true
                },
                {
                    id: 'contract-information-issued',
                    title: 'Issued',
                    pageTitle: 'Issued Contract',
                    type: 'item',
                    url: '/contract-information/issued',
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'module-information',
            title: 'Module Information',
            type: 'item',
            icon: IconSchool,
            url: '/module-information',
            breadcrumbs: true
        },
        {
            id: 'ta-information',
            title: 'TA Information',
            type: 'item',
            icon: IconPeople,
            url: '/ta-information',
            breadcrumbs: true
        }
    ]
};

export default hr;
