import { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Avatar, Badge, Box, Button, Chip, ClickAwayListener, Grid, Paper, Popper, Stack, Typography, useMediaQuery } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import NotificationList from './NotificationList';
import { IconBell } from '@tabler/icons';
import { useFindAllNotificationsQuery, useReadAllNotificationMutation } from 'generated/graphql';

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = useState(false);
    /**
     * anchorRef is used on different components and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef<any>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const { data, error, loading, refetch } = useFindAllNotificationsQuery();
    const [readAll] = useReadAllNotificationMutation();
    let unreadCounts = 0;
    if (data?.findAllNotifications) {
        unreadCounts = data.findAllNotifications.filter((notification) => !notification.read).length;
    }

    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <Badge badgeContent={unreadCounts} color="warning">
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                            color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                            '&[aria-controls="menu-list-grow"],&:hover': {
                                background: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                                color: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light
                            }
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        color="inherit"
                    >
                        <IconBell stroke={1.5} size="1.3rem" />
                    </Avatar>
                </Badge>
            </Box>

            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 5 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                            <Paper>
                                {open && (
                                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                        {loading && <p>Loading...</p>}
                                        {(!data?.findAllNotifications || error) && (
                                            <p>
                                                <p>Error: {error?.message}</p>
                                                <Button variant="contained" onClick={() => refetch()}>
                                                    Reload
                                                </Button>
                                            </p>
                                        )}
                                        {data && data.findAllNotifications.length === 0 && (
                                            <Grid container justifyContent="center" alignItems="center">
                                                <Typography variant="body1">You have no notifications</Typography>
                                            </Grid>
                                        )}
                                        {data && data.findAllNotifications.length > 0 && (
                                            <Grid container direction="column" spacing={2}>
                                                <Grid item xs={12}>
                                                    <Grid
                                                        container
                                                        alignItems="center"
                                                        justifyContent="space-between"
                                                        sx={{ pt: 2, px: 2 }}
                                                    >
                                                        <Grid item>
                                                            <Stack direction="row" spacing={2}>
                                                                <Typography variant="subtitle1">All Notification</Typography>
                                                                {unreadCounts > 0 && (
                                                                    <Chip
                                                                        size="small"
                                                                        label={unreadCounts}
                                                                        sx={{
                                                                            color: theme.palette.background.default,
                                                                            bgcolor: theme.palette.warning.dark
                                                                        }}
                                                                    />
                                                                )}
                                                            </Stack>
                                                        </Grid>
                                                        {unreadCounts > 0 && (
                                                            <Grid item>
                                                                <Button
                                                                    variant="text"
                                                                    color="primary"
                                                                    size="small"
                                                                    onClick={async () => {
                                                                        try {
                                                                            await readAll();
                                                                            refetch();
                                                                        } catch {
                                                                            console.log('cannot read all');
                                                                        }
                                                                    }}
                                                                >
                                                                    Mark as all read
                                                                </Button>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <PerfectScrollbar
                                                        style={{ height: '100%', maxHeight: 'calc(100vh - 205px)', overflowX: 'hidden' }}
                                                    >
                                                        <NotificationList data={data} />
                                                    </PerfectScrollbar>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </MainCard>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default NotificationSection;
