// action - state management
import { LOGIN, LOGOUT } from './actions';
import { InitialLoginContextProps } from 'types/auth';

// ==============================|| ACCOUNT REDUCER ||============================== //

interface AccountReducerActionProps {
    type: string;
    payload?: InitialLoginContextProps;
}

export const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isUCL: true,
    isInitialized: false,
    user: {
        id: 0,
        firstName: '',
        lastName: '',
        name: 'Error, User Not Signed In',
        email: 'Error, User Not Signed In',
        permissions: []
    }
};

export const accountReducer = (state = initialState, action: AccountReducerActionProps): InitialLoginContextProps => {
    switch (action.type) {
        case LOGIN: {
            const { user, isUCL } = action.payload!;
            return {
                isUCL,
                isLoggedIn: true,
                isInitialized: true,
                user
            };
        }
        case LOGOUT: {
            return {
                isInitialized: true,
                isLoggedIn: false,
                user: state.user
            };
        }
        default: {
            return { ...state };
        }
    }
};
