import { Typography } from '@mui/material';
import NavGroup from './NavGroup';
import { useMenu } from 'layout/MainLayout';
import NavItem from './NavItem';
import NavCollapse from './NavCollapse';

const MenuList = () => {
    const { navigation } = useMenu();

    switch (navigation.type) {
        case 'item':
            return <NavItem key={navigation.id} item={navigation} level={0} />;
        case 'collapse':
            return <NavCollapse key={navigation.id} menu={navigation} level={0} />;
        case 'group':
            return <NavGroup key={navigation.id} item={navigation} level={0} />;
        case 'tabGroup':
            return <NavItem key={navigation.id} item={navigation} level={0} />;
        default:
            return (
                <Typography key={navigation.id} variant="h6" color="error" align="center">
                    Menu Items Error
                </Typography>
            );
    }
};

export default MenuList;
