import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link } from '@mui/material';

import { DASHBOARD_PATH } from 'config';
import UCLLogo from '../../../assets/images/auth/UCLLogoUnofficial.png';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <Link component={RouterLink} to={DASHBOARD_PATH}>
        <img src={UCLLogo} alt="UCL Logo" style={{ width: '60%', height: 'auto' }} />
    </Link>
);

export default LogoSection;
