import { lazy } from 'react';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { RouteObject } from 'react-router-dom';

const ModuleIndexPage = Loadable(lazy(() => import('../views/module/index')));
const ModuleProfilePage = Loadable(lazy(() => import('../views/module/profile')));
const ModuleApprovedAppPage = Loadable(lazy(() => import('../views/module/approved')));
const ModulePendingAppPage = Loadable(lazy(() => import('../views/module/pending')));
const ModuleInitialBudgetPage = Loadable(lazy(() => import('../views/module/initial')));
const ModuleExtraBudgetPage = Loadable(lazy(() => import('../views/module/extra')));
const ModuleVacancyPage = Loadable(lazy(() => import('../views/module/vacancies')));
const ModuleTeamPage = Loadable(lazy(() => import('../views/module/team')));
const PendingTA = Loadable(lazy(() => import('../views/ta/Pending')));
const ApprovedTA = Loadable(lazy(() => import('../views/ta/Approved')));
const ApplicantsPage = Loadable(lazy(() => import('../views/ta/Applicants')));
const InitialBudgetPage = Loadable(lazy(() => import('../views/budget/Initial')));
const ExtraBudgetPage = Loadable(lazy(() => import('../views/budget/Extra')));

const MainRoutes: RouteObject = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/module',
            element: <ModuleIndexPage />
        },
        {
            path: '/module/:moduleCode',
            children: [
                {
                    path: 'profile',
                    element: <ModuleProfilePage />
                },
                {
                    path: 'approved',
                    element: <ModuleApprovedAppPage />
                },
                {
                    path: 'pending',
                    element: <ModulePendingAppPage />
                },
                {
                    path: 'initial',
                    element: <ModuleInitialBudgetPage />
                },
                {
                    path: 'extra',
                    element: <ModuleExtraBudgetPage />
                },
                {
                    path: 'vacancies',
                    element: <ModuleVacancyPage />
                },
                {
                    path: 'team',
                    element: <ModuleTeamPage />
                }
            ]
        },
        {
            path: 'ta',
            children: [
                {
                    path: 'pending',
                    element: <PendingTA />
                },
                {
                    path: 'approved',
                    element: <ApprovedTA />
                },
                {
                    path: 'applicants',
                    element: <ApplicantsPage />
                }
            ]
        },
        {
            path: 'budget',
            children: [
                {
                    path: 'initial',
                    element: <InitialBudgetPage />
                },
                {
                    path: 'extra',
                    element: <ExtraBudgetPage />
                }
            ]
        }
    ]
};

export default MainRoutes;
