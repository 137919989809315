import { lazy } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
import AuthRoutes from './AuthRoutes';
import PGTARoutes from './PGTARoutes';
import MLRoutes from './MLRoutes';
import ALRoutes from './ALRoutes';
import HRRoutes from './HRRoutes';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const Dashboard = Loadable(lazy(() => import('views/dashboard')));

const GeneralRoutes: RouteObject = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: 'overview',
            element: <Dashboard />
        },
        {
            path: '*',
            element: <Dashboard />
        }
    ]
};

export default function ThemeRoutes() {
    return useRoutes([GeneralRoutes, PGTARoutes, MLRoutes, ALRoutes, HRRoutes, AuthRoutes]);
}
