import IconJob from '@mui/icons-material/WorkOutline';
import IconPerson from '@mui/icons-material/PersonOutline';
import IconFaq from '@mui/icons-material/QuestionAnswerOutlined';
import { IconDashboard, IconVacancy } from './menu-icons';
import { IMenuItem } from 'types/menu';

const other: IMenuItem = {
    id: 'pgta-navigation',
    type: 'group',
    children: [
        {
            id: 'overview',
            title: 'Overview',
            type: 'item',
            url: '/overview',
            icon: IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'vacancies',
            title: 'Vacancies',
            type: 'collapse',
            icon: IconVacancy,
            children: [
                {
                    id: 'vacancies-search',
                    title: 'Search',
                    pageTitle: 'Search Vacancies',
                    type: 'item',
                    url: '/vacancies/search',
                    breadcrumbs: true
                },
                {
                    id: 'vacancies-favorites',
                    title: 'Favorites',
                    type: 'item',
                    url: '/vacancies/favorites',
                    breadcrumbs: true
                },
                {
                    id: 'vacancies-apply',
                    title: 'Apply',
                    type: 'item',
                    url: '/vacancies/apply',
                    breadcrumbs: false,
                    hidden: true
                }
            ]
        },
        {
            id: 'jobs',
            title: 'Jobs',
            type: 'tabGroup',
            url: '/jobs',
            defaultTabUrl: '/jobs/submitted',
            breadcrumbs: true,
            icon: IconJob,
            children: [
                {
                    id: 'jobs-submitted',
                    title: 'Submitted',
                    type: 'item',
                    url: '/jobs/submitted'
                },
                {
                    id: 'jobs-confirmed',
                    title: 'Confirmed',
                    type: 'item',
                    url: '/jobs/confirmed'
                }
            ]
        },
        {
            id: 'profile',
            title: 'My Profile',
            type: 'tabGroup',
            url: '/profile',
            defaultTabUrl: '/profile/basic',
            icon: IconPerson,
            breadcrumbs: true,
            children: [
                {
                    id: 'profile-basic',
                    title: 'Basic Information',
                    type: 'item',
                    url: '/profile/basic'
                },
                {
                    id: 'profile-documents',
                    title: 'Documents',
                    type: 'item',
                    url: '/profile/documents'
                }
            ]
        },
        {
            id: 'faqs',
            title: 'FAQs',
            type: 'item',
            url: '/faqs',
            icon: IconFaq,
            breadcrumbs: true
        }
    ]
};

export default other;
