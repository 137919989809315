import { lazy } from 'react';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { RouteObject } from 'react-router-dom';

const ApplyVacancy = Loadable(lazy(() => import('views/vacancies/Apply')));
const Application = Loadable(lazy(() => import('views/jobs/application/Application')));
const Confirmed = Loadable(lazy(() => import('views/jobs/confirmed/Confirmed')));
const Search = Loadable(lazy(() => import('views/vacancies/Search/index')));
const Favorites = Loadable(lazy(() => import('views/vacancies/Favorites/Favorites')));
const Basic = Loadable(lazy(() => import('views/profile/Basic')));
const Documents = Loadable(lazy(() => import('views/profile/Documents')));
const Faqs = Loadable(lazy(() => import('views/Faq')));

const MainRoutes: RouteObject = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: 'vacancies',
            children: [
                {
                    path: 'search',
                    element: <Search />
                },
                {
                    path: 'favorites',
                    element: <Favorites />
                },
                {
                    path: 'apply/:vacancyId',
                    element: <ApplyVacancy />
                }
            ]
        },
        {
            path: 'jobs',
            children: [
                {
                    path: 'submitted',
                    element: <Application />
                },
                {
                    path: 'confirmed',
                    element: <Confirmed />
                }
            ]
        },
        {
            path: 'profile',
            children: [
                {
                    path: 'basic',
                    element: <Basic />
                },
                {
                    path: 'documents',
                    element: <Documents />
                }
            ]
        },
        {
            path: 'faqs',
            element: <Faqs />
        }
    ]
};

export default MainRoutes;
